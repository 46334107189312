import axiosClient from "src/features/axios/client";
import {
  LearningPathDetailItem,
  LearningPathListItem,
} from "../types/learningPathsV2.types";
import { ModuleListItem } from "../types/modulesV2.types";

class LearningPathsService {
  async createEmptyModule(id: string): Promise<ModuleListItem> {
    return await axiosClient
      .post(`/api/learning-paths/${id}/create-empty-module/`)
      .then((res) => res.data);
  }

  async getLearningPaths(): Promise<LearningPathListItem[]> {
    return await axiosClient
      .get("/api/learning-paths/")
      .then((res) => res.data);
  }

  async getLearningPath(id: number): Promise<LearningPathDetailItem> {
    return await axiosClient
      .get(`/api/learning-paths/${id}/`)
      .then((res) => res.data);
  }

  async partialUpdateLearningPath(
    data: Partial<LearningPathDetailItem>
  ): Promise<LearningPathDetailItem> {
    return await axiosClient
      .patch(`/api/learning-paths/${data?.id}/`, data)
      .then((res) => res.data);
  }

  async startLearningPath(id: number): Promise<LearningPathListItem> {
    return await axiosClient
      .post(`/api/learning-paths/${id}/start/`)
      .then((res) => res.data);
  }
}

const learningPathsService = new LearningPathsService();

export default learningPathsService;
