import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";

import { useAlert } from "@blaumaus/react-alert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import YellowCircleLoadingSpinner from "src/components/Animations/YellowCircleLoadingSpinner";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import Container from "src/components/Container/Container";
import DragAndDropFileInput, {
  FileUploadResponse,
  UploadVariables,
} from "src/components/Forms/DragAndDropFileInput";
import FormRow from "src/components/Forms/FormRow";
import EmptyGrayAndYellowCircleIcon from "src/components/Icons/EmptyGrayAndYellowCircleIcon";
import YellowCheckLargeIcon from "src/components/Icons/YellowCheckLargeIcon";
import StepContainer from "src/components/Steps/StepContainer";
import StepsProgressBarV2 from "src/components/Steps/StepsProgressBarV2";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { useImageGeneratorWebSocket } from "src/features/images/hooks/useImageGeneratorWebSocket";
import {
  ImageGeneratorStatus,
  ImageGeneratorSteps,
} from "src/features/images/images.types";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import {
  ScheduledTodosFormSteps,
  setScheduledTodosFormStep,
} from "src/features/todos/todosSlice";
import getImageColorFromCategory from "src/features/utils/getImageColorFromCategory";
import { useAppDispatch } from "src/store";
import { z } from "zod";
import useGetVideoQuery from "../hooks/queries/useGetVideoQuery";
import useVideoFormReducer from "../hooks/useVideoFormReducer";
import {
  setVideoFormStep,
  setVideoId,
  VideoFormSteps,
} from "../redux/videoSlice";
import videoService2 from "../services/videoService2";

const videoUpdateSchema = z.object({
  id: z
    .string()
    .transform((value) => parseInt(value, 10))
    .or(z.number()),
  thumbnail_url: z.string().nullable(),
  thumbnail_name: z.string().min(1, "A thumbnail is required."),
  thumbnail_ext: z.string().min(1, "A thumbnail is required."),
});

export type VideoUpdateFormShemaType = z.infer<typeof videoUpdateSchema>;

function VideoThumbnailUploadForm() {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const { videoId } = useVideoFormReducer();
  const queryClient = useQueryClient();
  const handleErrors = useErrorHandling();

  const { data: video } = useGetVideoQuery({ id: videoId });

  const partialUpdateVideoMutation = useMutation({
    mutationFn: videoService2.partialUpdateVideo,
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKeys.Video, String(data.id)], data);
      dispatch(setVideoId(String(data.id)));
      dispatch(setVideoFormStep(VideoFormSteps.AssignEmployees));
      dispatch(
        setScheduledTodosFormStep(
          ScheduledTodosFormSteps.CreateUpdateScheduledTodos
        )
      );
    },
    onError: handleErrors,
  });

  const form = useForm<VideoUpdateFormShemaType>({
    resolver: zodResolver(videoUpdateSchema),
    defaultValues: {
      id: video?.id,
      thumbnail_url: video?.thumbnail_url,
      thumbnail_name: video?.thumbnail_name,
      thumbnail_ext: video?.thumbnail_ext,
    },
  });

  const onSubmit = useCallback(
    (data: VideoUpdateFormShemaType) => {
      partialUpdateVideoMutation.mutate(data);
    },
    [partialUpdateVideoMutation]
  );

  const handleGoToLastStep = () => {
    const id = video?.id ? String(video.id) : null;
    dispatch(setVideoId(id));
    dispatch(setVideoFormStep(VideoFormSteps.AddDetails));
  };

  const handleSuccessfulUpload = useCallback(
    (_: FileUploadResponse, variables: UploadVariables) => {
      form.setValue("thumbnail_url", variables.get_url);
      form.setValue("thumbnail_name", variables.name);
      form.setValue("thumbnail_ext", variables.ext);
    },
    [form]
  );

  const handleRemovefile = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      form.setValue("thumbnail_url", "");
      form.setValue("thumbnail_name", "");
      form.setValue("thumbnail_ext", "");
    },
    [form]
  );

  const { sendMessage, status, step, socketUuid } = useImageGeneratorWebSocket({
    onSetValues: ({ image, name, ext }) => {
      form.setValue("thumbnail_url", image);
      form.setValue("thumbnail_name", name);
      form.setValue("thumbnail_ext", ext);
    },
  });

  const prompt = ` Video Title: ${video?.title}. Video Description: ${video?.description}`;

  const handleGenerateImage = () => {
    sendMessage({
      uuid: socketUuid,
      prompt: prompt,
      s3_key: "private/videos/thumbnails",
      color: getImageColorFromCategory(video?.category?.title),
    });
  };

  const steps = [
    {
      icon: <YellowCheckLargeIcon />,
      label: "Received Task",
    },
    {
      icon:
        step === ImageGeneratorSteps.GeneratingImage ? (
          <YellowCircleLoadingSpinner />
        ) : step === ImageGeneratorSteps.ReceivedPrompt ? (
          <EmptyGrayAndYellowCircleIcon />
        ) : (
          <YellowCheckLargeIcon />
        ),
      label: "Generating Image",
    },
    {
      icon:
        step === ImageGeneratorSteps.FinalizingData ? (
          <YellowCircleLoadingSpinner />
        ) : (
          <EmptyGrayAndYellowCircleIcon />
        ),
      label: "Finalizing Data",
    },
  ];

  const onError: SubmitErrorHandler<VideoUpdateFormShemaType> = (errors) => {
    if (errors.thumbnail_name) {
      alert.error(errors.thumbnail_name.message);
    }
  };

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">Upload your video file.</span>
      </div>
      <EditingContainer>
        <form
          onSubmit={form.handleSubmit(onSubmit, onError)}
          className="mt-20 overflow-y-auto"
        >
          <FormRow>
            <DragAndDropFileInput
              containerClassName="max-h-300"
              s3Folder="private/videos/thumbnails"
              acceptedTypes="image/*"
              fileUrl={form.watch("thumbnail_url") || ""}
              onRemoveFile={handleRemovefile}
              onSuccessfulUpload={handleSuccessfulUpload}
            />
          </FormRow>
          <div className="flex justify-center py-10">
            {status === ImageGeneratorStatus.Processing ? (
              <Container style={{ padding: "40px 20px" }}>
                <StepsProgressBarV2
                  progress={
                    step === ImageGeneratorSteps.ReceivedPrompt
                      ? 0
                      : step === ImageGeneratorSteps.GeneratingImage
                      ? 50
                      : 100
                  }
                  steps={steps}
                />
              </Container>
            ) : (
              <Button
                color={ButtonColors.Gray}
                size={ButtonSizes.MD}
                onClick={handleGenerateImage}
                disabled={partialUpdateVideoMutation.isLoading}
              >
                Generate image using A.I.
              </Button>
            )}
          </div>
          <ButtonsContainer>
            <Button
              color={ButtonColors.GrayAndYellow}
              size={ButtonSizes.MD}
              type="button"
              onClick={handleGoToLastStep}
              disabled={partialUpdateVideoMutation.isLoading}
            >
              {partialUpdateVideoMutation.isLoading ? (
                <RotatingLines
                  strokeColor="#f1b70c"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              ) : (
                "Go back"
              )}
            </Button>
            <Button
              color={ButtonColors.Yellow}
              size={ButtonSizes.MD}
              type="submit"
              disabled={partialUpdateVideoMutation.isLoading}
            >
              {!partialUpdateVideoMutation.isLoading ? (
                "Save And Next"
              ) : (
                <RotatingLines
                  strokeColor="#000"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              )}
            </Button>
          </ButtonsContainer>
        </form>
      </EditingContainer>
    </StepContainer>
  );
}

export default React.memo(VideoThumbnailUploadForm);
