import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { memo, ReactNode, useCallback, useEffect, useRef } from "react";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";

import useScroll from "src/hooks/useScroll";

type HorizontalScrollListProps<T> = {
  title: ReactNode;
  items: ReactNode;
  onLoadMore?: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<T, unknown>>;
  hasMore?: boolean;
  loadingThreshold?: number;
  isLoadingMore?: boolean;
  loadingComponent?: ReactNode;
};

function HorizontalScrollList<T>({
  title,
  items,
  onLoadMore,
  loadingComponent,
  hasMore = false,
  loadingThreshold = 800,
  isLoadingMore = false,
}: HorizontalScrollListProps<T>) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { left: canScrollLeft, right: canScrollRight } = useScroll(scrollRef);

  const scroll = (direction: number) => {
    scrollRef.current?.scrollBy({
      top: 0,
      left: direction * (scrollRef.current?.clientWidth - 20),
      behavior: "smooth",
    });
  };

  const handleScroll = useCallback(() => {
    if (!scrollRef.current || !onLoadMore || !hasMore || isLoadingMore) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

    if (scrollLeft + clientWidth >= scrollWidth - loadingThreshold) {
      onLoadMore();
    }
  }, [onLoadMore, hasMore, loadingThreshold, isLoadingMore]);

  useEffect(() => {
    const currentRef = scrollRef.current;
    if (!currentRef) return;

    currentRef.addEventListener("scroll", handleScroll);
    return () => {
      currentRef.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, onLoadMore]);

  return (
    <div className={"horizontalScrollList"} data-testid="horizontalScrollList">
      {title ? <div className={"horizontalListTitle"}>{title}</div> : null}
      {canScrollLeft && (
        <BsFillArrowLeftCircleFill
          onClick={() => scroll(-1)}
          className={"arrowLeft"}
          size={40}
          color={"#f1b70c"}
        />
      )}
      <div ref={scrollRef} className={"horizontalScrollContainer"}>
        {items}
        {isLoadingMore && loadingComponent ? loadingComponent : null}
      </div>
      {canScrollRight && (
        <BsFillArrowRightCircleFill
          onClick={() => scroll(1)}
          className={"arrowRight"}
          size={40}
          color={"#f1b70c"}
        />
      )}
    </div>
  );
}

export default memo(HorizontalScrollList);
