import { forwardRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./VideoPlayer.module.css";

type VideoPlayerProps = {
  src: string;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  controls?: boolean;
  className?: string;
  style?: React.CSSProperties;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  preload?: "auto" | "metadata" | "none";
};

const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>(
  (
    {
      src,
      autoPlay,
      muted = false,
      loop,
      className,
      controls,
      containerClassName,
      containerStyle,
      style,
      preload = "auto",
    },
    ref
  ) => {
    const [videoLoaded, setVideoLoaded] = useState(false);
    return (
      <div
        className={`${styles.videoPlayerContainer} ${containerClassName}`}
        style={containerStyle}
      >
        <video
          src={src}
          ref={ref}
          controlsList="nodownload"
          disablePictureInPicture
          className={className ? className : styles.videoPlayer}
          style={{ display: videoLoaded ? "block" : "none", ...style }}
          onLoadedData={() => setVideoLoaded(true)}
          onError={(err) => console.error("Error loading video", err)}
          onWaiting={() => console.log("Video is waiting")}
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
          controls={controls}
          data-testid="video-player"
          preload={preload}
        >
          Your browser does not support the video tag.
        </video>

        {!videoLoaded && (
          <Skeleton
            className={styles.videoPlayerSkeleton}
            borderRadius={15}
            containerTestId="video-loading-skeleton"
          />
        )}
      </div>
    );
  }
);

export default VideoPlayer;
