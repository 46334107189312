import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import getFileTypeFromAWSLink from "src/features/aws/utils/getFileTypeFromAWSLink";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import LPImage from "src/features/learningPaths/components/LPTake/LPImage";
import LPVideoPlayer from "src/features/learningPaths/components/LPTake/LPVideoPlayer";
import NumericQuestion from "src/features/learningPaths/components/LPTake/NumericQuestion";
import { z } from "zod";
import sittingTakeService from "../services/sittingTakeService";
import {
  MCQuestionType,
  NumericalQuestionType,
} from "../types/assessments.types";
import { Sitting, SittingStatus } from "../types/sittings.types";
import isMCQuestion from "../utils/isMcQuestion";
import isNumericalQuestion from "../utils/isNumericalQuestion";
import MCQuestion from "./MCQuestion";

const mcAnswerSchema = z.object({
  answers: z.array(z.number()).nonempty({ message: "Answers cannot be empty" }),
  sitting: z.number(),
  question: z.number().optional(),
});

const numericAnswerSchema = z.object({
  answer: z
    .string()
    .transform((val) => parseFloat(val))
    .refine((val) => !isNaN(val), {
      message: "Answer must be a valid number",
    })
    .refine((val) => val >= 0, {
      message: "Answer must be a positive number",
    }),
  sitting: z.number(),
  question: z.number().optional(),
});

export type MCAnswerFormData = z.infer<typeof mcAnswerSchema>;
export type NumericAnswerFormData = z.infer<typeof numericAnswerSchema>;

type Props = {
  sitting: Sitting;
  isNextBtnDisabled: boolean;
  onRefetchSitting: () => void;
  onSetQuestionNumber: (num: number) => void;
  setIsNextBtnDisabled: (isDisabled: boolean) => void;
};

function SittingQuestionTakeForm({
  sitting,
  isNextBtnDisabled,
  onRefetchSitting,
  onSetQuestionNumber,
  setIsNextBtnDisabled,
}: Props) {
  const queryClient = useQueryClient();
  const handleErrors = useErrorHandling();
  const [isVideoCompleted, setIsVideoCompleted] = React.useState(false);

  const {
    data: question,
    isLoading: isQuestionLoading,
    isError: isQuestionError,
    refetch: refetchQuestion,
    isRefetching: isQuestionRefetching,
  } = useQuery({
    queryKey: ["sittingTakeQuestion", sitting?.id],
    queryFn: sitting?.id
      ? () => sittingTakeService.getNextQuestion(sitting.id)
      : undefined,
    enabled: !!sitting?.id && sitting?.status === SittingStatus.InProgress,
    onSuccess: (data) => handleSuccessfulResponse(data),
  });

  const postNumericAnswerMutation = useMutation({
    mutationFn: sittingTakeService.postNumericAnswer,
    onSuccess: (data) => {
      resetNumericalAnswer();
      resetMCAnswers();
      queryClient.setQueryData(["sittingTakeQuestion", sitting.id], data);
      handleSuccessfulResponse(data);
    },
    onError: handleErrors,
  });

  const postMCAnswersMutation = useMutation({
    mutationFn: sittingTakeService.postMCAnswer,
    onSuccess: (data) => {
      resetNumericalAnswer();
      resetMCAnswers();
      queryClient.setQueryData(["sittingTakeQuestion", sitting.id], data);
      handleSuccessfulResponse(data);
    },
    onError: handleErrors,
  });

  const handleSuccessfulResponse = (
    data: NumericalQuestionType | MCQuestionType | null
  ) => {
    if (!data) {
      return onRefetchSitting();
    } else if (data.current_question) {
      onSetQuestionNumber(data.current_question);
    }
  };

  const {
    handleSubmit: handleSubmitMCAnswers,
    control: controlMCAnswers,
    reset: resetMCAnswers,
    watch: watchMCAnswers,
  } = useForm<Omit<MCAnswerFormData, "question">>({
    resolver: zodResolver(mcAnswerSchema),
    defaultValues: {
      answers: undefined,
      sitting: sitting?.id,
    },
  });

  const {
    register: registerNumericalAnswer,
    reset: resetNumericalAnswer,
    handleSubmit: handleSubmitNumericAnswer,
    watch: watchNumericalAnswer,
  } = useForm<Omit<NumericAnswerFormData, "question">>({
    resolver: zodResolver(numericAnswerSchema),
    defaultValues: {
      answer: undefined,
      sitting: sitting?.id,
    },
  });

  const fileType = getFileTypeFromAWSLink(question?.file_url);

  const onSubmitNumericalAnswer = (data: NumericAnswerFormData) =>
    postNumericAnswerMutation.mutateAsync({ ...data, question: question?.id });

  const onSubmitMCAnswers = (data: MCAnswerFormData) =>
    postMCAnswersMutation.mutateAsync({ ...data, question: question?.id });

  const handleSubmitAnswer = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    if (isNumericalQuestion(question)) {
      handleSubmitNumericAnswer(onSubmitNumericalAnswer)();
    } else if (isMCQuestion(question)) {
      handleSubmitMCAnswers(onSubmitMCAnswers)();
    }
  };

  const handleToggleAnswer = (selectedAnswers: number[], answerId: number) => {
    if (!isMCQuestion(question)) return;
    let answers;
    if (selectedAnswers.includes(answerId)) {
      answers = selectedAnswers.filter((id) => id !== answerId);
    } else {
      if (question.correct_answer_count === 1) {
        answers = [answerId];
      } else {
        answers = [...selectedAnswers, answerId];
      }
    }
    return answers;
  };

  const answers = watchMCAnswers("answers");
  const answer = watchNumericalAnswer("answer");

  const handleQuestionStateChange = useCallback(() => {
    if (isMCQuestion(question)) {
      if (answers?.length > 0 && (fileType !== "video" || isVideoCompleted)) {
        setIsNextBtnDisabled(false);
      } else {
        setIsNextBtnDisabled(true);
      }
    } else if (isNumericalQuestion(question)) {
      if (answer && (fileType !== "video" || isVideoCompleted)) {
        setIsNextBtnDisabled(false);
      } else {
        setIsNextBtnDisabled(true);
      }
    }
  }, [
    question,
    answers?.length,
    fileType,
    isVideoCompleted,
    setIsNextBtnDisabled,
    answer,
  ]);

  React.useEffect(() => {
    handleQuestionStateChange();
  }, [answers?.length, answer, isVideoCompleted, handleQuestionStateChange]);

  if (isQuestionLoading || isQuestionRefetching) {
    return (
      <div className="flex flex-col flex-1 justify-center items-center">
        <Skeleton height={400} />
      </div>
    );
  }

  if (isQuestionError) {
    return (
      <div className="flex flex-col flex-1 justify-center items-center">
        <ErrorFallback onReload={refetchQuestion} />
      </div>
    );
  }
  return (
    <>
      {question?.file_url && fileType === "video" ? (
        <LPVideoPlayer
          src={question?.file_url}
          onComplete={setIsVideoCompleted}
        />
      ) : null}
      {question?.file_url && fileType === "image" ? (
        <LPImage src={question?.file_url} />
      ) : null}
      {isMCQuestion(question) ? (
        <MCQuestion
          question={question}
          isLoading={
            postMCAnswersMutation.isLoading ||
            (fileType === "video" && !isVideoCompleted)
          }
          control={controlMCAnswers}
          onToggleAnswer={handleToggleAnswer}
        />
      ) : null}
      {isNumericalQuestion(question) ? (
        <NumericQuestion
          question={question}
          register={registerNumericalAnswer}
        />
      ) : null}
      <ButtonsContainer>
        <Button
          color={ButtonColors.Yellow}
          size={ButtonSizes.LG}
          title={"Submit Answer"}
          type="button"
          onClick={handleSubmitAnswer}
          disabled={
            isNextBtnDisabled ||
            postMCAnswersMutation.isLoading ||
            postNumericAnswerMutation.isLoading
          }
        />
      </ButtonsContainer>
    </>
  );
}

export default React.memo(SittingQuestionTakeForm);
