import React from "react";
import cn from "src/features/utils/cn";

type ListItemTextProps = {
  className?: string;
  titleClassName?: string;
  subTitleClassName?: string;
  title: React.ReactNode;
  subTitle: React.ReactNode;
};

export default function ListItemText({
  className,
  titleClassName,
  subTitleClassName,
  title,
  subTitle,
}: ListItemTextProps) {
  return (
    <div className={cn("listItemTextContainer", className)}>
      <div className={cn("listItemTitle", titleClassName)}>
        {title}
      </div>
      <div className={cn("listItemSubTitle", subTitleClassName)}>
        {subTitle}
      </div>
    </div>
  );
}
