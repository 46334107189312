import formatDateToString from "src/features/datetime/utils/formatDateToString";
import { MySittingListItem, Sitting } from "../types/sittings.types";

function getSittingStartEndText(sitting?: MySittingListItem | Sitting): string {
  const sittingStart = sitting?.start
    ? formatDateToString(new Date(sitting?.start))
    : "Not started";
  const sittingEnd = sitting?.end
    ? formatDateToString(new Date(sitting?.end))
    : "Incomplete";

  return `${sittingStart} - ${sittingEnd}`;
}

export default getSittingStartEndText;
