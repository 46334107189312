import axiosClient from "src/features/axios/client";
import { EmployeeLearningPathListItem } from "../types/employeeLearningPathsV2.types";

class EmployeeLearningPathsService {
  async getEmployeeLearningPath(
    id: number
  ): Promise<EmployeeLearningPathListItem> {
    return await axiosClient
      .get(`/api/employee-learning-paths/${id}/`)
      .then((res) => res.data);
  }

  async getEmployeeLearningPaths(): Promise<EmployeeLearningPathListItem[]> {
    return await axiosClient
      .get("/api/employee-learning-paths/")
      .then((res) => res.data);
  }

  async getEmployeeLearningPathsPaginated(
    { id, page = 1, sortBy, sortDirection, ...filters } = {} as any
  ) {
    return await axiosClient.get(`api/employee-learning-paths/`, {
      params: {
        id,
        page,
        sortBy,
        sortDirection,
        ...filters,
      },
    });
  }

  async getMyRecentEmployeeLearningPaths(): Promise<
    EmployeeLearningPathListItem[]
  > {
    return await axiosClient
      .get("/api/employee-learning-paths/my-recent/")
      .then((res) => res.data);
  }

  async getMyRecentEmployeeLearningPath(
    id: number
  ): Promise<EmployeeLearningPathListItem> {
    return await axiosClient
      .get(`/api/employee-learning-paths/my-recent/`, { params: { id } })
      .then((res) => {
        if (Array.isArray(res.data) && res.data.length > 0) {
          return res.data[0];
        }
        return res.data;
      });
  }

  async markAsStarted(id: number) {
    return await axiosClient
      .post(`/api/employee-learning-paths/${id}/start/`)
      .then((res) => res.data);
  }

  async markAsCompleted(id: number) {
    return await axiosClient
      .post(`/api/employee-learning-paths/${id}/complete/`)
      .then((res) => res.data);
  }
}

const employeeLearningPathsService = new EmployeeLearningPathsService();

export default employeeLearningPathsService;
