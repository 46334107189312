import { EmployeeListItem } from "src/features/employees/types/employees.type";
import { LearningPathListItem } from "./learningPathsV2.types";

export enum EmployeeLpStatus {
  Assigned = 0,
  InProgress = 1,
  Completed = 2,
  Incomplete = 3,
}

export type EmployeeLearningPathListItem = {
  id: number;
  employee: EmployeeListItem;
  learning_path: LearningPathListItem;
  learning_path_version: number;
  certificate_url: string | null;
  status: EmployeeLpStatus;
  overall_score: number | null;
  completion_percentage: number;
  deadline: string | null;
  time_taken: string | null;
  assigned_at: string | null;
  started_at: string | null;
  completed_at: string | null;
  created_on: string;
  updated_on: string;
};
