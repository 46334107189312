import React, { memo } from "react";
import Skeleton from "react-loading-skeleton";
import ImageV2 from "../Images/ImageV2";
import ImageUploadInput from "../Inputs/ImageUploadInput";
import styles from "./EmployeeImage.module.css";

type EmployeeImageProps = {
  src?: string;
  alt: string;
  allowUpload?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isUploading?: boolean;
  containerStyle?: React.CSSProperties;
  containerClassName?: string;
  imgStyle?: React.CSSProperties;
  imgClassName?: string;
  loading?: "lazy" | "eager";
  height?: string | number;
  width?: string | number;
  circle?: boolean;
};

function EmployeeImage({
  src,
  alt,
  allowUpload = true,
  onChange,
  isUploading = false,
  containerStyle,
  containerClassName,
  imgStyle,
  imgClassName,
  loading = "lazy",
  height = 40,
  width = 40,
  circle = true,
}: EmployeeImageProps) {
  const containerClass = containerClassName
    ? containerClassName
    : styles.employeeImageContainer;
  return (
    <div
      className={containerClass}
      style={{ ...containerStyle, width, height }}
    >
      {allowUpload && onChange ? (
        <>
          <ImageUploadInput
            name="image"
            onChange={onChange}
            containerClass={styles.imageUploadIconContainer}
            inputClass={styles.imageUploadInput}
            isUploading={isUploading}
          />
        </>
      ) : null}
      {src ? (
        <ImageV2
          loading={loading}
          src={src}
          alt={alt}
          className={`${styles.employeeImage} ${imgClassName}`}
          style={imgStyle}
          height={height}
          width={width}
          circle={circle}
        />
      ) : null}
      {!src ? (
        <Skeleton circle={circle} height={height} width={height} />
      ) : null}
    </div>
  );
}

export default memo(EmployeeImage);
