import { EmployeeListItem } from "src/features/employees/types/employees.type";
import { EmployeeModuleContentList } from "src/features/learningPaths/learningPaths.types";
import { AssessmentListItem } from "./assessments.types";

export enum SittingStatus {
  InProgress = 0,
  Passed = 1,
  TimeExpired = 2,
  Failed = 3,
  Assigned = 4,
}

export type MySittingListItem = {
  id: number;
  employee: number;
  assessment: AssessmentListItem;
  question_order: string;
  question_list: string;
  incorrect_questions: string;
  current_score: number;
  percent_correct: number | null;
  percent_complete: number;
  complete: boolean;
  passed: boolean;
  start: string;
  end: string | null;
  time_expired: boolean;
  status: SittingStatus;
  employee_module_content: number | null;
};

export type Sitting = {
  id: number;
  employee: EmployeeListItem;
  assessment: AssessmentListItem;
  status: SittingStatus;
  start: string | null;
  end: string | null;
  deadline: string | null;
  current_score: number | null;
  max_score: number | null;
  percent_correct: number | null;
  percent_complete: number;
  current_time: string;
  current_question: number;
  time_taken: string;
  employee_module_content: EmployeeModuleContentList | null;
};
