import { MCQuestionType, NumericalQuestionType } from "./assessments.types";

export enum EmployeeAnswerStatus {
  UNANSWERED = 0,
  CORRECT = 1,
  INCORRECT = 2,
  TIME_EXPIRED = 3,
}

export type EmployeeAnswer = {
  id: number;
  employee: number;
  status: EmployeeAnswerStatus;
  sitting: number;
  content_type: string;
  object_id: number;
  question: string | null;
  position: number;
  answer: string | null;
  created_on: string;
  updated_on: string;
};

export type EmployeeAnswerReview = {
  id: number;
  employee: number;
  status: EmployeeAnswerStatus;
  sitting: number;
  content_type: string;
  object_id: number;
  question: MCQuestionType | NumericalQuestionType;
  position: number;
  answer: string | null;
  created_on: string;
  updated_on: string;
};
