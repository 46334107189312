import { useAlert } from "@blaumaus/react-alert";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";

import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import DragAndDropFileInput, {
  FileUploadResponse,
  UploadVariables,
} from "src/components/Forms/DragAndDropFileInput";
import FormRow from "src/components/Forms/FormRow";
import StepContainer from "src/components/Steps/StepContainer";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import {
  ScheduledTodosFormSteps,
  setScheduledTodosFormStep,
} from "src/features/todos/todosSlice";
import { useAppDispatch } from "src/store";
import useGetPolicyQuery from "../../hooks/useGetPolicyQuery";
import usePartialUpdatePolicyMutation from "../../hooks/usePartialUpdatePolicyMutation";
import usePolicyFormState from "../../hooks/usePolicyFormState";
import { PolicyFormSteps, setPolicyFormStep } from "../../slices/policySlice";
import {
  policyUploadPdfSchema,
  PolicyUploadPdfSchemaType,
} from "../../zod/policyUploadPdfSchema";

export default function PolicyUploadPdfForm() {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const { selectedPolicyId } = usePolicyFormState();

  const { data: policy, isInitialLoading: isPolicyLoading } = useGetPolicyQuery(
    {
      id: selectedPolicyId ?? "",
    }
  );

  const form = useForm<PolicyUploadPdfSchemaType>({
    resolver: zodResolver(policyUploadPdfSchema),
    defaultValues: {
      id: selectedPolicyId || policy?.id,
      file_url: policy?.file_url,
      name: policy?.name,
      ext: policy?.ext,
    },
  });

  const partialUpdatePolicyMutation = usePartialUpdatePolicyMutation({
    onSuccess: (data) => {
      alert.success("Policy pdf saved successfully!");
      queryClient.setQueryData(
        [QueryKeys.Policy, String(selectedPolicyId)],
        data
      );
      dispatch(
        setScheduledTodosFormStep(
          ScheduledTodosFormSteps.CreateUpdateScheduledTodos
        )
      );
      dispatch(setPolicyFormStep(PolicyFormSteps.AssignToEmployees));
    },
  });

  const onSubmit = useCallback(
    (data: PolicyUploadPdfSchemaType) => {
      partialUpdatePolicyMutation.mutate({
        id: String(data.id),
        name: data.name,
        ext: data.ext,
      });
    },
    [partialUpdatePolicyMutation]
  );

  const handleGoToLastStep = () =>
    dispatch(setPolicyFormStep(PolicyFormSteps.AddDetails));

  const handleSuccessfulUpload = useCallback(
    (_: FileUploadResponse, variables: UploadVariables) => {
      form.setValue("file_url", variables.get_url);
      form.setValue("name", variables.name);
      form.setValue("ext", variables.ext);
    },
    [form]
  );

  const handleRemovefile = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      form.setValue("file_url", "");
      form.setValue("name", "");
      form.setValue("ext", "");
    },
    [form]
  );

  const isLoading = isPolicyLoading || partialUpdatePolicyMutation.isLoading;

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">
          Please upload a PDF file for your policy. The policy cannot be
          published without a PDF file.
        </span>
      </div>
      <EditingContainer>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="mt-20 overflow-y-auto"
        >
          <FormRow>
            <DragAndDropFileInput
              s3Folder="private/policies"
              acceptedTypes="application/pdf"
              fileUrl={form.watch("file_url") || ""}
              onRemoveFile={handleRemovefile}
              onSuccessfulUpload={handleSuccessfulUpload}
              containerClassName="h-unset"
            />
          </FormRow>
          <ButtonsContainer>
            <Button
              color={ButtonColors.GrayAndYellow}
              size={ButtonSizes.MD}
              type="button"
              onClick={handleGoToLastStep}
              disabled={isLoading}
            >
              {isLoading ? (
                <RotatingLines
                  strokeColor="#f1b70c"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              ) : (
                "Go back"
              )}
            </Button>
            <Button
              color={ButtonColors.Yellow}
              size={ButtonSizes.MD}
              type="submit"
              disabled={isLoading}
            >
              {!isLoading ? (
                "Save And Next"
              ) : (
                <RotatingLines
                  strokeColor="#000"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              )}
            </Button>
          </ButtonsContainer>
        </form>
      </EditingContainer>
    </StepContainer>
  );
}
