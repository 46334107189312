import { useQuery } from "@tanstack/react-query";
import getFullName from "src/features/employees/utils/getFullName";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import employeeScheduleRolesService from "../services/employeeScheduleRolesService";
import { ShiftRequirementType } from "../services/shiftRequirementsService";
import useGetFulfilledEmployeeShiftsListQuery from "./useGetFulfilledEmployeeShiftsListQuery";

type UseGetEmployeeScheduleRolesQuery = {
  scheduleRole?: number;
  shift: ShiftRequirementType | null;
  search?: string;
};

export default function useGetAvailableEmployeeScheduleRolesQuery({
  scheduleRole,
  shift,
  search,
}: UseGetEmployeeScheduleRolesQuery) {
  const { data: employeeShifts, isLoading } =
    useGetFulfilledEmployeeShiftsListQuery({
      scheduleRoleId: scheduleRole,
      shift,
    });

  return useQuery({
    queryFn: () =>
      employeeScheduleRolesService.getEmployeeScheduleRoles(scheduleRole),
    queryKey: [QueryKeys.EmployeeScheduleRolesList, scheduleRole],

    enabled: !isLoading && !!scheduleRole,
    select: (data) => {
      let filteredData = data;

      // Filter the employee in employee_schedule_role.employee by search
      if (search) {
        filteredData = filteredData?.filter((esr) => {
          const fullName = getFullName(esr.employee);
          return fullName.toLowerCase().includes(search.toLowerCase());
        });
      }

      // Always filter out the employee schedule roles that have already been scheduled
      if (!isLoading && employeeShifts) {
        filteredData = filteredData?.filter((esr) => {
          return !employeeShifts.some(
            (shift) => shift.employee_schedule_role.id === esr.id
          );
        });
      }

      return filteredData;
    },
  });
}
