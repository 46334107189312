import axiosClient from "src/features/axios/client";
import { EmployeeAnswerReview } from "../types/employeeAnswers.types";

type EmployeeAnswersReviewParams = {
  sitting: number;
};

class EmployeeAnswersService {
  async getMyEmployeeAnswers(
    params: EmployeeAnswersReviewParams
  ): Promise<EmployeeAnswerReview[]> {
    return await axiosClient
      .get("/api/employee-answers/", { params })
      .then((res) => res.data);
  }
}

const employeeAnswersService = new EmployeeAnswersService();

export default employeeAnswersService;
