import { useCallback, useMemo, useRef } from "react";
import { Document, Page } from "react-pdf";
import LoadingAnimation from "src/components/Animations/LoadingAnimation";
import useIntersectionObserver from "src/hooks/useIntersectionObserver";
import { useAppDispatch } from "src/store";
import useDocumentViewer from "../../hooks/useDocumentViewer";
import { ContainerSize } from "../../routes/DocumentDetailRoute";
import {
  setContainerSize,
  setNumPages,
  setPageNumber,
} from "../../slices/documentViewerSlice";
import styles from "../ESignatures.module.css";
import PdfThumbnail from "./PdfThumbnail";
import PdfViewerContainer from "./PdfViewerContainer";
import PdfViewerPagesContainer from "./PdfViewerPagesContainer";
import PdfViewerThumbnailListContainer from "./PdfViewerThumbnailListContainer";

interface BaseAnnotation {
  id?: string | number;
  page: number;
  uuid: string;
}

type VerticalDocumentViewerProps<T extends BaseAnnotation> = {
  fileUrl?: string | null;
  isLoading: boolean;
  pageRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  annotations?: T[];
  loadingAnimation?: React.ReactNode;
  renderAnnotation?: (annotation: T, key: string) => React.ReactNode;
  startPage?: number;
  endPage?: number;
  onDocumentComplete?: () => void;
};

function VerticalDocumentViewer<T extends BaseAnnotation>({
  fileUrl,
  isLoading = false,
  pageRefs,
  annotations,
  loadingAnimation = <LoadingAnimation />,
  renderAnnotation,
  startPage,
  endPage,
  onDocumentComplete,
}: VerticalDocumentViewerProps<T>) {
  const dispatch = useAppDispatch();
  const { numPages, pageNumber, containerSize } = useDocumentViewer();

  const opt = useMemo(() => {
    return {
      cMapUrl: "/bcmaps/",
      cMapPacked: true,
    };
  }, []);

  const handleLoadSuccess = useCallback(
    ({ numPages }: { numPages: number | null }) => {
      dispatch(setNumPages(numPages));

      const initialPage = startPage && startPage >= 1 ? startPage : 1;

      // Only reset to initialPage if it's the first time loading the document.
      if (
        !pageNumber ||
        pageNumber < initialPage ||
        (numPages && pageNumber > numPages)
      ) {
        setTimeout(() => dispatch(setPageNumber(initialPage)), 200);
      }
    },
    [dispatch, pageNumber, startPage]
  );

  const handleRenderSuccess = useCallback(
    (pageSize: ContainerSize) => dispatch(setContainerSize(pageSize)),
    [dispatch]
  );

  const firstPage = useMemo(() => {
    return startPage && startPage >= 1 ? startPage : 1;
  }, [startPage]);

  const lastPage = useMemo(() => {
    if (!numPages) return 0;
    if (endPage && endPage <= numPages) {
      return endPage;
    }
    return numPages;
  }, [endPage, numPages]);

  const pageNumbers = useMemo(() => {
    if (!numPages) return [];
    const start = firstPage;
    const end = lastPage;
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  }, [firstPage, lastPage, numPages]);

  const handleThumbnailClick = useCallback(
    (page: number) => {
      dispatch(setPageNumber(page));
      const pageIndex = pageNumbers.findIndex((p) => p === page);
      if (pageIndex !== -1) {
        const pageRef = pageRefs.current[pageIndex];
        if (pageRef) {
          pageRef.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    },
    [dispatch, pageRefs, pageNumbers]
  );

  // Use a ref to ensure onDocumentComplete is called only once
  const onDocumentCompleteCalled = useRef(false);

  useIntersectionObserver({
    refs: pageRefs,
    onIntersect: (entry) => {
      const pageNumberString = entry.target.getAttribute("data-page-number");
      if (pageNumberString !== null) {
        const pageNum = parseInt(pageNumberString);
        dispatch(setPageNumber(pageNum));

        if (
          onDocumentComplete &&
          pageNum === lastPage &&
          !onDocumentCompleteCalled.current
        ) {
          onDocumentComplete();
          onDocumentCompleteCalled.current = true;
        }
      }
    },
  });

  return (
    <div className={styles.rowContainer}>
      <PdfViewerThumbnailListContainer>
        {numPages && fileUrl
          ? pageNumbers.map((pageNum) => (
              <PdfThumbnail
                key={pageNum}
                fileSrc={fileUrl}
                pageNumber={pageNum}
                currentPage={pageNumber}
                onThumbnailClick={handleThumbnailClick}
              />
            ))
          : null}
      </PdfViewerThumbnailListContainer>
      <PdfViewerContainer>
        {!isLoading && fileUrl ? (
          <PdfViewerPagesContainer>
            <Document
              file={fileUrl}
              onLoadSuccess={handleLoadSuccess}
              loading={loadingAnimation}
              options={opt}
            >
              {numPages &&
                pageNumbers.map((pageNum, index) => (
                  <div
                    key={pageNum}
                    ref={(el) => (pageRefs.current[index] = el)}
                    data-page-number={pageNum}
                    style={{
                      marginBottom: "20px",
                      position: "relative",
                      width: containerSize.width
                        ? `${containerSize.width}px`
                        : "auto",
                      height: containerSize.height
                        ? `${containerSize.height}px`
                        : "auto",
                    }}
                  >
                    <Page
                      pageNumber={pageNum}
                      onRenderSuccess={handleRenderSuccess}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                    {annotations?.length && renderAnnotation
                      ? annotations
                          .filter((ann) => ann.page === pageNum)
                          .map((ann) => renderAnnotation(ann, ann.uuid))
                      : null}
                  </div>
                ))}
            </Document>
          </PdfViewerPagesContainer>
        ) : null}
      </PdfViewerContainer>
    </div>
  );
}

export default VerticalDocumentViewer;
