import axiosClient from "src/features/axios/client";
import { CreateCategorySchemaType } from "../zod/createCategorySchema";
import { Category } from "src/features/learningPaths/learningPaths.types";

class CategoriesService {
  async createCategory({ title }: CreateCategorySchemaType) {
    return await axiosClient
      .post("/api/categories/", {
        title,
      })
      .then((res) => res.data);
  }
  async getCategories(): Promise<Category[]> {
    return await axiosClient.get("/api/categories/").then((res) => res.data);
  }
}

const categoriesService = new CategoriesService();

export default categoriesService;
