import { useCallback, useMemo } from "react";
import useGetAssessmentsQuery from "../hooks/useGetAssessmentsQuery";
import { AssessmentListItem } from "../types/assessments.types";
import { SittingStatus } from "../types/sittings.types";
import useGetMyRecentSittingsQuery from "./useGetMyRecentSittingsQuery";

export interface AssessmentWithCompletion extends AssessmentListItem {
  percent_complete?: number;
  status?: SittingStatus;
  sitting_id?: number;
}

const useAssessmentsData = () => {
  const {
    data: assessments,
    isLoading: isAssessmentsLoading,
    isError: isAssessmentsError,
    refetch: refetchAssessments,
  } = useGetAssessmentsQuery();

  const {
    data: mySittings,
    isLoading: isMySittingsLoading,
    isError: isMySittingsError,
    refetch: refetchMySittings,
  } = useGetMyRecentSittingsQuery();

  const handleRefetch = useCallback(() => {
    refetchAssessments();
    refetchMySittings();
  }, [refetchAssessments, refetchMySittings]);

  const assessmentsByCategory = useMemo(() => {
    const assessmentsAssignedToYou: AssessmentWithCompletion[] = [];
    const addedAssessmentIds = new Set<number>();

    if (mySittings) {
      mySittings.forEach((sitting) => {
        const assessment = assessments?.find(
          (a) => a.id === sitting.assessment.id
        );
        if (assessment && !addedAssessmentIds.has(assessment.id)) {
          assessmentsAssignedToYou.push({
            ...assessment,
            percent_complete: sitting.percent_complete || undefined,
            status: sitting?.status,
            sitting_id: sitting.id,
          });
          addedAssessmentIds.add(assessment.id);
        }
      });
    }

    const grouped = {} as Record<string, AssessmentWithCompletion[]>;
    if (assessmentsAssignedToYou.length > 0) {
      grouped["Assigned to you"] = assessmentsAssignedToYou;
    }

    assessments?.forEach((assessment) => {
      if (!addedAssessmentIds.has(assessment.id)) {
        const sitting = mySittings?.find(
          (s) => s.assessment.id === assessment.id
        );
        const completeAssessment: AssessmentWithCompletion = {
          ...assessment,
          percent_complete: sitting?.percent_complete || undefined,
          status: sitting?.status,
          sitting_id: sitting?.id,
        };
        const category = assessment.category?.title || "Uncategorized";
        grouped[category] = grouped[category] || [];
        grouped[category].push(completeAssessment);
      }
    });

    Object.values(grouped).forEach((assessments) =>
      assessments.sort((a, b) => {
        if (a.percent_complete && b.percent_complete) {
          return a.percent_complete - b.percent_complete;
        }
        if (a.draft && !b.draft) return 1;
        if (!a.draft && b.draft) return -1;
        return 0;
      })
    );

    return grouped;
  }, [assessments, mySittings]);

  const assignedToYouCategory = assessmentsByCategory["Assigned to you"];
  const nextUpAssessment = assignedToYouCategory
    ? assignedToYouCategory[0]
    : null;

  console.log("mySittings", mySittings);

  return {
    nextUpAssessment,
    assessmentsByCategory,
    isLoading: isAssessmentsLoading || isMySittingsLoading,
    isError: isAssessmentsError || isMySittingsError,
    handleRefetch,
  };
};

export default useAssessmentsData;
