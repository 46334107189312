import { useAlert } from "@blaumaus/react-alert";
import { memo, useRef, useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";

import getFileTypeFromAWSLink from "src/features/aws/utils/getFileTypeFromAWSLink";
import ResizingPDFViewer from "src/features/pdf/components/ResizingPDFViewer";
import useUploadFileToS3Mutation from "src/features/s3/hooks/useUploadFileToS3Mutation";
import s3Service from "src/features/s3/services/s3Service";
import cn from "src/features/utils/cn";
import CloseSmallIcon from "../Icons/CloseSmallIcon";
import ImageV2 from "../Images/ImageV2";
import Placeholder from "../Placeholder/Placeholder";
import ProgressBar from "../ProgressBar/ProgressBar";

export type UploadVariables = {
  file: File;
  name: string;
  ext: string;
  get_url: string;
  presigned_url: string;
};

export type FileUploadResponse = {
  presigned_url: string;
  status: number;
};

type DragAndDropFileInputProps = {
  fileUrl: string;
  acceptedTypes: string;
  s3Folder: string;
  onSuccessfulUpload: (
    data: FileUploadResponse,
    variables: UploadVariables
  ) => void;
  onRemoveFile: (e: React.MouseEvent) => void;
  containerClassName?: string;
  mediaClassName?: string;
  pdfPageWidth?: number;
};

function DragAndDropFileInput({
  fileUrl,
  acceptedTypes,
  s3Folder,
  onSuccessfulUpload,
  onRemoveFile,
  containerClassName = "",
  mediaClassName = "",
  pdfPageWidth = 300,
}: DragAndDropFileInputProps) {
  const alert = useAlert();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPageCount(numPages);
  };

  const changePage = (offset: number) =>
    setPageNumber((prevPageNumber) => prevPageNumber + offset);

  const previousPage = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    changePage(-1);
  };

  const nextPage = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    changePage(1);
  };

  const uploadFileToS3Mutation = useUploadFileToS3Mutation({
    onSuccess: (data, variables) => {
      setUploadProgress(0);
      onSuccessfulUpload(data, variables);
    },
    uploadProgress: setUploadProgress,
  });

  async function handleFileUpload(file: File) {
    const uuid = uuidv4();
    const extension = file.name.split(".").pop();
    const key = `${s3Folder}/${uuid}.${extension}`;

    const response = await s3Service.getPresignedUrl(key);

    if (response?.presigned_url && uuid && extension) {
      uploadFileToS3Mutation.mutate({
        file,
        presigned_url: response.presigned_url,
        get_url: response.get_url,
        name: uuid,
        ext: extension,
      });
    }
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (file) handleFileUpload(file);
  };

  const fileType = getFileTypeFromAWSLink(fileUrl);

  const onDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file) {
      const normalizedAcceptedTypes = acceptedTypes
        .split(",")
        .map((type) => type.trim());

      const isFileTypeAccepted = (
        fileType: string,
        acceptedTypes: string[]
      ) => {
        return acceptedTypes.some((acceptedType) => {
          if (acceptedType.endsWith("/*")) {
            return fileType.startsWith(acceptedType.replace("/*", ""));
          }
          return fileType === acceptedType;
        });
      };

      if (isFileTypeAccepted(file.type, normalizedAcceptedTypes)) {
        handleFileUpload(file);
      } else {
        alert.error("Invalid file type. Must be an image, video or pdf.");
      }
    }
  };

  return (
    <div
      className={cn("drag-and-drop-input-container", containerClassName)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={onDrop}
      onClick={() => {
        if (inputFileRef.current) {
          inputFileRef.current.click();
        }
      }}
    >
      <input
        type="file"
        style={{ display: "none" }}
        multiple={false}
        accept={acceptedTypes}
        ref={inputFileRef}
        onChange={handleFileSelect}
      />
      {uploadProgress > 0 && (
        <div className="centered gap-10 p-20">
          <Placeholder title={"Uploading file..."} />
          <ProgressBar progress={uploadProgress} />
        </div>
      )}
      {!fileUrl && uploadProgress === 0 ? (
        <div className="centered gap-10 p-20">
          <BsCloudUpload color={"#f1b70c"} size={24} />
          <p className={"cp-yellow text-md text-align-center font-w-500"}>
            Drag file here or click to upload.
          </p>
        </div>
      ) : null}
      {fileUrl && uploadProgress === 0 && (
        <div className={"drag-and-drop-remove-button"}>
          <button style={{ all: "unset" }} onClick={onRemoveFile}>
            <CloseSmallIcon />
          </button>
        </div>
      )}
      {uploadProgress === 0 && fileType === "image" ? (
        <div className={cn("drag-and-drop-media-container", mediaClassName)}>
          <ImageV2 src={fileUrl} alt={"uploadedFile"} />
        </div>
      ) : null}
      {uploadProgress === 0 && fileType === "video" ? (
        <div className={cn("drag-and-drop-media-container", mediaClassName)}>
          <video controls src={fileUrl} />
        </div>
      ) : null}
      {uploadProgress === 0 && fileType === "pdf" ? (
        <ResizingPDFViewer
          pageNumber={pageNumber}
          onPreviousPage={previousPage}
          onNextPage={nextPage}
          onLoadSuccess={onDocumentLoadSuccess}
          file={fileUrl}
          nextBtnDisabled={pageCount === pageNumber}
          prevBtnDisabled={pageNumber === 1}
          pageWidth={pdfPageWidth}
        />
      ) : null}
    </div>
  );
}

export default memo(DragAndDropFileInput);
