import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import shiftConflictsService, {
  ShiftConflictResolutionStatus,
  ShiftConflictType,
} from "../services/shiftConflictsService";

type GetShiftConflictsByDateQueryParams = {
  departmentId?: number;
  weeklyScheduleId?: number;
  resolutionStatus?: ShiftConflictResolutionStatus;
  conflictType?: ShiftConflictType;
  startDate?: string;
  endDate?: string;
};

export default function useGetShiftConflictsByDateQuery({
  departmentId,
  weeklyScheduleId,
  resolutionStatus,
  conflictType,
  startDate,
  endDate,
}: GetShiftConflictsByDateQueryParams) {
  return useQuery({
    queryFn: () =>
      shiftConflictsService.getShiftConflicts(
        departmentId,
        weeklyScheduleId,
        resolutionStatus,
        conflictType,
        startDate,
        endDate
      ),
    queryKey: [
      QueryKeys.ShiftConflictsList,
      {
        departmentId,
        weeklyScheduleId,
        resolutionStatus,
        conflictType,
        startDate,
        endDate,
      },
    ],

    retry: false,
  });
}
