import {
  MCQuestionType,
  NumericalQuestionType,
} from "../types/assessments.types";

function isMCQuestion(
  question?: NumericalQuestionType | MCQuestionType | null
): question is MCQuestionType {
  if (!question) {
    return false;
  }
  return (question as MCQuestionType)?.answers !== undefined;
}

export default isMCQuestion;
