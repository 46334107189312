import React from "react";
import styles from "./SquareCheckboxInput.module.css";

const INPUT_TYPE = "checkbox";

interface CheckboxInputProps {
  id: string;
  label: string | null;
  description?: string;
  name: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value?: string | number;
}

const SquareCheckboxInput = React.forwardRef<
  HTMLInputElement,
  CheckboxInputProps
>(
  (
    {
      id,
      label,
      description,
      name,
      checked,
      onChange,
      className,
      value,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <label className={styles.container}>
          {label}
          {description && <div className="inputDescription">{description}</div>}
          <input
            type={INPUT_TYPE}
            checked={checked}
            onChange={onChange}
            className={styles.checkbox}
            id={id}
            name={name}
            ref={ref}
            value={value}
            {...rest}
          >
            
          </input>
          <span className={styles.checkmark}></span>
        </label>
      </>
    );
  }
);

export default SquareCheckboxInput;
