import axiosClient from "src/features/axios/client";
import { z } from "zod";
import { VideoDetailsFormSchemaType } from "../form/VideoDetailsForm";
import { VideoListItem } from "../types/videos.types";

const videoSchema = z.object({
  id: z.number(),
  title: z.string(),
  category: z.string(),
  description: z.string(),
  name: z.string().optional(),
  ext: z.string().optional(),
  file_url: z.string().nullable(),
  draft: z.boolean().optional(),
  thumbnail: z.string().optional(),
});

export type VideoSchemaType = z.infer<typeof videoSchema>;

class VideoService2 {
  async createVideoDetails(
    data: VideoDetailsFormSchemaType
  ): Promise<VideoListItem> {
    return await axiosClient.post("/api/videos/", data).then((res) => res.data);
  }

  async deleteVideo(id: string) {
    return await axiosClient
      .delete(`/api/videos/${id}/`)
      .then((res) => res.data);
  }

  async markVideoAsViewed(id: string) {
    return await axiosClient
      .post(`/api/videos/${id}/mark-as-viewed/`)
      .then((res) => res.data);
  }

  async partialUpdateVideo(
    data: Partial<VideoSchemaType>
  ): Promise<VideoListItem> {
    return await axiosClient
      .patch(`/api/videos/${data.id}/`, data)
      .then((res) => res.data);
  }
}

const videoService2 = new VideoService2();

export default videoService2;
