import React from "react";
import cn from "src/features/utils/cn";
import styles from "./VideoPreviewInfo.module.css";

type VideoPreviewInfoProps = {
  superTitle?: React.ReactNode | string | null;
  title?: React.ReactNode | string;
  subTitle?: React.ReactNode | string | null;
  actions?: React.ReactNode;
  rightInfo?: React.ReactNode;
};

export default function VideoPreviewInfo({
  superTitle,
  title,
  subTitle,
  actions,
  rightInfo,
}: VideoPreviewInfoProps) {
  return (
    <>
      <div className={styles.videoPreviewInfo}>
        <div className="flex flex-1 flex-row-mobile-column px-10 py-20">
          <div className="flex flex-col flex-1 gap-20">
            {superTitle ? (
              <span
                className={styles.videoPreviewRecommended}
                data-testid="superTitle"
              >
                {superTitle}
              </span>
            ) : null}
            {title ? (
              <span className={styles.videoPreviewTitle} data-testid="title">
                {title}
              </span>
            ) : null}
            {subTitle ? (
              <span
                className={cn(
                  styles.videoPreviewCategory,
                  "truncatedDescription"
                )}
                data-testid="subTitle"
              >
                {subTitle}
              </span>
            ) : null}
            {actions ? (
              <span
                className={styles.videoPreviewButtons}
                data-testid="actions"
              >
                {actions}
              </span>
            ) : null}
          </div>
          <div className="flex flex-col flex-1-desktop justify-center">
            {rightInfo}
          </div>
        </div>
      </div>
    </>
  );
}
