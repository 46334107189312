import axiosClient from "src/features/axios/client";
import { CompositeTodoFilter, TodoDetail } from "../types/todos.types";

class MyTodosService {
  async getMyTodos(params: CompositeTodoFilter): Promise<TodoDetail[]> {
    return await axiosClient
      .get("/api/my-todos/", { params })
      .then((res) => res.data);
  }
}

const myTodosService = new MyTodosService();

export default myTodosService;
