import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import DynamicFormInput from "src/components/DynamicForm/DynamicFormInput";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import FormRow from "src/components/Forms/FormRow";
import FlagIcon from "src/components/Icons/FlagIcon";
import NoteIcon from "src/components/Icons/NoteIcon";
import InputContainer from "src/components/Inputs/InputContainer";
import InputLabel from "src/components/Inputs/InputLabel";
import inputStyles from "src/components/Inputs/Inputs.module.css";
import StepContainer from "src/components/Steps/StepContainer";
import useGetCategoriesListQuery from "src/features/categories/hooks/useGetCategoriesListQuery";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import useScrollIntoView from "src/features/page/hooks/useScrollIntoView";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { useAppDispatch } from "src/store";
import { z } from "zod";
import useGetVideoQuery from "../hooks/queries/useGetVideoQuery";
import useVideoFormReducer from "../hooks/useVideoFormReducer";
import {
  setVideoFormStep,
  setVideoId,
  VideoFormSteps,
} from "../redux/videoSlice";
import videoService2 from "../services/videoService2";

const videoDetailsFormSchema = z.object({
  id: z
    .string()
    .transform((value) => parseInt(value, 10))
    .or(z.number())
    .optional(),
  title: z.string().min(1, "Title is required."),
  description: z.string().min(1, "Description is required."),
  category: z.string().min(1, "Please select a category"),
});

export type VideoDetailsFormSchemaType = z.infer<typeof videoDetailsFormSchema>;

export default function VideoDetailsForm() {
  const { id } = useParams<{ id: string }>();
  const scrollRef = useScrollIntoView();
  const { videoId } = useVideoFormReducer();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const handleErrors = useErrorHandling();

  const combinedIds = id || videoId;

  const {
    data: categories,
    isLoading: isCategoriesLoading,
    isError: isCategoriesError,
    isRefetching: isCategoriesRefetching,
    refetch: refetchCategories,
  } = useGetCategoriesListQuery();

  const {
    data: video,
    isInitialLoading: isVideoLoading,
    isError: isVideoError,
    isRefetching: isVideoRefetching,
    refetch: refetchVideo,
  } = useGetVideoQuery({
    id: combinedIds,
  });

  const createVideoMutation = useMutation({
    mutationFn: videoService2.createVideoDetails,
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKeys.Video, String(data.id)], data);
      dispatch(setVideoId(String(data.id)));
      dispatch(setVideoFormStep(VideoFormSteps.UploadVideo));
    },
    onError: handleErrors,
  });

  const partialUpdateVideoMutation = useMutation({
    mutationFn: videoService2.partialUpdateVideo,
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKeys.Video, String(data.id)], data);
      dispatch(setVideoId(String(data.id)));
      dispatch(setVideoFormStep(VideoFormSteps.UploadVideo));
    },
    onError: handleErrors,
  });

  const handleRefetch = useCallback(() => {
    refetchCategories();
    refetchVideo();
  }, [refetchCategories, refetchVideo]);

  const handleSaveVideo = useCallback(
    (data: VideoDetailsFormSchemaType) => {
      if (data.id) {
        partialUpdateVideoMutation.mutate(data);
      } else {
        createVideoMutation.mutate(data);
      }
    },
    [createVideoMutation, partialUpdateVideoMutation]
  );

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<VideoDetailsFormSchemaType>({
    resolver: zodResolver(videoDetailsFormSchema),
  });

  console.log("errors", errors);

  useEffect(() => {
    if (video) {
      reset({
        ...video,
        category: video.category?.id ? String(video.category.id) : undefined,
      });
    } else {
      reset({
        title: "",
        description: "",
        category: undefined,
      });
    }
  }, [video, reset]);

  const isLoading =
    isCategoriesLoading ||
    isVideoLoading ||
    isCategoriesRefetching ||
    isVideoRefetching;

  if (isVideoError || isCategoriesError) {
    return (
      <StepContainer>
        <ErrorFallback onReload={handleRefetch} />
      </StepContainer>
    );
  }
  return (
    <StepContainer>
      <div ref={scrollRef} />
      <div className="mb-20">
        <span className="text-muted">Please add video details.</span>
      </div>
      <EditingContainer>
        <form onSubmit={handleSubmit(handleSaveVideo)}>
          <FormRow>
            <InputContainer>
              <InputLabel htmlFor={"title"} labelIcon={<FlagIcon />}>
                Video Title
              </InputLabel>
              {!isLoading ? (
                <DynamicFormInput
                  register={register}
                  type={"text"}
                  placeholder={"Add a title..."}
                  name={"title"}
                  defaultValue={""}
                  options={[]}
                  optionKey={""}
                  error={
                    errors["title" as keyof VideoDetailsFormSchemaType]?.message
                  }
                  inputClassName={inputStyles.darkInput}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                />
              ) : null}
              {isLoading ? <Skeleton height={40} /> : null}
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer>
              <InputLabel htmlFor={"description"} labelIcon={<FlagIcon />}>
                Video Description
              </InputLabel>
              {!isLoading ? (
                <DynamicFormInput
                  register={register}
                  type={"textarea"}
                  placeholder={"Add a description..."}
                  name={"description"}
                  defaultValue={""}
                  options={[]}
                  optionKey={""}
                  error={
                    errors["description" as keyof VideoDetailsFormSchemaType]
                      ?.message
                  }
                  inputClassName={inputStyles.darkTextArea}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkTextAreaError}
                />
              ) : null}
              {isLoading ? <Skeleton height={100} /> : null}
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer>
              <InputLabel htmlFor={"category"} labelIcon={<NoteIcon />}>
                <div className="flex-1">Category</div>
                {/* <button className="cursor-pointer"
                  style={{
                    all: "unset",
                    height: "16px",
                  }}
                  id={CREATE_A_CATEGORY}
                  onClick={(e) => handleOpenModal(e, CREATE_A_CATEGORY)}
                >
                  <AddRoundedSmallIcon />
                </button> */}
              </InputLabel>
              {!isLoading ? (
                <DynamicFormInput
                  register={register}
                  type={"select"}
                  placeholder={"category"}
                  name={"category"}
                  defaultValue={null}
                  options={categories || []}
                  optionKey={"title"}
                  error={
                    errors["category" as keyof VideoDetailsFormSchemaType]
                      ?.message
                  }
                  inputClassName={inputStyles.darkInput}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                />
              ) : null}
              {isLoading ? <Skeleton height={40} /> : null}
            </InputContainer>
          </FormRow>
          <ButtonsContainer>
            <Button
              color={ButtonColors.Yellow}
              size={ButtonSizes.MD}
              type="submit"
              disabled={isLoading}
            >
              {!isLoading ? (
                "Save and Next"
              ) : (
                <RotatingLines
                  strokeColor="#000"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              )}
            </Button>
          </ButtonsContainer>
        </form>
      </EditingContainer>
    </StepContainer>
  );
}
