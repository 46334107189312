import React from "react";
import useScrollIntoView from "src/features/page/hooks/useScrollIntoView";
import styles from "./Steps.module.css";

interface StepProps {
  icon: React.ReactNode;
  label: string;
}

interface StepsProgressBarV2Props {
  progress: number;
  containerClassName?: string;
  progressClassName?: string;
  steps: StepProps[];
}

function StepsProgressBarV2({
  progress,
  containerClassName,
  progressClassName,
  steps,
}: StepsProgressBarV2Props) {
  const progressWidth = `${progress}%`;
  const scrollref = useScrollIntoView();

  return (
    <div
      className={`${styles.progressBar} ${containerClassName}`}
      ref={scrollref}
    >
      <div
        className={`${styles.progress} ${progressClassName}`}
        style={{ width: progressWidth }}
      ></div>
      {steps?.length &&
        steps?.map((step, index) => {
          const stepPosition = `${(index / (steps.length - 1)) * 100}%`;
          const { icon, label } = step;
          return (
            <React.Fragment key={index}>
              <div
                style={{
                  position: "absolute",
                  left: `calc(${stepPosition} - 21px)`,
                  top: "-21px",
                }}
              >
                {icon ? icon : null}
              </div>
              <div
                className={styles.label}
                style={{
                  left: `calc(${stepPosition} - 22px)`, //same as the icon to center below the icon
                }}
              >
                {label}
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
}

export default StepsProgressBarV2;
