import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import scheduleSetupService from "../services/scheduleSetupService";

export default function useScheduleSetupStateQuery() {
  return useQuery({
    queryFn: scheduleSetupService.getScheduleSetups,
    queryKey: [QueryKeys.SchedulesSetupList],
  });
}
