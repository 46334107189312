import { useEffect } from "react";

import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import StepThreeGrayIcon from "src/components/Icons/StepThreeGrayIcon";
import StepThreeYellowIcon from "src/components/Icons/StepThreeYellowIcon";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepContainer from "src/components/Steps/StepContainer";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import {
  ScheduledTodosFormSteps,
  setScheduledTodosFormStep,
} from "src/features/todos/todosSlice";
import { useAppDispatch } from "src/store";
import usePolicyFormState from "../../hooks/usePolicyFormState";
import { PolicyFormSteps, setPolicyFormStep } from "../../slices/policySlice";
import PolicyScheduleForm from "./PolicyScheduleForm";

export default function PolicyFormStepThree() {
  const { step } = usePolicyFormState();
  const dispatch = useAppDispatch();

  const handleStepButtonClick = () =>
    dispatch(setPolicyFormStep(PolicyFormSteps.AssignToEmployees));

  useEffect(() => {
    dispatch(
      setScheduledTodosFormStep(ScheduledTodosFormSteps.ListScheduledTodos)
    );
  }, [dispatch]);

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: PolicyFormSteps.AssignToEmployees,
    titleAction:
      step !== PolicyFormSteps.AddDetails &&
      step !== PolicyFormSteps.UploadPolicy &&
      step !== PolicyFormSteps.AssignToEmployees ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepThreeYellowIcon />,
    inactiveIcon:
      step === PolicyFormSteps.PublishPolicy ? (
        <CheckCircleYellowIcon />
      ) : (
        <StepThreeGrayIcon />
      ),
    defaultContent: (
      <StepContainer>
        {step !== PolicyFormSteps.AddDetails &&
        step !== PolicyFormSteps.UploadPolicy &&
        step !== PolicyFormSteps.AssignToEmployees ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">
            Assign "To-Do" tasks related to this policy to specific employees,
            roles, or departments. These tasks must be completed by the
            designated recipients. Please note that scheduled tasks will only be
            dispatched once the policy is published.
          </span>
        )}
      </StepContainer>
    ),
    activeContent: <PolicyScheduleForm />,
  });
}
