import { useEffect, useRef, useCallback } from "react";

type WebSocketHookHandlers = {
  onConnecting?: () => void;
  onOpen?: (event: Event) => void;
  onMessage?: (event: MessageEvent) => void;
  onError?: (event: Event) => void;
  onClose?: (event: CloseEvent) => void;
};

function useWebSocket(url: string | null, handlers: WebSocketHookHandlers) {
  const connection = useRef<WebSocket | null>(null);

  const {
    onConnecting = () => {},
    onOpen = () => {},
    onMessage = () => {},
    onError = () => {},
    onClose = () => {},
  } = handlers;

  useEffect(() => {
    if (!url) return;

    onConnecting();

    connection.current = new WebSocket(url);

    connection.current.onopen = onOpen;
    connection.current.onmessage = onMessage;
    connection.current.onerror = onError;
    connection.current.onclose = onClose;

    return () => {
      if (connection.current) connection.current.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const sendWebSocketMessage = useCallback((messageObj: any) => {
    if (connection.current?.readyState === WebSocket.OPEN) {
      connection.current.send(JSON.stringify(messageObj));
    }
  }, []);

  return { sendWebSocketMessage };
}

export default useWebSocket;
