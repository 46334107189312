import { ModuleContentListItem } from "./moduleContentsV2.types";

export enum EmployeeModuleContentStatus {
  Assigned = 0,
  InProgress = 1,
  Completed = 2,
  DidNotComplete = 3,
}

export type EmployeeModuleContentListItem = {
  id: number;
  employee_module: number;
  module_content: ModuleContentListItem;
  sittings: number[];
  started_at: string | null;
  completed_at: string | null;
  status: EmployeeModuleContentStatus;
  time_taken: string | null;
  created_on: string;
  updated_on: string;
};
