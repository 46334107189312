import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import employeeLearningPathsService from "../servicesV2/employeeLearningPathsService";
import learningPathsService from "../servicesV2/learningPathsService";
import { EmployeeLpStatus } from "../types/employeeLearningPathsV2.types";
import { LearningPathListItem } from "../types/learningPathsV2.types";

export interface LearningPathWithStatus extends LearningPathListItem {
  status?: EmployeeLpStatus;
  progress?: number;
  employee_lp_id?: number;
}

const useLearningPathsData = () => {
  const {
    data: learningPaths,
    isLoading: isLearningPathsLoading,
    isError: isLearningPathsError,
    refetch: refetchLearningPaths,
  } = useQuery({
    queryKey: ["learningPaths"],
    queryFn: learningPathsService.getLearningPaths,
  });

  const {
    data: employeeLearningPaths,
    isLoading: isEmployeeLpLoading,
    isError: isEmployeeLpError,
    refetch: refetchEmployeeLp,
  } = useQuery({
    queryKey: ["myRecentEmployeeLearningPaths"],
    queryFn: employeeLearningPathsService.getMyRecentEmployeeLearningPaths,
  });

  const handleRefetch = useCallback(() => {
    refetchLearningPaths();
    refetchEmployeeLp();
  }, [refetchLearningPaths, refetchEmployeeLp]);

  const learningPathsByCategory = useMemo(() => {
    const assignedLearningPaths: LearningPathWithStatus[] = [];
    const addedLearningPathIds = new Set<number>();

    if (employeeLearningPaths) {
      employeeLearningPaths.forEach((elp) => {
        const learningPath = learningPaths?.find(
          (lp) => lp.id === elp.learning_path.id
        );
        if (learningPath && !addedLearningPathIds.has(learningPath.id)) {
          assignedLearningPaths.push({
            ...learningPath,
            status: elp.status || undefined,
            progress: elp.completion_percentage,
            employee_lp_id: elp.id,
          });
          addedLearningPathIds.add(learningPath.id);
        }
      });
    }

    const grouped: Record<string, LearningPathWithStatus[]> = {};
    if (assignedLearningPaths.length > 0) {
      grouped["Assigned to you"] = assignedLearningPaths;
    }

    learningPaths?.forEach((lp) => {
      if (!addedLearningPathIds.has(lp.id)) {
        const employeeLp = employeeLearningPaths?.find(
          (elp) => elp.learning_path.id === lp.id
        );
        const completeLearningPath: LearningPathWithStatus = {
          ...lp,
          status: employeeLp?.status,
          progress: employeeLp?.completion_percentage,
          employee_lp_id: employeeLp?.id,
        };
        const category = lp.category?.title || "Uncategorized";
        grouped[category] = grouped[category] || [];
        grouped[category].push(completeLearningPath);
      }
    });

    // Sort the learning paths within each category
    Object.values(grouped).forEach((learningPaths) =>
      learningPaths.sort((a, b) => {
        if (a.progress !== undefined && b.progress !== undefined) {
          return a.progress - b.progress;
        }
        // Sort by draft status if progress is not available
        if (a.draft && !b.draft) return 1;
        if (!a.draft && b.draft) return -1;
        return 0;
      })
    );

    return grouped;
  }, [learningPaths, employeeLearningPaths]);

  const assignedToYouCategory = learningPathsByCategory["Assigned to you"];
  const nextUpLearningPath = assignedToYouCategory
    ? assignedToYouCategory[0]
    : null;

  return {
    nextUpLearningPath,
    learningPathsByCategory,
    isLoading: isLearningPathsLoading || isEmployeeLpLoading,
    isError: isLearningPathsError || isEmployeeLpError,
    handleRefetch,
  };
};

export default useLearningPathsData;
