const getFileTypeFromAWSLink = (
  url?: string | null
): "image" | "video" | "audio" | "pdf" | undefined => {
  if (!url) return undefined;

  const urlWithoutQuery = url.split("?")[0];
  const extension = urlWithoutQuery.split(".").pop()?.toLowerCase();

  if (extension) {
    if (
      [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "tiff",
        "tif",
        "svg",
        "webp",
      ].includes(extension)
    ) {
      return "image";
    } else if (
      [
        "mp4",
        "webm",
        "ogg",
        "mov",
        "avi",
        "flv",
        "mkv",
        "wmv",
        "m4v",
        "3gp",
      ].includes(extension)
    ) {
      return "video";
    } else if (
      ["mp3", "wav", "aac", "ogg", "m4a", "flac"].includes(extension)
    ) {
      return "audio";
    } else if (["pdf"].includes(extension)) {
      return "pdf";
    }
  }

  return undefined;
};

export default getFileTypeFromAWSLink;
