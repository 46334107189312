import axiosClient from "src/features/axios/client";
import { EmployeeModuleListItem } from "../types/employeeModulesV2.types";

export type GetEmployeeModulesParams = {
  employee_learning_path_id: number;
};

class EmployeeModulesService {
  async getEmployeeModules(
    params: GetEmployeeModulesParams
  ): Promise<EmployeeModuleListItem[]> {
    return await axiosClient
      .get(`/api/employee-modules/`, { params })
      .then((res) => res.data);
  }

  async startEmployeeModule(id: number) {
    return await axiosClient
      .post(`/api/employee-modules/${id}/start/`)
      .then((res) => res.data);
  }

  async markAsCompleted(id: number) {
    return await axiosClient
      .post(`/api/employee-modules/${id}/complete/`)
      .then((res) => res.data);
  }
}

const employeeModulesService = new EmployeeModulesService();

export default employeeModulesService;
