import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import shiftTransferService, {
  ShiftTransferStatus,
  ShiftTransferType,
} from "../services/shiftTransferService";

type UseGetShiftTransfersByDatesQuery = {
  enabled?: boolean;
  startDate?: string;
  endDate?: string;
  type?: ShiftTransferType;
  status?: ShiftTransferStatus | string;
  notFrom?: number;
  departmentId?: number;
};

export default function useGetShiftTransfersByDatesQuery({
  enabled = true,
  startDate,
  endDate,
  type,
  status,
  notFrom,
  departmentId,
}: UseGetShiftTransfersByDatesQuery) {
  return useQuery({
    queryFn: startDate
      ? () =>
          shiftTransferService.getShiftTransfersByStartDate(
            startDate,
            endDate,
            type,
            status,
            notFrom,
            departmentId
          )
      : undefined,
    queryKey: [
      QueryKeys.ShiftTransfersList,
      {
        startDate,
        endDate,
        type,
        status,
        notFrom,
        departmentId,
      },
    ],
    enabled: !!startDate && enabled,

    retry: false,
  });
}
