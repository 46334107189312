import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import timeOffRequestService from "../services/timeOffRequestService";

type UseGetTimeOffRequestByIdQueryProps = {
  id?: string;
};

export default function useGetTimeOffRequestByIdQuery({
  id,
}: UseGetTimeOffRequestByIdQueryProps) {
  return useQuery({
    queryFn: id
      ? () => timeOffRequestService.getTimeOffRequestById(id)
      : undefined,
    queryKey: [QueryKeys.TimeOffRequests, id],
    enabled: !!id,

    retry: false,
  });
}
