// src/components/AssessmentHorizontalListCard/AssessmentHorizontalListCard.tsx

import React from "react";
import Badge from "src/components/Badges/Badge";
import EmployeeImage from "src/components/EmployeeImage/EmployeeImage";
import HorizontalListCard from "src/components/Lists/HorizontalListCard";
import ProgressBar from "src/components/ProgressBar/ProgressBar";
import getFullName from "src/features/employees/utils/getFullName";
import { AssessmentWithCompletion } from "../hooks/useAssessmentsListPageData";

type Props = {
  assessment: AssessmentWithCompletion;
  onClick: (id: number) => void;
};

const AssessmentHorizontalListCard: React.FC<Props> = ({
  assessment,
  onClick,
}) => {
  const header = assessment.created_by ? (
    <>
      <span>
        <Badge
          color={assessment.draft ? "#0096FF" : "#28a745"}
          text={assessment.draft ? "Draft" : "Published"}
        />
      </span>
      <div className="flex flex-row items-center gap-5">
        <EmployeeImage
          src={assessment.created_by.image}
          alt={`${getFullName(assessment.created_by)} image`}
          width={20}
          height={20}
        />
        <span className="text-xs text-muted">
          {getFullName(assessment.created_by)}
        </span>
      </div>
    </>
  ) : null;

  // Body Content
  const body = (
    <>
      <span className="text-lg font-bold">{assessment.title}</span>
      <span className="truncatedDescription text-muted">
        {assessment.description}
      </span>
      {assessment.percent_complete !== undefined && (
        <div className="flex flex-col gap-5">
          <div className="flex flex-row justify-between">
            <span className="text-muted text-xs">Progress</span>
            <span className="text-muted text-xs">
              {assessment.percent_complete || 0}%
            </span>
          </div>
          <ProgressBar
            progress={assessment.percent_complete}
            progressBarStyle={{ height: 10, borderRadius: 5 }}
          />
        </div>
      )}
    </>
  );

  return (
    <HorizontalListCard
      imageSrc={assessment.file_url}
      imageAlt={assessment.title}
      header={header}
      body={body}
      onClick={() => onClick(assessment.id)}
    />
  );
};

export default AssessmentHorizontalListCard;
