import { useAlert } from "@blaumaus/react-alert";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogDescription from "src/components/Dialog/DialogDescription";
import DialogFooter from "src/components/Dialog/DialogFooter";
import DialogHeader from "src/components/Dialog/DialogHeader";
import EmployeeImage from "src/components/EmployeeImage/EmployeeImage";
import inputStyles from "src/components/Inputs/Inputs.module.css";
import SearchInput from "src/components/Inputs/SearchInput";
import SquareCheckboxInput from "src/components/Inputs/SquareCheckboxInput";
import Tab from "src/components/Tabs/Tab";
import TabNavigation from "src/components/Tabs/TabNavigation";
import { getDepartmentsWithRoles } from "src/features/departments/apis";
import useEmployees from "src/features/employees/hooks/useEmployees";
import { AuthenticatedEmployee } from "src/features/employees/types/employees.type";
import NestedCheckBoxInput, {
  Department,
  Role,
} from "src/features/forms/components/NestedCheckBoxesInput";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { z } from "zod";
import styles from "../../../../features/todos/components/ScheduledTodosForm.module.css";
import useCreateBulkDocumentMutation from "../../hooks/useCreateBulkDocumentMutation";
import { DocumentStatus } from "../../types/document.types";
import { DocumentTemplate } from "../../types/template.types";

const documentAssignSchema = z.object({
  roles: z.array(z.number()).optional(),
  employees: z.array(z.number()).optional(),
});

type DocumentAssignForm = z.infer<typeof documentAssignSchema>;

type DocumentAssignDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  template: DocumentTemplate;
};

const TAB_DEPARTMENTS = "Roles";
const TAB_EMPLOYEES = "Employees";

export default function DocumentBulkAssignDialog({
  isOpen,
  setIsOpen,
  template,
}: DocumentAssignDialogProps) {
  const alert = useAlert();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<DocumentAssignForm>({
    resolver: zodResolver(documentAssignSchema),
    defaultValues: {
      roles: [],
      employees: [],
    },
  });

  const { data: employees } = useEmployees({});

  const handleSuccessfulSave = () => {
    queryClient.invalidateQueries([QueryKeys.DocumentsList]);
    setIsOpen(false);
    reset({
      roles: [],
      employees: [],
    });
    alert.success("Documents assigned successfully!");
    navigate("/e-signatures?documentsTab=Pending");
  };

  const createBulkDocumentMutation = useCreateBulkDocumentMutation({
    onSuccess: () => handleSuccessfulSave(),
  });

  const onSubmit = (data: DocumentAssignForm) => {
    createBulkDocumentMutation.mutate({
      template: template.id,
      status: DocumentStatus.Draft,
      roles: data.roles,
      employees: data.employees,
    });
  };

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      onClose={() =>
        reset({
          roles: [],
          employees: [],
        })
      }
    >
      <DialogHeader>Assign Document</DialogHeader>
      <DialogDescription>
        Assign the document template "{template.title}" to employees
      </DialogDescription>
      <form onSubmit={handleSubmit(onSubmit)} className="dialog-form">
        <DialogContent>
          <TabNavigation id="assign-tabs">
            <Tab title={TAB_DEPARTMENTS}>
              <NestedCheckBoxInput<Department, Role>
                key="departments"
                register={register}
                setValue={setValue}
                queryFn={getDepartmentsWithRoles}
                inputName="roles"
                itemName="name"
                subItemName="name"
                subItemKey="roles"
                defaultSelectedSubIds={[]}
                error={errors.roles?.message}
              />
            </Tab>
            <Tab title={TAB_EMPLOYEES}>
              <SearchInput
                id="employeeSearch"
                placeholder="Search employees by name or role..."
                inputClassName={inputStyles.darkInputTextIndent30}
                containerClassName={inputStyles.inputContainer}
              />
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                <div className={styles.employeeCheckboxesContainer}>
                  {employees?.map((employee: AuthenticatedEmployee) => (
                    <div key={employee.id} className={styles.employeeCheckbox}>
                      <Controller
                        control={control}
                        name="employees"
                        defaultValue={[]}
                        render={({ field }) => (
                          <SquareCheckboxInput
                            id={employee.id.toString()}
                            name={employee.id.toString()}
                            label=""
                            checked={
                              field.value?.includes(employee.id) ?? false
                            }
                            onChange={(e) => {
                              const currentValues = field.value ?? [];
                              const updatedValues = e.target.checked
                                ? [...currentValues, employee.id]
                                : currentValues.filter(
                                    (id) => id !== employee.id
                                  );

                              field.onChange(updatedValues);
                            }}
                            value={employee.id}
                          />
                        )}
                      />

                      <div className={styles.employeeAvatar}>
                        <EmployeeImage
                          containerStyle={{ width: "30px", height: "30px" }}
                          alt={employee.username}
                          src={employee.image}
                        />
                      </div>
                      <div className={styles.employeeTextContainer}>
                        <div className={styles.employeeName}>
                          {employee.first_name} {employee.last_name}
                        </div>
                        <div className={styles.employeeRole}>
                          {employee.role.name}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Tab>
          </TabNavigation>
        </DialogContent>
        <DialogFooter>
          <Button
            color={ButtonColors.GrayAndYellow}
            onClick={() => setIsOpen(false)}
            disabled={createBulkDocumentMutation.isLoading}
          >
            {createBulkDocumentMutation.isLoading ? (
              <RotatingLines
                strokeColor="#f1b70c"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Cancel"
            )}
          </Button>
          <Button
            color={ButtonColors.Yellow}
            type="submit"
            disabled={createBulkDocumentMutation.isLoading}
          >
            {createBulkDocumentMutation.isLoading ? (
              <RotatingLines
                strokeColor="#000"
                strokeWidth="5"
                animationDuration="0.75"
                width="20"
                visible={true}
              />
            ) : (
              "Assign Document"
            )}
          </Button>
        </DialogFooter>
      </form>
    </Dialog>
  );
}
