import BlankYellowCircle from "../Icons/BlankYellowCircle";
import { RotatingLines } from "react-loader-spinner";

export default function YellowCircleLoadingSpinner() {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <RotatingLines
          strokeColor="#000"
          strokeWidth="5"
          animationDuration="0.75"
          width="35"
          visible={true}
        />
      </div>
      <BlankYellowCircle />
    </div>
  );
}
