import Skeleton from "react-loading-skeleton";

import React, { useCallback } from "react";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import StepContainer from "src/components/Steps/StepContainer";
import ScheduledTodosForm from "src/features/todos/components/ScheduledTodosForm";
import ScheduledTodosTable from "src/features/todos/components/ScheduledTodosTable";
import useGetScheduledTodosListQuery from "src/features/todos/hooks/queries/useGetScheduledTodosListQuery";
import useScheduledTodosFormState from "src/features/todos/hooks/redux/useScheduledTodosFormState";
import { ScheduledTodosFormSteps } from "src/features/todos/todosSlice";
import { TodoObjectTypes } from "src/features/todos/types/todosEnums.types";
import { useAppDispatch } from "src/store";
import useGetVideoQuery from "../hooks/queries/useGetVideoQuery";
import useVideoFormReducer from "../hooks/useVideoFormReducer";
import { setVideoFormStep, VideoFormSteps } from "../redux/videoSlice";

function VideoScheduleContent() {
  const dispatch = useAppDispatch();
  const { videoId, step } = useVideoFormReducer();
  const { scheduledTodosFormStep } = useScheduledTodosFormState();

  const {
    data: video,
    isLoading: isVideoLoading,
    isError: isVideoError,
    isRefetching: isVideoRefetching,
    refetch: refetchVideo,
  } = useGetVideoQuery({
    id: videoId,
  });

  const {
    data: scheduledTodos,
    isLoading: isLoadingScheduledTodos,
    isError: isScheduledTodosError,
    isRefetching: isScheduledTodosRefetching,
    refetch: refetchScheduledTodos,
  } = useGetScheduledTodosListQuery({
    objectId: videoId,
    objectType: TodoObjectTypes.Video,
  });

  const isLoading =
    isVideoLoading ||
    isLoadingScheduledTodos ||
    isVideoRefetching ||
    isScheduledTodosRefetching;

  const handleGoToPublishStep = () =>
    dispatch(setVideoFormStep(VideoFormSteps.Publish));

  const handleRefetch = useCallback(() => {
    refetchVideo();
    refetchScheduledTodos();
  }, [refetchScheduledTodos, refetchVideo]);

  if (isVideoError || isScheduledTodosError) {
    return (
      <StepContainer>
        <ErrorFallback onReload={handleRefetch} />
      </StepContainer>
    );
  }

  if (isLoading) {
    return (
      <StepContainer>
        <Skeleton height={200} />
      </StepContainer>
    );
  }

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">Assign the video to employees.</span>
      </div>
      {scheduledTodosFormStep === ScheduledTodosFormSteps.ListScheduledTodos ? (
        <ScheduledTodosTable
          scheduledTodos={scheduledTodos}
          isScheduleStep={step === VideoFormSteps.AssignEmployees}
          onNextStep={handleGoToPublishStep}
        />
      ) : null}
      {video &&
      scheduledTodosFormStep ===
        ScheduledTodosFormSteps.CreateUpdateScheduledTodos ? (
        <ScheduledTodosForm
          objectId={video.id}
          objectType={TodoObjectTypes.Video}
          onSuccess={handleGoToPublishStep}
        />
      ) : null}
    </StepContainer>
  );
}

export default React.memo(VideoScheduleContent);
