import StepFourGrayIcon from "src/components/Icons/StepFourGrayIcon";
import StepFourYellowIcon from "src/components/Icons/StepFourYellowIcon";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepContainer from "src/components/Steps/StepContainer";
import usePolicyFormState from "../../hooks/usePolicyFormState";
import { PolicyFormSteps } from "../../slices/policySlice";
import PolicyPublishForm from "./PolicyPublishForm";

export default function PolicyFormStepFour() {
  const { step } = usePolicyFormState();

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: PolicyFormSteps.PublishPolicy,
    titleAction: null,
    activeIcon: <StepFourYellowIcon />,
    inactiveIcon: <StepFourGrayIcon />,
    defaultContent: (
      <StepContainer>
        <span className="text-muted">
          Publish the policy or save it as a draft. Once published, the policy
          will be sent to the designated recipients assigned in the Schedules.
          Saving as draft will remove any assigned "To-Do" tasks employees have
          already received.
        </span>
      </StepContainer>
    ),
    activeContent: <PolicyPublishForm />,
  });
}
