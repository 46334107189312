import { ModuleListItem } from "./modulesV2.types";

export enum EmployeeModuleStatus {
  Assigned = 0,
  InProgress = 1,
  Completed = 2,
  DidNotComplete = 3,
}

export type EmployeeModuleListItem = {
  id: number;
  employee_learning_path: number;
  module: ModuleListItem;
  started_at: string;
  completed_at: string;
  status: EmployeeModuleStatus;
  completion_percentage: number;
  time_taken: string;
  overall_score: number;
  created_on: string;
  updated_on: string;
};
