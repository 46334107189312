import { useEffect } from "react";
import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import StepThreeGrayIcon from "src/components/Icons/StepThreeGrayIcon";
import StepThreeYellowIcon from "src/components/Icons/StepThreeYellowIcon";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepContainer from "src/components/Steps/StepContainer";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import {
  ScheduledTodosFormSteps,
  setScheduledTodosFormStep,
} from "src/features/todos/todosSlice";
import { useAppDispatch } from "src/store";
import useMemoFormState from "../../hooks/useMemoFormState";
import { MemoFormSteps, setMemoFormStep } from "../../slices/memoSlice";
import MemoScheduleContent from "./MemoScheduleForm";

export default function MemoFormStepThree() {
  const { step } = useMemoFormState();
  const dispatch = useAppDispatch();

  const handleStepButtonClick = () =>
    dispatch(setMemoFormStep(MemoFormSteps.AssignToEmployees));

  useEffect(() => {
    dispatch(
      setScheduledTodosFormStep(ScheduledTodosFormSteps.ListScheduledTodos)
    );
  }, [dispatch]);

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: MemoFormSteps.AssignToEmployees,
    titleAction:
      step !== MemoFormSteps.AddDetails &&
      step !== MemoFormSteps.UploadFile &&
      step !== MemoFormSteps.AssignToEmployees ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepThreeYellowIcon />,
    inactiveIcon:
      step === MemoFormSteps.PublishMemo ? (
        <CheckCircleYellowIcon />
      ) : (
        <StepThreeGrayIcon />
      ),
    defaultContent: (
      <StepContainer>
        {step !== MemoFormSteps.AddDetails &&
        step !== MemoFormSteps.UploadFile &&
        step !== MemoFormSteps.AssignToEmployees ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">
            Assign "To-Do" tasks related to this memo to specific employees,
            roles, or departments. These tasks must be completed by the
            designated recipients. Please note that scheduled tasks will only be
            dispatched once the memo is published.
          </span>
        )}
      </StepContainer>
    ),
    activeContent: <MemoScheduleContent />,
  });
}
