import { MdArrowForward } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Card from "src/components/Cards/Card";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import SittingResult from "../components/SittingResult";
import useGetSitting from "../hooks/useGetSitting";

export default function SittingCompletedPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    data: sitting,
    isInitialLoading: isSittingLoading,
    isRefetching: isSittingRefetching,
    isError: isSittingError,
    refetch: refetchSitting,
  } = useGetSitting({ id });

  const handleNavigateToAssessments = () => navigate("/assessments");

  if (isSittingLoading || isSittingRefetching || !sitting) {
    return (
      <Card style={{ display: "flex", flexDirection: "column", padding: 10 }}>
        <Skeleton height={500} />
      </Card>
    );
  }

  if (isSittingError) {
    return (
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ErrorFallback onReload={refetchSitting} />
      </Card>
    );
  }

  return (
    <Card className="flex">
      <div className="flex flex-1 items-center text-3xl font-bold text-primary py-10 border-bottom-light-gray">
        Assessment Results
      </div>
      <div className="flex flex-col items-end p-10">
        <Button
          color={ButtonColors.Yellow}
          onClick={handleNavigateToAssessments}
        >
          Back to assessments
          <MdArrowForward size={20} />
        </Button>
        <SittingResult sitting={sitting} />
        <Button
          color={ButtonColors.Yellow}
          onClick={handleNavigateToAssessments}
        >
          Back to assessments
          <MdArrowForward size={20} />
        </Button>
      </div>
    </Card>
  );
}
