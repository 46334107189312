import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import templateService from "../services/templateService";
import { DocumentTemplate } from "../types/template.types";

export default function useGetTemplates() {
  return useQuery<DocumentTemplate[]>({
    queryKey: [QueryKeys.DocumentTemplateList],
    queryFn: templateService.getTemplates,
  });
}
