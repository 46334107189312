import { useQuery } from "@tanstack/react-query";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import Card from "src/components/Cards/Card";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import ModuleContentTitleAndDescription from "src/features/learningPaths/components/LPTake/ModuleContentTitleAndDescription";
import ProgressBarTimer from "src/features/progress/components/ProgressBarTimer";
import SittingQuestionTakeForm from "../components/SittingQuestionTakeForm";
import sittingTakeService from "../services/sittingTakeService";
import { SittingStatus } from "../types/sittings.types";

function SittingTakeV2Page() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [questionNumber, setQuestionNumber] = React.useState(0);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = React.useState(true);

  const {
    data: sitting,
    isLoading: isSittingLoading,
    isError: isSittingError,
    refetch: refetchSitting,
    isRefetching: isSittingRefetching,
  } = useQuery({
    queryKey: ["mySittingTake", id],
    queryFn: id
      ? () => sittingTakeService.getMySittingTake(Number(id))
      : undefined,

    enabled: !!id,
    onSuccess: (data) => {
      if (data?.status !== SittingStatus.InProgress) {
        navigate(`/assessments/sittings/${id}/result`);
      }
    },
  });

  if (isSittingLoading || isSittingRefetching) {
    return (
      <Card className="flex flex-col p-10 gap-10">
        <Skeleton height={500} />
      </Card>
    );
  }

  if (isSittingError) {
    return (
      <Card className="flex flex-col flex-1 justify-center items-center">
        <ErrorFallback onReload={refetchSitting} />
      </Card>
    );
  }

  return (
    <Card className="flex flex-col p-10 gap-10">
      <ProgressBarTimer
        timeLimit={sitting.assessment?.time_limit}
        startTime={sitting.start}
        currentTime={sitting.current_time}
        currentQuestionNum={questionNumber}
        totalQuestions={sitting.max_score}
        onTimeOutCallback={refetchSitting}
      />
      <ModuleContentTitleAndDescription
        title={sitting.assessment?.title}
        description={sitting.assessment?.description}
      />
      <SittingQuestionTakeForm
        sitting={sitting}
        isNextBtnDisabled={isNextBtnDisabled}
        onRefetchSitting={refetchSitting}
        onSetQuestionNumber={setQuestionNumber}
        setIsNextBtnDisabled={setIsNextBtnDisabled}
      />
    </Card>
  );
}

export default React.memo(SittingTakeV2Page);
