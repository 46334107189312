import { SittingStatus } from "../types/sittings.types";

type color = "Green" | "White" | "Yellow" | "Red" | undefined;

function getSittingResultContainerColor(status?: SittingStatus): color {
  switch (status) {
    case SittingStatus.Passed:
      return "Green";
    case SittingStatus.Failed:
      return "Red";
    case SittingStatus.InProgress:
      return "White";
    case SittingStatus.TimeExpired:
      return "Red";
    case SittingStatus.Assigned:
      return "White";
    default:
      return "White";
  }
}

export default getSittingResultContainerColor;
