import StepFiveGrayIcon from "src/components/Icons/StepFiveGrayIcon";
import StepFiveYellowIcon from "src/components/Icons/StepFiveYellowIcon";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepContainer from "src/components/Steps/StepContainer";
import useVideoFormReducer from "../hooks/useVideoFormReducer";
import { VideoFormSteps } from "../redux/videoSlice";
import VideoPublishForm from "./VideoPublishForm";

export default function VideoFormStepFive() {
  const { step } = useVideoFormReducer();

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: VideoFormSteps.Publish,
    titleAction: null,
    activeIcon: <StepFiveYellowIcon />,
    inactiveIcon: <StepFiveGrayIcon />,
    defaultContent: (
      <StepContainer>
        <span className="text-muted">
          Publish the video or save it as a draft.
        </span>
      </StepContainer>
    ),
    activeContent: <VideoPublishForm />,
  });
}
