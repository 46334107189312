import { FileUploadResponse } from "src/components/Forms/DragAndDropFileInput";
import axiosClient from "src/features/axios/client";
import { UploadProgressCallback } from "src/features/files/hooks/useFileUpload";
import { handleUploadProgress } from "src/features/files/utils/handleUploadProgress";

class S3Service {
  async getPresignedUrl(key: string) {
    return await axiosClient
      .post("/api/s3-storage/get-presigned-url/", { key })
      .then((res) => res.data);
  }

  async uploadFileToS3(
    presigned_url: string,
    file: File,
    uploadProgressCallback?: UploadProgressCallback
  ): Promise<FileUploadResponse> {
    return await axiosClient.put(presigned_url, file, {
      headers: { "Content-Type": file.type },
      onUploadProgress: uploadProgressCallback
        ? (progressEvent) =>
            handleUploadProgress(progressEvent, uploadProgressCallback)
        : undefined,
    });
  }
}

const s3Service = new S3Service();

export default s3Service;
