import { useQuery } from "@tanstack/react-query";

import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useSearch from "src/features/search/hooks/useSearch";
import { useDebounce } from "src/hooks/useDebounce";
import departmentsService from "../services/departmentsService";

export default function useGetDepartmentsListQuery() {
  const { searchTerm: rawSearchTerm } = useSearch();
  const searchTerm = useDebounce(rawSearchTerm, 700);
  const handleErrors = useErrorHandling();

  return useQuery({
    queryKey: [QueryKeys.DepartmentsList],
    queryFn: () => departmentsService.getDepartments({ search: searchTerm }),
    onError: handleErrors,

    retry: 1,
  });
}
