import * as React from "react"
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    fill="none"
    {...props}
  >
    <circle cx={21} cy={21} r={21} fill="url(#a)" />
    <path
      fill="#000"
      d="M18.144 28.03c-.229.208-.553.382-.839.382-.286 0-.61-.182-.849-.39l-5.339-4.853 1.697-1.543 4.5 4.09 11.9-10.893 1.668 1.569L18.144 28.03Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={21}
        x2={21}
        y1={0}
        y2={42}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEC110" />
        <stop offset={0} stopColor="#FEC110" />
        <stop offset={1} stopColor="#CC9800" />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgComponent
