import React from "react";
import { MdArrowForward } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import HorizontalScrollList from "src/components/Lists/HorizontalScrollList";
import VideoPreviewInfo from "src/features/videos/components/VideoPreviewInfo";
import LearningPathHorizontalListCard from "../componentsV2/LearningPathHorizontalListCard";
import useLearningPathsData from "../hooksV2/useLearningPathsListPageData";
import { EmployeeLpStatus } from "../types/employeeLearningPathsV2.types";

function LearningPathsListPage() {
  const navigate = useNavigate();

  const {
    nextUpLearningPath,
    learningPathsByCategory,
    isLoading,
    isError,
    handleRefetch,
  } = useLearningPathsData();

  const handleNavigateToLearningPath = (id: number) =>
    navigate(`/learning-paths/${id}`);

  if (isLoading) {
    const numberOfLists = 3;
    const skeletonItemsPerList = 5;

    const generateSkeletonItems = () =>
      Array.from({ length: skeletonItemsPerList }).map((_, index) => (
        <div key={index}>
          <Skeleton height={380} width={300} />
        </div>
      ));

    return (
      <div className="flex flex-col gap-20">
        {Array.from({ length: numberOfLists }).map((_, index) => (
          <HorizontalScrollList
            key={index}
            title={<Skeleton width={150} height={20} />}
            items={generateSkeletonItems()}
          />
        ))}
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <ErrorFallback onReload={handleRefetch} />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-20">
      {/* Display the "Next up" section if a learning path is assigned */}
      {nextUpLearningPath && (
        <div className="flex flex-col featureItemContainer">
          <VideoPreviewInfo
            superTitle={
              nextUpLearningPath.status === EmployeeLpStatus.InProgress
                ? "Resume Learning Path"
                : "Next up"
            }
            title={nextUpLearningPath.title}
            subTitle={nextUpLearningPath.description}
            actions={
              nextUpLearningPath ? (
                <>
                  <Button
                    color={ButtonColors.Yellow}
                    onClick={() =>
                      handleNavigateToLearningPath(nextUpLearningPath.id)
                    }
                    dataTestid="next-learning-path-button"
                  >
                    <MdArrowForward size={20} />
                    {nextUpLearningPath.status === EmployeeLpStatus.InProgress
                      ? "Resume learning path"
                      : "Go to learning path"}
                  </Button>
                </>
              ) : null
            }
          />
          <div className="featureItemImageContainer">
            {nextUpLearningPath.file_url ? (
              <img
                src={nextUpLearningPath.file_url}
                alt={nextUpLearningPath.title}
                className="w-full object-cover border-radius-10"
              />
            ) : null}
          </div>
          <div className="featureItemOverlay" />
        </div>
      )}

      {/* Horizontal scroll lists for each category */}
      {Object.entries(learningPathsByCategory)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([categoryName, learningPaths]) => (
          <HorizontalScrollList
            key={categoryName}
            title={categoryName}
            items={learningPaths.map((learningPath) => (
              <LearningPathHorizontalListCard
                key={learningPath.id}
                learningPath={learningPath}
                onClick={handleNavigateToLearningPath}
              />
            ))}
          />
        ))}
    </div>
  );
}

export default React.memo(LearningPathsListPage);
