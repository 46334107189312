import React, { useRef, useState } from "react";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import VideoPlayer from "src/features/videos/components/VideoPlayer";
import useVideoActions from "src/features/videos/hooks/useVideoActions";
import createTimeTriggerAction from "src/features/videos/utils/createTimeTriggerAction";
import ModuleContentTitleAndDescription from "../../components/LPTake/ModuleContentTitleAndDescription";
import {
  EmployeeModuleContentListItem,
  EmployeeModuleContentStatus,
} from "../../types/employeeModuleContentsV2.types";
import getModuleContentTypeString from "../../utils/getModuleContentTypeString";

type Props = {
  employeeModuleContent: EmployeeModuleContentListItem | null;
  onClickNext: () => void;
  onModuleContentCompleted: () => void;
};

function EmployeeModuleContentVideo({
  employeeModuleContent,
  onClickNext,
  onModuleContentCompleted,
}: Props) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoLengthTriggered, setVideoLengthTriggered] = useState(false);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(
    employeeModuleContent?.status === EmployeeModuleContentStatus.InProgress ||
      employeeModuleContent?.status === EmployeeModuleContentStatus.Assigned
  );

  const handleVideoComplete = () => {
    if (!employeeModuleContent) return;
    onModuleContentCompleted();
    setIsNextBtnDisabled(false);
  };

  const timeUpdateAction = createTimeTriggerAction(
    videoRef,
    handleVideoComplete,
    videoLengthTriggered,
    setVideoLengthTriggered
  );

  useVideoActions(videoRef, { timeupdate: timeUpdateAction });

  const title = `${getModuleContentTypeString(
    employeeModuleContent?.module_content?.content_type
  )}: ${employeeModuleContent?.module_content?.content_object?.title}`;

  const description =
    employeeModuleContent?.module_content?.content_object?.description ||
    "No description";

  const fileUrl =
    employeeModuleContent?.module_content?.content_object.file_url;

  return (
    <div className="flex p-10 card">
      <ModuleContentTitleAndDescription
        title={title}
        description={description}
      />
      <div className="flex py-10 items-end">
        <Button
          className="border-radius-5"
          color={ButtonColors.Yellow}
          size={ButtonSizes.LG}
          title="Next"
          type="button"
          onClick={onClickNext}
          disabled={isNextBtnDisabled}
        />
      </div>
      {fileUrl ? (
        <VideoPlayer src={fileUrl} ref={videoRef} autoPlay={true} controls />
      ) : (
        <div className="flex centered min-h-300">No video found</div>
      )}
      <div className="flex py-10 items-end">
        <Button
          className="border-radius-5"
          color={ButtonColors.Yellow}
          size={ButtonSizes.LG}
          title="Next"
          type="button"
          onClick={onClickNext}
          disabled={isNextBtnDisabled}
        />
      </div>
    </div>
  );
}

export default React.memo(EmployeeModuleContentVideo);
