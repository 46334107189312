import React from "react";
import cn from "src/features/utils/cn";
import styles from "./CardHeaderAction.module.css";

type CardHeaderActionProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export default function CardHeaderAction({
  children,
  className,
  style,
}: CardHeaderActionProps) {
  return (
    <div className={cn(styles.action, className)} style={style}>
      {children}
    </div>
  );
}
