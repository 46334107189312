import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { getSittingQuestion } from "../apis";

type Props = {
  emcId: string;
  id: string;
  enabled: boolean;
};

export default function useGetLearningPathSittingQuestion({
  emcId,
  id,
  enabled,
}: Props) {
  const handleErrors = useErrorHandling();
  return useQuery({
    queryKey: [QueryKeys.MyLearningPathAssessmentTake],
    queryFn: () => getSittingQuestion({ id, emcId }),
    onError: (error: AxiosError) => handleErrors(error),

    enabled: !!id && !!emcId && enabled,
  });
}
