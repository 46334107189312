import React from "react";
import { MdArrowForward } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import HorizontalScrollList from "src/components/Lists/HorizontalScrollList";
import VideoPreviewInfo from "src/features/videos/components/VideoPreviewInfo";
import useAssessmentsData from "../hooks/useAssessmentsListPageData";
import { SittingStatus } from "../types/sittings.types";
import AssessmentHorizontalListCard from "./AssessmentHorizontalListCard";

function AssessmentsListPage() {
  const navigate = useNavigate();

  const {
    nextUpAssessment,
    assessmentsByCategory,
    isLoading,
    isError,
    handleRefetch,
  } = useAssessmentsData();

  const handleNavigateToAssessment = (id: number) =>
    navigate(`/assessments/${id}`);

  const handleNavigateToSitting = (id: number) =>
    navigate(`/assessments/sittings/${id}/take`);

  if (isLoading) {
    const numberOfLists = 3;
    const skeletonItemsPerList = 5;

    const generateSkeletonItems = () =>
      Array.from({ length: skeletonItemsPerList }).map((_, index) => (
        <div key={index}>
          <Skeleton height={380} width={300} />
        </div>
      ));

    return (
      <div className="flex flex-col gap-20">
        {Array.from({ length: numberOfLists }).map((_, index) => (
          <HorizontalScrollList
            key={index}
            title={<Skeleton width={150} height={20} />}
            items={generateSkeletonItems()}
          />
        ))}
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <ErrorFallback
          error="Oops! An error occurred!"
          onReload={handleRefetch}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-20">
      {/* Display the "Next up" section if an assessment is assigned */}
      {nextUpAssessment && (
        <div className="flex flex-col featureItemContainer">
          <VideoPreviewInfo
            superTitle={
              nextUpAssessment?.status === SittingStatus.InProgress
                ? "Resume Assessment"
                : "Next up"
            }
            title={nextUpAssessment?.title}
            subTitle={nextUpAssessment?.description}
            actions={
              nextUpAssessment ? (
                <>
                  <Button
                    color={ButtonColors.Yellow}
                    onClick={
                      nextUpAssessment?.status === SittingStatus.InProgress &&
                      nextUpAssessment.sitting_id
                        ? () =>
                            nextUpAssessment.sitting_id !== undefined &&
                            handleNavigateToSitting(nextUpAssessment.sitting_id)
                        : () => handleNavigateToAssessment(nextUpAssessment.id)
                    }
                    dataTestid="next-assessment-button"
                  >
                    <MdArrowForward size={20} />
                    {nextUpAssessment?.status === SittingStatus.InProgress
                      ? "Resume assessment"
                      : "Go to assessment"}
                  </Button>
                </>
              ) : null
            }
          />
          <div className="featureItemImageContainer">
            <img
              src={nextUpAssessment.file_url}
              alt={nextUpAssessment.title}
              className="w-full object-cover border-radius-10"
            />
          </div>
          <div className="featureItemOverlay" />
        </div>
      )}

      {/* Horizontal scroll lists for each category */}
      {Object.entries(assessmentsByCategory)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([categoryName, assessments]) => (
          <HorizontalScrollList
            key={categoryName}
            title={categoryName}
            items={assessments.map((assessment) => (
              <AssessmentHorizontalListCard
                key={assessment.id}
                assessment={assessment}
                onClick={handleNavigateToAssessment}
              />
            ))}
          />
        ))}
    </div>
  );
}

export default React.memo(AssessmentsListPage);
