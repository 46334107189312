import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import EditPencilYellowSmallIcon from "src/components/Icons/EditPencilYellowSmallIcon";
import RedTrashIcon from "src/components/Icons/RedTrashIcon";
import { useAppDispatch } from "src/store";
import useDeleteScheduledTodoMutation from "../hooks/mutations/useDeleteScheduledTodoMutation";
import {
  ScheduledTodosFormSteps,
  setScheduledTodoToUpdate,
  setScheduledTodosFormStep,
} from "../todosSlice";
import { ScheduledTodoStatus } from "../types/todosEnums.types";

type ScheduledTodosTableProps = {
  scheduledTodos: any;
  onNextStep: () => void;
  isScheduleStep: boolean;
};

export default function ScheduledTodosTable({
  scheduledTodos,
  isScheduleStep,
  onNextStep,
}: ScheduledTodosTableProps) {
  const dispatch = useAppDispatch();
  const deleteScheduledTodosMutation = useDeleteScheduledTodoMutation();

  const handleCreateScheduledTodos = () =>
    dispatch(
      setScheduledTodosFormStep(
        ScheduledTodosFormSteps.CreateUpdateScheduledTodos
      )
    );

  const handleEditScheduledTodo = (
    e: React.MouseEvent<Element, MouseEvent>
  ) => {
    dispatch(
      setScheduledTodosFormStep(
        ScheduledTodosFormSteps.CreateUpdateScheduledTodos
      )
    );
    dispatch(setScheduledTodoToUpdate(e.currentTarget.id));
  };

  const handleDeleteScheduledTodo = (
    e: React.MouseEvent<Element, MouseEvent>
  ) => deleteScheduledTodosMutation.mutate({ id: e.currentTarget.id });

  return (
    <>
      <div className={"tableContainer"}>
        <table className={"table"}>
          {scheduledTodos?.length > 0 ? (
            <thead>
              <tr>
                <th>Status</th>
                {isScheduleStep ? <th>Actions</th> : null}
              </tr>
            </thead>
          ) : null}
          <tbody>
            {scheduledTodos?.length > 0 ? (
              scheduledTodos.map((scheduledTodo: any) => (
                <tr key={scheduledTodo.id}>
                  <td>
                    {scheduledTodo.status === ScheduledTodoStatus.Assigned
                      ? "Assigned"
                      : scheduledTodo.status === ScheduledTodoStatus.Draft
                      ? "Draft"
                      : scheduledTodo.status === ScheduledTodoStatus.Published
                      ? "Published"
                      : ""}
                  </td>
                  {isScheduleStep ? (
                    <td>
                      <Button
                        id={scheduledTodo.id}
                        color={ButtonColors.Transparent}
                        size={ButtonSizes.SM}
                        onClick={handleEditScheduledTodo}
                      >
                        <EditPencilYellowSmallIcon />
                      </Button>
                      <Button
                        color={ButtonColors.Transparent}
                        size={ButtonSizes.SM}
                        id={scheduledTodo.id}
                        onClick={handleDeleteScheduledTodo}
                      >
                        <RedTrashIcon />
                      </Button>
                    </td>
                  ) : null}
                </tr>
              ))
            ) : (
              <tr>
                <td>No schedules yet...</td>
                <td></td>
                {isScheduleStep ? <td></td> : null}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isScheduleStep ? (
        <ButtonsContainer>
          <Button
            color={ButtonColors.GrayAndYellow}
            title={"Assign to Employees"}
            onClick={handleCreateScheduledTodos}
          />
          <Button
            color={ButtonColors.Yellow}
            title={"Save and Next"}
            onClick={onNextStep}
          />
        </ButtonsContainer>
      ) : null}
    </>
  );
}
