import React from "react";
import Badge from "src/components/Badges/Badge";
import EmployeeImage from "src/components/EmployeeImage/EmployeeImage";
import HorizontalListCard from "src/components/Lists/HorizontalListCard";
import ProgressBar from "src/components/ProgressBar/ProgressBar";
import getFullName from "src/features/employees/utils/getFullName";
import { LearningPathWithStatus } from "../hooksV2/useLearningPathsListPageData";

type Props = {
  learningPath: LearningPathWithStatus;
  onClick: (id: number) => void;
};

function LearningPathHorizontalListCard({ learningPath, onClick }: Props) {
  const header = learningPath.created_by ? (
    <>
      <span>
        <Badge
          color={learningPath.draft ? "#FFA500" : "#28a745"}
          text={learningPath.draft ? "Draft" : "Published"}
        />
      </span>
      <div className="flex flex-row items-center gap-5">
        <EmployeeImage
          src={learningPath.created_by.image}
          alt={`${getFullName(learningPath.created_by)} image`}
          width={20}
          height={20}
        />
        <span className="text-xs text-muted">
          {getFullName(learningPath.created_by)}
        </span>
      </div>
    </>
  ) : null;

  // Body Content
  const body = (
    <>
      <span className="text-lg font-bold">{learningPath.title}</span>
      <span className="truncatedDescription text-muted">
        {learningPath.description}
      </span>
      {learningPath.progress !== undefined && (
        <div className="flex flex-col gap-5">
          <div className="flex flex-row justify-between">
            <span className="text-muted text-xs">Progress</span>
            <span className="text-muted text-xs">
              {learningPath.progress || 0}%
            </span>
          </div>
          <ProgressBar
            progress={learningPath.progress}
            progressBarStyle={{ height: 10, borderRadius: 5 }}
          />
        </div>
      )}
    </>
  );

  return (
    <HorizontalListCard
      imageSrc={learningPath.file_url}
      imageAlt={learningPath.title}
      header={header}
      body={body}
      onClick={() => onClick(learningPath.id)}
    />
  );
}

export default React.memo(LearningPathHorizontalListCard);
