import { useAlert } from "@blaumaus/react-alert";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import DynamicFormInput from "src/components/DynamicForm/DynamicFormInput";
import FormRow from "src/components/Forms/FormRow";
import AddRoundedSmallIcon from "src/components/Icons/AddRoundedSmallIcon";
import FlagIcon from "src/components/Icons/FlagIcon";
import NoteIcon from "src/components/Icons/NoteIcon";
import InputContainer from "src/components/Inputs/InputContainer";
import InputLabel from "src/components/Inputs/InputLabel";
import inputStyles from "src/components/Inputs/Inputs.module.css";
import StepContainer from "src/components/Steps/StepContainer";
import { SELECT } from "src/features/forms/inputTypes";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import { setModalStateAction } from "src/features/modal/handlers";
import { CREATE_A_POLICY_CATEGORY } from "src/features/modal/titles";
import { useAppDispatch } from "src/store";
import useCreatePolicyMutation from "../../hooks/useCreatePolicyMutation";
import useGetPoliciesCategories from "../../hooks/useGetPoliciesCategories";
import useGetPolicyQuery from "../../hooks/useGetPolicyQuery";
import usePartialUpdatePolicyMutation from "../../hooks/usePartialUpdatePolicyMutation";
import usePolicyFormState from "../../hooks/usePolicyFormState";
import {
  PolicyFormSteps,
  setPolicyFormStep,
  setSelectedPolicyId,
} from "../../slices/policySlice";
import { Policy } from "../../types/policies.types";
import {
  PolicyDetailsFormSchemaType,
  policyDetailsFormSchema,
} from "../../zod/policyStepFormSchema";

export default function PolicyDetailsForm() {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { selectedPolicyId } = usePolicyFormState();
  const queryClient = useQueryClient();

  const { data: categories } = useGetPoliciesCategories();

  const combinedId = id || selectedPolicyId;

  const { data: policy, isInitialLoading: isPolicyLoading } = useGetPolicyQuery(
    {
      id: combinedId ? String(combinedId) : undefined,
    }
  );

  const handlePolicySuccess = (data: Policy) => {
    queryClient.invalidateQueries(["policies"]);
    alert.success("Policy created successfully!");
    dispatch(setSelectedPolicyId(data.id));
    dispatch(setPolicyFormStep(PolicyFormSteps.UploadPolicy));
  };

  const createPolicyMutation = useCreatePolicyMutation({
    onSuccess: (data) => handlePolicySuccess(data),
  });
  const partialUpdatePolicyMutation = usePartialUpdatePolicyMutation({
    onSuccess: (data) => handlePolicySuccess(data),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<PolicyDetailsFormSchemaType>({
    resolver: zodResolver(policyDetailsFormSchema),
    defaultValues: {
      id: policy?.id,
      title: policy?.title,
      category: policy?.category?.id,
    },
  });

  useEffect(() => {
    if (policy) {
      reset({
        id: policy.id,
        title: policy.title,
        category: policy.category?.id,
      });
    }
  }, [policy, id, selectedPolicyId, reset]);

  const handleSavePolicyDetails = (data: PolicyDetailsFormSchemaType) => {
    if (data?.id) {
      partialUpdatePolicyMutation.mutate(data);
    } else {
      createPolicyMutation.mutate(data);
    }
  };

  const handleOpenModal = (e: React.MouseEvent, title: string) => {
    e.preventDefault();
    dispatch(setModalStateAction(null)(title)(true));
  };

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">
          Please enter a title and specify a category for your policy.
        </span>
      </div>
      <EditingContainer>
        <form
          onSubmit={handleSubmit(handleSavePolicyDetails)}
          className="overflow-y-auto"
        >
          <FormRow>
            <InputContainer>
              <InputLabel htmlFor={"title"} labelIcon={<FlagIcon />}>
                Policy Title
              </InputLabel>
              {!isPolicyLoading ? (
                <DynamicFormInput
                  register={register}
                  type={"text"}
                  placeholder={"Add a title..."}
                  name={"title"}
                  defaultValue={""}
                  options={[]}
                  optionKey={""}
                  error={
                    errors["title" as keyof PolicyDetailsFormSchemaType]
                      ?.message
                  }
                  inputClassName={inputStyles.darkInput}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                />
              ) : null}
              {isPolicyLoading ? <Skeleton height={40} /> : null}
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer>
              <InputLabel htmlFor={"category"} labelIcon={<NoteIcon />}>
                <div style={{ flex: 1 }}>Category</div>
                <button
                  style={{
                    all: "unset",
                    height: "16px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleOpenModal(e, CREATE_A_POLICY_CATEGORY)}
                >
                  <AddRoundedSmallIcon />
                </button>
              </InputLabel>
              {!isPolicyLoading ? (
                <DynamicFormInput
                  register={register}
                  type={SELECT}
                  placeholder={"category"}
                  name={"category"}
                  options={categories || []}
                  optionKey={"title"}
                  error={
                    errors["category" as keyof PolicyDetailsFormSchemaType]
                      ?.message
                  }
                  inputClassName={inputStyles.darkInput}
                  containerClassName={inputStyles.inputContainer}
                  errorClassName={inputStyles.darkInputError}
                />
              ) : null}
              {isPolicyLoading ? <Skeleton height={40} /> : null}
            </InputContainer>
          </FormRow>
          <ButtonsContainer>
            <Button
              color={ButtonColors.Yellow}
              size={ButtonSizes.MD}
              type="submit"
              disabled={isPolicyLoading}
            >
              {!isPolicyLoading ? (
                "Save Policy Details"
              ) : (
                <RotatingLines
                  strokeColor="#000"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              )}
            </Button>
          </ButtonsContainer>
        </form>
      </EditingContainer>
    </StepContainer>
  );
}
