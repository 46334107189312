import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SubClassedQuestion } from "src/features/assessments/services/questionsService";
import { Answer } from "../../learningPaths/learningPaths.types";

export enum QuestionSteps {
  QuestionInput = "QuestionInput",
  GenerateQuestionFromText = "Generate Question From Text",
  GenerateQuestionFromModuleContent = "Generate Question From Module Content",
  GenerateQuestionsFromPolicy = "Generate Questions From Policy",
  GenerateQuestionFromVideo = "Generate Question From Video",
}

export enum QuestionTypes {
  MC = "Multiple Choice",
  Numeric = "Numeric",
}

export type GeneratedQuestion = {
  id?: string | number;
  question: string;
  answers: Answer[];
  explanation: string;
};

export type QuestionFormState = {
  step: QuestionSteps | null;
  type: QuestionTypes | null;
  selectedQuestion: SubClassedQuestion | null;
  selectedQuestionId: string | null;
};

const initialState: QuestionFormState = {
  step: null,
  type: null,
  selectedQuestion: null,
  selectedQuestionId: null,
};

const questionFormSlice = createSlice({
  name: "question",
  initialState: initialState,
  reducers: {
    setSelectedQuestionId(state, action: PayloadAction<string | null>) {
      state.selectedQuestionId = action.payload;
    },
    setSelectedQuestion(
      state,
      action: PayloadAction<SubClassedQuestion | null>
    ) {
      state.selectedQuestion = action.payload;
    },
    setQuestionStep(state, action: PayloadAction<QuestionSteps | null>) {
      state.step = action.payload;
    },
    setQuestionType(state, action: PayloadAction<QuestionTypes | null>) {
      state.type = action.payload;
    },
    resetInitialState() {
      return initialState;
    },
  },
});

export const {
  setQuestionStep,
  setQuestionType,
  setSelectedQuestion,
  setSelectedQuestionId,
  resetInitialState,
} = questionFormSlice.actions;

export default questionFormSlice.reducer;
