import { useQuery } from "@tanstack/react-query";

import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import moduleContentService from "../services/moduleContentService";

type ModuleContentQuery = {
  id?: string;
};

export default function useGetModuleContentQuery({ id }: ModuleContentQuery) {
  return useQuery({
    queryKey: [QueryKeys.ModuleContent, id],
    queryFn: id ? () => moduleContentService.getModuleContent(id) : undefined,
    enabled: !!id,
  });
}
