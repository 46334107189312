import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import memosService from "../services/memosService";

type UseGetMemoQueryProps = {
  id?: string;
};

export default function useGetMemoQuery({ id }: UseGetMemoQueryProps) {
  return useQuery({
    queryKey: [QueryKeys.Memo, String(id)],
    queryFn: id ? () => memosService.getMemo(id) : undefined,
    enabled: !!id,
  });
}
