import { useQuery } from "@tanstack/react-query";
import assessmentService from "../services/assessmentService";

export default function useGetMyRecentSittingsQuery() {
  return useQuery({
    queryKey: ["my-recent-sittings"],
    queryFn: assessmentService.getMyRecentSittingsList,
    refetchOnWindowFocus: true,
  });
}
