import { useAlert } from "@blaumaus/react-alert";
import { useEffect, useRef, useState } from "react";

import VideoPlayer from "src/features/videos/components/VideoPlayer";
import useVideoActions from "src/features/videos/hooks/useVideoActions";
import createTimeTriggerAction from "src/features/videos/utils/createTimeTriggerAction";

type LPVideoPlayerProps = {
  src: string;
  onComplete: (value: boolean) => void;
};

const LPVideoPlayer = ({ src, onComplete }: LPVideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoLengthTriggered, setVideoLengthTriggered] = useState(false);

  const alert = useAlert();

  const videoWatchedTrigger = () => {
    alert.success("Congratulations! You have completed this video.");
    onComplete(true);
  };

  const timeUpdateAction = createTimeTriggerAction(
    videoRef,
    videoWatchedTrigger,
    videoLengthTriggered,
    setVideoLengthTriggered
  );

  useVideoActions(videoRef, {
    timeupdate: timeUpdateAction,
  });

  useEffect(() => {
    onComplete(false);
    setVideoLengthTriggered(false);
  }, [onComplete]);

  return <VideoPlayer src={src} ref={videoRef} autoPlay={true} controls />;
};

export default LPVideoPlayer;
