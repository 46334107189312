import { useQuery } from "@tanstack/react-query";

import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import departmentsService from "../services/departmentsService";

type GetDepartmentQuery = {
  id?: string | number;
};

export default function useGetDepartmentQuery({ id }: GetDepartmentQuery) {
  return useQuery({
    queryKey: [QueryKeys.Department],
    queryFn: id ? () => departmentsService.getDepartment(id) : undefined,

    enabled: !!id,
  });
}
