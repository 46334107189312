import React, { useRef } from "react";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import VerticalDocumentViewer from "src/features/e-signatures/components/Viewers/VerticalDocumentViewer";
import ModuleContentTitleAndDescription from "../../components/LPTake/ModuleContentTitleAndDescription";
import { EmployeeModuleContentListItem } from "../../types/employeeModuleContentsV2.types";
import getModuleContentTypeString from "../../utils/getModuleContentTypeString";

type Props = {
  employeeModuleContent: EmployeeModuleContentListItem | null;
  onClickNext: () => void;
  onModuleContentCompleted: () => void;
};

function EmployeeModuleContentPdfViewer({
  employeeModuleContent,
  onClickNext,
  onModuleContentCompleted,
}: Props) {
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = React.useState(true);

  const title = `${getModuleContentTypeString(
    employeeModuleContent?.module_content?.content_type
  )}: ${employeeModuleContent?.module_content?.content_object?.title}`;

  const description =
    employeeModuleContent?.module_content?.content_object?.description ||
    "No description";

  const fileUrl =
    employeeModuleContent?.module_content?.content_object.file_url;

  const startPage =
    employeeModuleContent?.module_content?.pdf_page_range?.start_page;

  const endPage =
    employeeModuleContent?.module_content?.pdf_page_range?.end_page;

  const handleDocumentComplete = () => {
    onModuleContentCompleted();
    setIsNextBtnDisabled(false);
  };

  return (
    <div className="flex p-10 card">
      <ModuleContentTitleAndDescription
        title={title}
        description={description}
      />
      <div className="flex py-10 items-end">
        <Button
          className="border-radius-5"
          color={ButtonColors.Yellow}
          size={ButtonSizes.LG}
          title="Next"
          type="button"
          onClick={onClickNext}
          disabled={isNextBtnDisabled}
        />
      </div>
      <VerticalDocumentViewer
        isLoading={false}
        pageRefs={pageRefs}
        fileUrl={fileUrl}
        startPage={startPage}
        endPage={endPage}
        onDocumentComplete={handleDocumentComplete}
      />
      <div className="flex py-10 items-end">
        <Button
          className="border-radius-5"
          color={ButtonColors.Yellow}
          size={ButtonSizes.LG}
          title="Next"
          type="button"
          onClick={onClickNext}
          disabled={isNextBtnDisabled}
        />
      </div>
    </div>
  );
}

export default React.memo(EmployeeModuleContentPdfViewer);
