import { createSlice } from "@reduxjs/toolkit";
import type { ModuleContentType } from "../learningPaths.types";

export enum BuilderStep {
  AddDetails = "Add Details",
  UploadImage = "Upload Image",
  AddModules = "Add Modules",
  AssignToEmployees = "Assign To Employees",
  PublishLearningPath = "Publish Learning Path",
}

export enum SectionBtns {
  AddNewSectionOrSaveModule = "addNewSectionOrSaveModule",
  AddModuleOrNextStepBtns = "addModuleOrNextStepBtns",
}

export enum ModuleContentForms {
  UploadExternalFile = "uploadExternalFile",
  CreateNewAssessment = "createNewAssessment",
  AddSavedVideo = "addSavedVideo",
  AddSavedPolicy = "addSavedPolicy",
  AddSavedAssessment = "addSavedAssessment",
}

export type LearningPathsBuilderState = {
  pathId: string | null;
  moduleId: string | null;
  step: BuilderStep | null;
  moduleContent: ModuleContentType | null;
  mcForm: ModuleContentForms | null;
  buttons: SectionBtns | null;
};

const initialState: LearningPathsBuilderState = {
  pathId: null,
  moduleId: null,
  step: BuilderStep.AddDetails,
  moduleContent: null,
  mcForm: null,
  buttons: null,
};

const learningPathsBuilderSlice = createSlice({
  name: "learningPathsBuilder",
  initialState,
  reducers: {
    addOrUpdateQuestionInBuilderModuleContent(state, action) {
      const { id } = action.payload;
      if (state.moduleContent) {
        const assessment = state.moduleContent?.content_object;
        if (assessment) {
          let questionFound = false;
          const questions = assessment.questions.map((question: any) => {
            if (Number(question.id) === Number(id)) {
              questionFound = true;
              return action.payload;
            }
            return question;
          });

          if (!questionFound) {
            questions.push(action.payload);
          }

          state.moduleContent.content_object.questions = questions;
        }
      }
    },
    removeQuestionFromBuilderModuleContent(state, action) {
      const { questionId } = action.payload;
      if (state.moduleContent) {
        const assessment = state.moduleContent?.content_object;
        if (assessment) {
          const questions = assessment.questions.filter(
            (question: any) => Number(question.id) !== Number(questionId)
          );
          state.moduleContent.content_object.questions = questions;
        }
      }
    },
    updateQuestionPositionInBuilderModuleContent(state, action) {
      const { questionId, newPosition } = action.payload;
      if (state.moduleContent) {
        const assessment = state.moduleContent?.content_object;
        if (assessment) {
          const questions = assessment.questions.map((question: any) => {
            if (Number(question.id) === Number(questionId)) {
              question.position = newPosition;
            }
            return question;
          });
          state.moduleContent.content_object.questions = questions;
        }
      }
    },
    updateQuestionsInBuilderModuleContent(state, action) {
      const { questions } = action.payload;
      if (state.moduleContent) {
        const assessment = state.moduleContent?.content_object;
        if (assessment) {
          state.moduleContent.content_object.questions = questions;
        }
      }
    },
    resetInitialState() {
      return initialState;
    },
    setBuilderModule(state, action) {
      state.moduleId = action.payload;
    },
    setBuilderStep(state, action) {
      state.step = action.payload;
    },
    setBuilderModuleContent(state, action) {
      state.moduleContent = action.payload;
      if (action.payload) {
        state.buttons = null;
      }
    },
    setPathId(state, action) {
      state.pathId = action.payload;
    },
    setStepForm(state, action) {
      state.mcForm = action.payload;
    },
    setSectionBtns(state, action) {
      state.buttons = action.payload;
    },
  },
});

export default learningPathsBuilderSlice.reducer;

export const {
  addOrUpdateQuestionInBuilderModuleContent,
  removeQuestionFromBuilderModuleContent,
  resetInitialState,
  setBuilderStep,
  setBuilderModule,
  setBuilderModuleContent,
  setPathId,
  setSectionBtns,
  setStepForm,
  updateQuestionPositionInBuilderModuleContent,
  updateQuestionsInBuilderModuleContent,
} = learningPathsBuilderSlice.actions;
