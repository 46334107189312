import DynamicFormInput from "src/components/DynamicForm/DynamicFormInput";
import InputLabel from "src/components/Inputs/InputLabel";
import inputStyles from "src/components/Inputs/Inputs.module.css";
import styles from "./LearningPathTake.module.css";

type NumericQuestionProps = {
  question: any;
  register: any;
};

export default function NumericQuestion({
  question,
  register,
}: NumericQuestionProps) {
  return (
    <div className={styles.questionContainer}>
      <h1 className={styles.questionContent}>{question.content}</h1>
      <form className={styles.answerContainer}>
        <InputLabel labelIcon={null}>{"Numeric answer:"}</InputLabel>
        <DynamicFormInput
          register={register}
          name={"answer"}
          type={"number"}
          placeholder="Enter numeric answer..."
          inputClassName={inputStyles.darkInput}
          containerClassName={inputStyles.inputContainer}
          errorClassName={inputStyles.inputError}
        />
      </form>
    </div>
  );
}
