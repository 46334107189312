import { useAlert } from "@blaumaus/react-alert";
import { useCallback, useEffect, useMemo, useState } from "react";

import formatTimeToHrMinSec from "src/features/datetime/utils/formatTimeToHrMinSec";
import calculateProgressBarPercentageFromTime from "src/features/progress/utils/calculateProgressBarPercentageFromTime";
import styles from "./ProgressBarTimer.module.css";

type ProgressBarTimerProps = {
  timeLimit?: number | null;
  startTime?: string | null;
  currentTime: string;
  currentQuestionNum?: number | null;
  totalQuestions?: number | null;
  onTimeOutCallback: () => void;
};

export default function ProgressBarTimer({
  timeLimit,
  startTime,
  currentTime,
  currentQuestionNum,
  totalQuestions,
  onTimeOutCallback,
}: ProgressBarTimerProps) {
  const alert = useAlert();
  const [hasAlerted, setHasAlerted] = useState(false);

  const timeLimitInMin = useMemo(() => {
    if (timeLimit == null) return null;
    return timeLimit * 60 * 1000;
  }, [timeLimit]);

  const calculateTimeRemaining = useCallback(() => {
    if (!startTime || !timeLimitInMin) return null;

    const start = new Date(startTime).getTime();
    const current = new Date(currentTime).getTime();

    if (isNaN(start) || isNaN(current)) {
      return null;
    }

    const timeLeft = timeLimitInMin - (current - start);

    return timeLeft > 0 ? timeLeft : 0;
  }, [startTime, currentTime, timeLimitInMin]);

  const [timeRemaining, setTimeRemaining] = useState<number | null>(
    calculateTimeRemaining()
  );

  useEffect(() => {
    const initialTimeRemaining = calculateTimeRemaining();
    setTimeRemaining(initialTimeRemaining);

    if (initialTimeRemaining === null) return;

    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === null) return null;

        const newTime = prevTime - 1000;
        if (newTime <= 0) {
          clearInterval(interval);
          if (!hasAlerted) {
            alert.error(
              "Time's up! The rest of the questions will be auto-submitted as incorrect."
            );
            onTimeOutCallback?.();
            setHasAlerted(true);
          }
          return 0;
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [
    startTime,
    currentTime,
    timeLimitInMin,
    hasAlerted,
    onTimeOutCallback,
    alert,
    calculateTimeRemaining,
  ]);

  const formattedTime =
    timeRemaining !== null ? formatTimeToHrMinSec(timeRemaining) : null;

  const progressBarPercentage =
    timeRemaining !== null && timeLimitInMin !== null
      ? calculateProgressBarPercentageFromTime(timeRemaining, timeLimitInMin)
      : null;

  return (
    <div className={styles.timerContainer}>
      <div className={styles.timerProgressBarContainer}>
        {progressBarPercentage !== null && (
          <div
            className={styles.timerProgressBar}
            style={{ width: `${progressBarPercentage}%` }}
          ></div>
        )}
      </div>
      <span className={styles.timerText}>
        Timer: {formattedTime !== null ? formattedTime : "N/A"}
      </span>
      <span className={styles.timerText}>
        Question: {currentQuestionNum ?? "N/A"}/{totalQuestions ?? "N/A"}
      </span>
    </div>
  );
}
