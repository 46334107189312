import { useQuery } from "@tanstack/react-query";
import { memo } from "react";
import {
  MdAssignment,
  MdBusiness,
  MdDashboard,
  MdDescription,
  MdEdit,
  MdForum,
  MdGavel,
  MdSchedule,
  MdSchool,
  MdSettings,
  MdVideoLibrary,
} from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import useAuth from "src/features/auth/hooks/useAuth";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import {
  ORGANIZATION_CRUD_GROUP,
  ORGANIZATION_VIEW_GROUP,
} from "src/features/groups/groups";
import { getOrganizationFeatures } from "src/features/organization/organizationApis";
import styles from "./NavMenu.module.css";
import NavMenuLink from "./NavMenuLink";

function NavMenuLinkList() {
  const { employee } = useAuth();

  const { data: features, isLoading } = useQuery({
    queryKey: ["companyFeatures"],
    queryFn: getOrganizationFeatures,
  });

  return (
    <div className={styles.navLinksContainer} id="navLinks">
      <ul className={styles.navLinksList}>
        {isLoading &&
          Array.from({ length: 7 }, (_, i) => (
            <div key={i} className={styles.navLinkListItem}>
              <Skeleton key={i} height={40} />
            </div>
          ))}
        {!isLoading && (
          <NavMenuLink
            icon={<MdDashboard size={24} />}
            text={"Dashboard"}
            to="/dashboard"
          />
        )}
        {!isLoading && features?.schedules && (
          <NavMenuLink
            icon={<MdSchedule size={24} />}
            text={"Schedules"}
            to="/schedules"
          />
        )}
        {!isLoading && features?.discussions && (
          <NavMenuLink
            icon={<MdForum size={24} />}
            text={"Discussions"}
            to="/discussions"
          />
        )}
        {!isLoading && features?.memos && (
          <NavMenuLink
            icon={<MdDescription size={24} />}
            text={"Memos"}
            to="/memos"
          />
        )}
        {!isLoading && features?.policies && (
          <NavMenuLink
            icon={<MdGavel size={24} />}
            text={"Policies"}
            to="/policies"
          />
        )}
        {!isLoading && features?.videos && (
          <NavMenuLink
            icon={<MdVideoLibrary size={24} />}
            text={"Videos"}
            to="/videos"
          />
        )}
        {!isLoading && features?.assessments && (
          <NavMenuLink
            icon={<MdAssignment size={24} />}
            text={"Assessments"}
            to="/assessments"
          />
        )}
        {!isLoading && features?.learning_paths && (
          <NavMenuLink
            icon={<MdSchool size={24} />}
            text={"Learning Paths"}
            to="/learning-paths"
            beta={false}
            id="learningPathsLink"
          />
        )}
        {!isLoading && features?.e_signatures && (
          <NavMenuLink
            icon={<MdEdit size={24} />}
            text={"E-Signatures"}
            beta={false}
            to="/e-signatures"
          />
        )}
        {!isLoading &&
          hasGroupPermissions(employee, ORGANIZATION_VIEW_GROUP) && (
            <NavMenuLink
              icon={<MdBusiness size={24} />}
              text={"Organization"}
              to="/organization"
            />
          )}
        {!isLoading &&
          hasGroupPermissions(employee, ORGANIZATION_CRUD_GROUP) && (
            <NavMenuLink
              icon={<MdSettings size={24} />}
              text={"Settings"}
              to="/settings"
            />
          )}
      </ul>
    </div>
  );
}

export default memo(NavMenuLinkList);
