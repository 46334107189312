import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum VideoFormSteps {
  AddDetails = "Add Details",
  UploadVideo = "Upload Video",
  UploadThumbnail = "Upload Thumbnail",
  AssignEmployees = "Assign Employees",
  Publish = "Publish",
}

export type VideoState = {
  step: VideoFormSteps | null;
  videoId: string | null;
};

const initialState: VideoState = {
  step: null,
  videoId: null,
};

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    resetVideoFormState(state) {
      state.step = VideoFormSteps.AddDetails;
    },
    setVideoFormStep(state, action: PayloadAction<VideoFormSteps>) {
      state.step = action.payload;
    },
    setVideoId(state, action: PayloadAction<string | null>) {
      state.videoId = action.payload;
    },
  },
});

export const { resetVideoFormState, setVideoFormStep, setVideoId } =
  videoSlice.actions;

export default videoSlice.reducer;
