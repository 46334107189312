import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import StepTwoGrayIcon from "src/components/Icons/StepTwoGrayIcon";
import StepTwoYellowIcon from "src/components/Icons/StepTwoYellowIcon";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepContainer from "src/components/Steps/StepContainer";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import { useAppDispatch } from "src/store";
import useVideoFormReducer from "../hooks/useVideoFormReducer";
import { setVideoFormStep, VideoFormSteps } from "../redux/videoSlice";
import VideoUploadForm from "./VideoUploadForm";

export default function VideoFormStepTwo() {
  const dispatch = useAppDispatch();
  const { step } = useVideoFormReducer();

  const handleStepButtonClick = () =>
    dispatch(setVideoFormStep(VideoFormSteps.UploadVideo));

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: VideoFormSteps.UploadVideo,
    titleAction:
      step !== VideoFormSteps.UploadVideo &&
      step !== VideoFormSteps.AddDetails ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepTwoYellowIcon />,
    inactiveIcon:
      step === VideoFormSteps.AddDetails ? (
        <StepTwoGrayIcon />
      ) : (
        <CheckCircleYellowIcon />
      ),
    defaultContent: (
      <StepContainer>
        {step !== VideoFormSteps.UploadVideo &&
        step !== VideoFormSteps.AddDetails ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">Upload your video file.</span>
        )}
      </StepContainer>
    ),
    activeContent: <VideoUploadForm />,
  });
}
