import { createSlice } from "@reduxjs/toolkit";

export enum PolicyFormSteps {
  AddDetails = "Add Details",
  UploadPolicy = "Upload Policy",
  AssignToEmployees = "Assign to Employees",
  PublishPolicy = "Publish Policy",
}

export type PolicyFormState = {
  step: PolicyFormSteps | null;
  selectedPolicyId: string | null;
};

const initialState: PolicyFormState = {
  step: null,
  selectedPolicyId: null,
};

const policySlice = createSlice({
  name: "policy",
  initialState,
  reducers: {
    resetPolicyFormState(state) {
      state.step = PolicyFormSteps.AddDetails;
      state.selectedPolicyId = null;
    },
    setPolicyFormStep(state, action) {
      state.step = action.payload;
    },
    setSelectedPolicyId(state, action) {
      state.selectedPolicyId = action.payload;
    },
  },
});

export const { resetPolicyFormState, setPolicyFormStep, setSelectedPolicyId } =
  policySlice.actions;

export default policySlice.reducer;
