import React from "react";
import {
  MdAssignment,
  MdCancel,
  MdCheckCircle,
  MdWorkOutline,
} from "react-icons/md";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Card from "src/components/Cards/Card";
import ObjectDetailPreview from "src/components/Detail/ObjectDetailPreview";
import ProgressBar from "src/components/ProgressBar/ProgressBar";
import {
  EmployeeModuleContentListItem,
  EmployeeModuleContentStatus,
} from "../types/employeeModuleContentsV2.types";
import { EmployeeModuleListItem } from "../types/employeeModulesV2.types";
import getModuleContentTypeString from "../utils/getModuleContentTypeString";

type Props = {
  onClickContinue: () => void;
  employeeModule: EmployeeModuleListItem | null;
  employeeModuleContents?: EmployeeModuleContentListItem[];
};

function EmployeeModuleDetailsCard({
  onClickContinue,
  employeeModule,
  employeeModuleContents,
}: Props) {
  const getStatusColor = (status: EmployeeModuleContentStatus) => {
    switch (status) {
      case EmployeeModuleContentStatus.Assigned:
        return "var(--clearpath-yellow)";
      case EmployeeModuleContentStatus.InProgress:
        return "var(--clearpath-blue)";
      case EmployeeModuleContentStatus.Completed:
        return "var(--clearpath-green)";
      case EmployeeModuleContentStatus.DidNotComplete:
        return "var(--clearpath-danger)";
      default:
        return "var(--clearpath-yellow)";
    }
  };

  const getStatusIcon = (status: EmployeeModuleContentStatus) => {
    switch (status) {
      case EmployeeModuleContentStatus.Assigned:
        return <MdAssignment size={18} color={getStatusColor(status)} />;
      case EmployeeModuleContentStatus.InProgress:
        return <MdWorkOutline size={18} color={getStatusColor(status)} />;
      case EmployeeModuleContentStatus.Completed:
        return <MdCheckCircle size={18} color={getStatusColor(status)} />;
      case EmployeeModuleContentStatus.DidNotComplete:
        return <MdCancel size={18} color={getStatusColor(status)} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="flex card">
        <div className="flex flex-col assessmentFeatureItemContainer p-10">
          <ObjectDetailPreview
            title={`Module: ${employeeModule?.module?.title}`}
            subTitle={employeeModule?.module?.description}
            actions={
              employeeModule ? (
                <div>
                  <Button
                    color={ButtonColors.Yellow}
                    onClick={onClickContinue}
                    dataTestid="navigate-button"
                  >
                    <MdAssignment size={20} />
                    {employeeModule &&
                    employeeModule?.completion_percentage === 0
                      ? "Start Module"
                      : employeeModule &&
                        employeeModule?.completion_percentage < 100
                      ? "Resume Module"
                      : "Go to Next Module"}
                  </Button>
                </div>
              ) : null
            }
            rightInfo={
              <Card className="flex flex-col gap-20 p-20">
                {employeeModule?.completion_percentage !== undefined ? (
                  <div className="flex flex-col gap-10">
                    <div className="flex flex-row justify-between gap-10">
                      <span className="text-sm font-bold">Module Progress</span>
                      <span className="text-sm font-bold">
                        {employeeModule?.completion_percentage}%
                      </span>
                    </div>
                    <ProgressBar
                      progress={employeeModule?.completion_percentage}
                      progressBarStyle={{ height: 10, borderRadius: 5 }}
                    />
                  </div>
                ) : null}
                {employeeModuleContents?.map((empModuleContent) => (
                  <div
                    key={empModuleContent.id}
                    className="text-sm flex justify-center gap-10"
                  >
                    <div className="flex flex-row gap-5">
                      {getStatusIcon(empModuleContent.status)}
                      <b>
                        {getModuleContentTypeString(
                          empModuleContent?.module_content?.content_type
                        )}
                        :
                      </b>{" "}
                      {empModuleContent?.module_content?.content_object
                        ?.title || "No Title Found"}
                    </div>
                  </div>
                ))}
              </Card>
            }
          />
          {employeeModule ? (
            <>
              <div className="featureItemImageContainer">
                {employeeModule?.module?.file_url ? (
                  <img
                    src={employeeModule?.module?.file_url}
                    alt={employeeModule?.module?.title}
                    className="w-full object-cover border-radius-10"
                  />
                ) : null}
              </div>
              <div className="featureItemOverlay" />
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default React.memo(EmployeeModuleDetailsCard);
