import {
  MCQuestionType,
  NumericalQuestionType,
} from "../types/assessments.types";

function isNumericalQuestion(
  question?: NumericalQuestionType | MCQuestionType | null
): question is NumericalQuestionType {
  if (!question) {
    return false;
  }
  return (question as NumericalQuestionType)?.answer !== undefined;
}

export default isNumericalQuestion;
