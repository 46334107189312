import axiosClient from "src/features/axios/client";
import { MULTIPART_FORM_HEADERS } from "../../axios/headers";
import { UploadProgressCallback } from "../../files/hooks/useFileUpload";
import { handleUploadProgress } from "../../files/utils/handleUploadProgress";
import { CategoriesWithVideos, VideoListItem } from "../types/videos.types";

class VideoService {
  async createVideo(
    formData: FormData,
    uploadProgressCallback: UploadProgressCallback
  ) {
    return await axiosClient
      .post("/api/videos/", formData, {
        headers: MULTIPART_FORM_HEADERS,
        onUploadProgress: (progressEvent) =>
          handleUploadProgress(progressEvent, uploadProgressCallback),
      })
      .then((res) => res.data);
  }

  async deleteVideo(id: number) {
    return await axiosClient
      .delete(`/api/videos/${id}/`)
      .then((res) => res.data);
  }

  async getCategoryVideos(): Promise<CategoriesWithVideos[]> {
    return await axiosClient
      .get("/api/category-videos/")
      .then((res) => res.data);
  }

  async getVideo(id: string): Promise<VideoListItem> {
    return await axiosClient.get(`/api/videos/${id}/`).then((res) => res.data);
  }

  async getVideos(): Promise<VideoListItem[]> {
    return await axiosClient.get("/api/videos/").then((res) => res.data);
  }

  async getVideoLikeStatus(videoId: number) {
    return await axiosClient
      .get(`/api/category-videos/${videoId}/like-status/`)
      .then((res) => res.data);
  }

  async getVideoSavedStatus(videoId: number) {
    return await axiosClient
      .get(`/api/category-videos/${videoId}/saved-status/`)
      .then((res) => res.data);
  }

  async updateVideo(
    formData: FormData,
    id: string,
    uploadProgressCallback: UploadProgressCallback
  ) {
    return await axiosClient
      .patch(`/api/videos/${id}/`, formData, {
        headers: MULTIPART_FORM_HEADERS,
        onUploadProgress: (progressEvent) =>
          handleUploadProgress(progressEvent, uploadProgressCallback),
      })
      .then((res) => res.data);
  }

  async updateVideoLikeStatus({
    videoId,
    action,
  }: {
    videoId: number;
    action: "like" | "dislike";
  }) {
    return await axiosClient
      .patch(`/api/category-videos/${videoId}/like-status/`, { action })
      .then((res) => res.data);
  }

  async updateVideoSavedStatus({ videoId }: { videoId: number }) {
    return await axiosClient
      .patch(`/api/category-videos/${videoId}/saved-status/update/`)
      .then((res) => res.data);
  }
}

const videoService = new VideoService();

export default videoService;
