import { useEffect } from "react";

import Card from "src/components/Cards/Card";
import VerticalStepper from "src/components/Steps/VerticalStepper";
import useSetHeaderTitle from "src/features/header/hooks/useSetHeaderTitle";
import { useAppDispatch } from "src/store";
import { setVideoFormStep, VideoFormSteps } from "../redux/videoSlice";
import VideoFormStepFive from "./VideoFormStepFive";
import VideoFormStepFour from "./VideoFormStepFour";
import VideoFormStepOne from "./VideoFormStepOne";
import VideoFormStepThree from "./VideoFormStepThree";
import VideoFormStepTwo from "./VideoFormStepTwo";

export default function VideoCreateEditForm() {
  const dispatch = useAppDispatch();
  useSetHeaderTitle("Video Form");

  useEffect(() => {
    dispatch(setVideoFormStep(VideoFormSteps.AddDetails));
  }, [dispatch]);

  const steps = [
    VideoFormStepOne(), // details
    VideoFormStepTwo(), // upload
    VideoFormStepThree(), // thumbnail
    VideoFormStepFour(), // schedule
    VideoFormStepFive(), // publish
  ];

  return (
    <Card>
      <div className="py-20 px-10">
        <VerticalStepper steps={steps} className={"step"} />
      </div>
    </Card>
  );
}
