import { useQuery } from "@tanstack/react-query";

import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import videoService from "../../services/videoService";

type UseGetVideoQueryProps = {
  id?: string | null;
};

export default function useGetVideoQuery({ id }: UseGetVideoQueryProps) {
  return useQuery({
    queryKey: [QueryKeys.Video, String(id)],
    queryFn: id ? () => videoService.getVideo(id) : undefined,
    enabled: !!id,
  });
}
