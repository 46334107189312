import styles from "./Badge.module.css";

type BadgeProps = {
  text: string;
  color: string;
  style?: React.CSSProperties;
};

export default function Badge({ text, color, style }: BadgeProps) {
  return (
    <span
      className={styles.badge}
      style={{ backgroundColor: color, ...style }}
      title={text}
    >
      {text}
    </span>
  );
}
