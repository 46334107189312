import { useCallback, useRef, useState } from "react";
import useAuth from "src/features/auth/hooks/useAuth";
import { HexCategoryColors } from "src/features/utils/getImageColorFromCategory";
import useWebSocket from "src/features/websockets/hooks/useWebsocket";
import { v4 as uuidv4 } from "uuid";
import { ImageGeneratorStatus, ImageGeneratorSteps } from "../images.types";

interface WebSocketMessage {
  status: ImageGeneratorStatus;
  step: ImageGeneratorSteps;
  response?: {
    images: string[];
    name: string;
    ext: string;
  };
  message?: string;
}

type Props = {
  onSetValues: ({
    image,
    name,
    ext,
  }: {
    image: string;
    name: string;
    ext: string;
  }) => void;
};

export function useImageGeneratorWebSocket({ onSetValues }: Props) {
  const { access } = useAuth();
  const uuidRef = useRef(uuidv4());
  const [connectionStatus, setConnectionStatus] =
    useState<string>("disconnected");
  const [status, setStatus] = useState<ImageGeneratorStatus | null>(null);
  const [step, setStep] = useState<ImageGeneratorSteps | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleWebSocketConnecting = () => setConnectionStatus("connecting");

  const handleWebSocketOpen = () => setConnectionStatus("connected");

  const handleWebSocketClose = () => setConnectionStatus("disconnected");

  const handleWebSocketError = () => {
    setError("An unknown connection error occurred. Please try again.");
  };

  const handleReceivedWebSocketMessage = useCallback(
    (event: MessageEvent) => {
      const data: WebSocketMessage = JSON.parse(event.data);

      setStatus(data.status);
      setStep(data.step);

      if (data.status === ImageGeneratorStatus.Complete && data.response) {
        onSetValues({
          image: data.response.images[0],
          name: data.response.name,
          ext: data.response.ext,
        });
      } else if (data.status === ImageGeneratorStatus.Failed && data.message) {
        setError(data.message);
      }
    },
    [onSetValues]
  );

  const host = window.location.host;
  const protocol = window.location.protocol === "https:" ? "wss" : "ws";
  const url = `${protocol}://${host}/ws/image-generator/${uuidRef.current}/?token=${access}`;

  const { sendWebSocketMessage } = useWebSocket(url, {
    onConnecting: handleWebSocketConnecting,
    onOpen: handleWebSocketOpen,
    onMessage: handleReceivedWebSocketMessage,
    onError: handleWebSocketError,
    onClose: handleWebSocketClose,
  });

  const sendMessage = useCallback(
    ({
      uuid,
      prompt,
      s3_key,
      color,
    }: {
      uuid: string;
      prompt: string;
      s3_key: string;
      color: HexCategoryColors;
    }) => {
      sendWebSocketMessage({ uuid, prompt, s3_key, color });
      setStatus(ImageGeneratorStatus.Processing);
      setStep(ImageGeneratorSteps.ReceivedPrompt);
    },
    [sendWebSocketMessage]
  );

  return {
    sendMessage,
    connectionStatus,
    status,
    step,
    error,
    socketUuid: uuidRef.current,
  };
}
