import { useQuery } from "@tanstack/react-query";
import categoriesService from "../services/categoriesService";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";

type UseGetCategories = {
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
};

export default function useGetCategoriesListQuery({
  refetchOnWindowFocus = false,
  enabled = true,
}: UseGetCategories = {}) {
  return useQuery({
    queryKey: [QueryKeys.CategoriesList],
    queryFn: categoriesService.getCategories,
    refetchOnWindowFocus,
    enabled,
  });
}
