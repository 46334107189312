import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import employeeShiftsService, {
  EmployeeShiftStatus,
} from "../services/employeeShiftsService";

type UseGetEmployeeShiftsByDepartmentIdQuery = {
  departmentId?: number;
  weeklyScheduleId?: number;
  status?: EmployeeShiftStatus;
};

export default function useGetEmployeeShiftsByDepartmentIdQuery({
  departmentId,
  weeklyScheduleId,
  status,
}: UseGetEmployeeShiftsByDepartmentIdQuery) {
  return useQuery({
    queryFn:
      departmentId && weeklyScheduleId
        ? () =>
            employeeShiftsService.getEmployeeShiftsByDepartmentId(
              departmentId,
              weeklyScheduleId,
              status
            )
        : undefined,
    queryKey: [
      QueryKeys.EmployeeShiftsList,
      { department: departmentId, weeklySchedule: weeklyScheduleId, status },
    ],

    enabled: !!departmentId && !!weeklyScheduleId,
  });
}
