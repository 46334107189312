import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import shiftTypeService, {
  GetShiftTypeFilters,
} from "../services/shiftTypeService";

export default function useGetShiftTypesQuery({
  ...filters
}: GetShiftTypeFilters) {
  return useQuery({
    queryFn: () => shiftTypeService.getShiftTypes(filters || {}),
    queryKey: [QueryKeys.ShiftTypesList, filters],
  });
}
