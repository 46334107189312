import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import shiftTransferService from "../services/shiftTransferService";

type UseGetShiftTransferByIdQuery = {
  id?: string;
};

export default function useGetShiftTransferById({
  id,
}: UseGetShiftTransferByIdQuery) {
  return useQuery({
    queryFn: id
      ? () => shiftTransferService.getShiftTransfersById(id)
      : undefined,
    queryKey: [QueryKeys.ShiftTransfer, id],
    enabled: !!id,
  });
}
