import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import employeeLearningPathsService from "../services/employeeLearningPathsService";

type Props = {
  id?: number | string;
};

export default function useGetEmployeeLearningPathAndModulesQuery({
  id,
}: Props) {
  return useQuery({
    queryKey: [QueryKeys.EmployeeLearningPath, String(id)],
    queryFn: id
      ? () => employeeLearningPathsService.getEmployeeLearningPathAndModules(id)
      : undefined,

    enabled: !!id,
  });
}
