import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import Card from "src/components/Cards/Card";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import SittingQuestionTakeForm from "src/features/assessments/components/SittingQuestionTakeForm";
import SittingResult from "src/features/assessments/components/SittingResult";
import sittingTakeService from "src/features/assessments/services/sittingTakeService";
import { SittingStatus } from "src/features/assessments/types/sittings.types";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import ProgressBarTimer from "src/features/progress/components/ProgressBarTimer";
import ModuleContentTitleAndDescription from "../../components/LPTake/ModuleContentTitleAndDescription";
import employeeModuleContentsService from "../../servicesV2/employeeModuleContentsService";
import { EmployeeModuleContentListItem } from "../../types/employeeModuleContentsV2.types";
import getModuleContentTypeString from "../../utils/getModuleContentTypeString";

type Props = {
  employeeModuleContent: EmployeeModuleContentListItem | null;
  onClickNext: () => void;
  onModuleContentCompleted: () => void;
};

function EmployeeModuleContentAssessment({
  employeeModuleContent,
  onClickNext,
  onModuleContentCompleted,
}: Props) {
  const [questionNumber, setQuestionNumber] = React.useState(0);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = React.useState(true);
  const [sittingId, setSittingId] = React.useState<number | null>(null);
  const [showResults, setShowResults] = React.useState(false);
  const handleErrors = useErrorHandling();

  const title = `${getModuleContentTypeString(
    employeeModuleContent?.module_content?.content_type
  )}: ${employeeModuleContent?.module_content?.content_object?.title}`;

  const description =
    employeeModuleContent?.module_content?.content_object?.description ||
    "No description";

  const startEmployeeMcAssessmentMutation = useMutation({
    mutationFn: employeeModuleContentsService.startAssessment,
    onSuccess: (data) => setSittingId(data.id),
    onError: handleErrors,
  });

  const {
    data: sitting,
    isLoading: isSittingLoading,
    isError: isSittingError,
    refetch: refetchSitting,
    isRefetching: isSittingRefetching,
  } = useQuery({
    queryKey: ["mySittingTake", sittingId],
    queryFn: sittingId
      ? () => sittingTakeService.getMySittingTake(Number(sittingId))
      : undefined,
    enabled: !!sittingId,
    onSuccess: (data) => {
      if (data?.status !== SittingStatus.InProgress) {
        setShowResults(true);
        if (data?.assessment?.single_attempt) {
          onModuleContentCompleted();
        } else if (data?.status === SittingStatus.Passed) {
          onModuleContentCompleted();
        }
      }
    },
  });

  const handleRetakeAssessment = () => {
    if (employeeModuleContent) {
      setShowResults(false);
      startEmployeeMcAssessmentMutation.mutate(employeeModuleContent.id);
    }
  };

  useEffect(() => {
    if (employeeModuleContent) {
      startEmployeeMcAssessmentMutation.mutate(employeeModuleContent.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAnyLoading =
    isSittingLoading ||
    isSittingRefetching ||
    startEmployeeMcAssessmentMutation.isLoading;

  if (
    isSittingLoading ||
    isSittingRefetching ||
    startEmployeeMcAssessmentMutation.isLoading
  ) {
    return (
      <Card className="flex flex-col p-10 gap-10">
        <Skeleton height={500} />
      </Card>
    );
  }

  if (isSittingError) {
    return (
      <Card className="flex flex-col flex-1 justify-center items-center">
        <ErrorFallback onReload={refetchSitting} />
      </Card>
    );
  }

  if (showResults) {
    return (
      <div className="flex p-10 card">
        <ModuleContentTitleAndDescription
          title={title}
          description={description}
        />
        <div className="flex flex-row py-10 gap-10 justify-end">
          {!sitting?.assessment?.single_attempt ? (
            <Button
              className="border-radius-5"
              color={ButtonColors.GrayAndYellow}
              size={ButtonSizes.LG}
              title="Retake Assessment"
              onClick={handleRetakeAssessment}
            />
          ) : null}
          {(!sitting?.assessment?.single_attempt &&
            sitting?.status === SittingStatus.Passed) ||
          sitting?.assessment?.single_attempt ? (
            <Button
              className="border-radius-5"
              color={ButtonColors.Yellow}
              size={ButtonSizes.LG}
              title="Save Results"
              type="button"
              onClick={onClickNext}
              disabled={isAnyLoading}
            />
          ) : null}
        </div>
        <SittingResult sitting={sitting} />
        <div className="flex flex-row py-10 gap-10 justify-end">
          {!sitting?.assessment?.single_attempt ? (
            <Button
              className="border-radius-5"
              color={ButtonColors.GrayAndYellow}
              size={ButtonSizes.LG}
              title="Retake Assessment"
              onClick={handleRetakeAssessment}
            />
          ) : null}
          {(!sitting?.assessment?.single_attempt &&
            sitting?.status === SittingStatus.Passed) ||
          sitting?.assessment?.single_attempt ? (
            <Button
              className="border-radius-5"
              color={ButtonColors.Yellow}
              size={ButtonSizes.LG}
              title="Save Results"
              type="button"
              onClick={onClickNext}
              disabled={isAnyLoading}
            />
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <Card className="flex flex-col p-10 gap-10">
      <ProgressBarTimer
        timeLimit={sitting.assessment?.time_limit}
        startTime={sitting.start}
        currentTime={sitting.current_time}
        currentQuestionNum={questionNumber}
        totalQuestions={sitting.max_score}
        onTimeOutCallback={refetchSitting}
      />
      <ModuleContentTitleAndDescription
        title={title}
        description={description}
      />
      <SittingQuestionTakeForm
        sitting={sitting}
        isNextBtnDisabled={isNextBtnDisabled}
        onRefetchSitting={refetchSitting}
        onSetQuestionNumber={setQuestionNumber}
        setIsNextBtnDisabled={setIsNextBtnDisabled}
      />
    </Card>
  );
}

export default React.memo(EmployeeModuleContentAssessment);
