export type HexCategoryColors =
  | "#0096ff"
  | "#f1b70c"
  | "#28a745"
  | "#cd3c44"
  | "#9D00FF"
  | "#FFA500";

const getImageColorFromCategory = (input?: string): HexCategoryColors => {
  if (!input || typeof input !== "string") {
    return "#f1b70c";
  }

  const colors: HexCategoryColors[] = [
    "#0096ff",
    "#f1b70c",
    "#28a745",
    "#cd3c44",
    "#9D00FF",
    "#FFA500",
  ];

  const firstChar = input.trim().charAt(0).toLowerCase();

  // Handle non-alphabetic characters
  if (firstChar < "a" || firstChar > "z") {
    return "#f1b70c"; // Default color for non-alphabetic input
  }

  const charCode = firstChar.charCodeAt(0);

  // Map letters a-z (26 letters) to colors array indices
  const index = (charCode - 97) % colors.length;

  return colors[index];
};

export default getImageColorFromCategory;
