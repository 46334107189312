import { Controller } from "react-hook-form";
import ToggleButton from "src/components/Buttons/ToggleButton";
import { MCQuestionType } from "../types/assessments.types";
import styles from "./MCQuestion.module.css";

type MCQuestionProps = {
  question: MCQuestionType;
  isLoading: boolean;
  control: any;
  onToggleAnswer: (value: any, id: any) => any;
};

export default function MCQuestion({
  question,
  isLoading,
  control,
  onToggleAnswer,
}: MCQuestionProps) {
  return (
    <div className={styles.questionContainer}>
      <h1 className={styles.questionContent}>{question.content}</h1>
      <span className={styles.questionInfoText}>
        {question.correct_answer_count > 1
          ? "Select all correct answers:"
          : "Select the correct answer:"}
      </span>
      <form className={styles.answerContainer}>
        {question.answers.map((answer: any) => (
          <Controller
            key={answer.id}
            name="answers"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value } }) => (
              <ToggleButton
                id={answer.id}
                title={answer.content}
                isSelected={value.includes(answer.id)}
                onClick={(e) => {
                  e.preventDefault();
                  onChange(onToggleAnswer(value, answer.id));
                }}
                disabled={isLoading}
              />
            )}
          />
        ))}
      </form>
    </div>
  );
}
