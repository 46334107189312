import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import StepOneYellowIcon from "src/components/Icons/StepOneYellowIcon";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepContainer from "src/components/Steps/StepContainer";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import { useAppDispatch } from "src/store";
import useVideoFormReducer from "../hooks/useVideoFormReducer";
import { setVideoFormStep, VideoFormSteps } from "../redux/videoSlice";
import VideoDetailsForm from "./VideoDetailsForm";

export default function VideoFormStepOne() {
  const dispatch = useAppDispatch();
  const { step } = useVideoFormReducer();

  const handleStepButtonClick = () =>
    dispatch(setVideoFormStep(VideoFormSteps.AddDetails));

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: VideoFormSteps.AddDetails,
    titleAction:
      step !== VideoFormSteps.AddDetails ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepOneYellowIcon />,
    inactiveIcon: <CheckCircleYellowIcon />,
    defaultContent: (
      <StepContainer>
        {step !== VideoFormSteps.AddDetails ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">
            Please enter the details for your video.
          </span>
        )}
      </StepContainer>
    ),
    activeContent: <VideoDetailsForm />,
  });
}
