import Skeleton from "react-loading-skeleton";
import CheckIcon20 from "src/components/Icons/CheckIcon20";
import EmptyCircle20 from "src/components/Icons/EmptyCircle20";
import useGetModuleContentQuery from "../../hooks/useGetModuleContentQuery";
import { EmployeeModuleContentListItem } from "../../types/employeeModuleContentsV2.types";
import styles from "./PathList.module.css";

type EmployeeModuleContentListItemProps = {
  employeeMc: EmployeeModuleContentListItem;
};

export default function EmployeeModuleContentListItemComp({
  employeeMc,
}: EmployeeModuleContentListItemProps) {
  const { data: moduleContent, isLoading: isModuleContentLoading } =
    useGetModuleContentQuery({
      id: employeeMc.module_content
        ? String(employeeMc.module_content)
        : undefined,
    });

  return (
    <span key={employeeMc.id} className={styles.sectionTitle}>
      {employeeMc ? (
        <>
          <span className={styles.sectionIcon}>
            {employeeMc.status === 2 ? <CheckIcon20 /> : <EmptyCircle20 />}
          </span>
          {!isModuleContentLoading && moduleContent ? (
            moduleContent.content_object?.title
          ) : (
            <Skeleton width={100} />
          )}
        </>
      ) : (
        <Skeleton />
      )}
    </span>
  );
}
