import React, { useRef } from "react";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import getFileTypeFromAWSLink from "src/features/aws/utils/getFileTypeFromAWSLink";
import VerticalDocumentViewer from "src/features/e-signatures/components/Viewers/VerticalDocumentViewer";
import {
  EmployeeAnswerReview,
  EmployeeAnswerStatus,
} from "../types/employeeAnswers.types";
import { Sitting, SittingStatus } from "../types/sittings.types";
import parseAndNormalizeAnswer from "../utils/parseAndNormalizeAnswer";

type Props = {
  employeeAnswers?: EmployeeAnswerReview[];
  sitting?: Sitting;
};

function EmployeeAnswerReviewList({ employeeAnswers, sitting }: Props) {
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  if (!employeeAnswers || !sitting) return null;

  const sittingDetails = [
    { label: "Assessment:", value: sitting.assessment.title },
    {
      label: "Started At:",
      value: sitting.start ? new Date(sitting.start).toLocaleString() : "N/A",
    },
    {
      label: "Ended At:",
      value: sitting.end ? new Date(sitting.end).toLocaleString() : "N/A",
    },
    { label: "Time Taken:", value: sitting.time_taken || "N/A" },
    {
      label: "Score:",
      value:
        sitting.current_score != null
          ? `${sitting.current_score} / ${sitting.max_score}`
          : "N/A",
    },
    {
      label: "Percentage Correct:",
      value:
        sitting.percent_correct != null ? `${sitting.percent_correct}%` : "N/A",
    },
    {
      label: "Passing Percentage:",
      value:
        sitting.assessment.passing_percentage != null
          ? `${sitting.assessment.passing_percentage}%`
          : "N/A",
    },
  ];

  const shouldHideAnswers =
    sitting.status === SittingStatus.InProgress ||
    sitting.status === SittingStatus.Assigned;

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col p-10 border-radius-10 border-light-gray bg-cp-black">
        <div className="flex flex-col items-center gap-10 p-20">
          {sitting.employee.image && (
            <img
              src={sitting.employee.image}
              alt={`${sitting.employee.first_name} ${sitting.employee.last_name}`}
              className="border-light-gray w-100 h-100 border-radius-half object-fit-cover"
            />
          )}
          <div className="flex flex-col items-center">
            <span className="text-xl text-white">
              {sitting.employee.first_name} {sitting.employee.last_name}
            </span>
            <span className="text-sm text-muted">
              {sitting.employee.username}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-10">
          {sittingDetails.map((detail, index) => (
            <div className="flex flex-row justify-between" key={index}>
              <span className="font-bold">{detail.label}</span>
              <span>{detail.value}</span>
            </div>
          ))}
        </div>
      </div>
      {employeeAnswers.map((employeeAnswer, index) => {
        const { question, status, answer } = employeeAnswer;
        const questionNumber = index + 1;
        const isUnanswered =
          status === EmployeeAnswerStatus.UNANSWERED && shouldHideAnswers;

        if (!question) {
          return (
            <div
              key={employeeAnswer.id}
              className="border-light-gray bg-cp-black border-radius-10 p-10"
            >
              <p className="text-red-500">
                Question data is unavailable for this answer.
              </p>
            </div>
          );
        }

        const selectedIds = parseAndNormalizeAnswer(answer).map((id) =>
          Number(id)
        );

        return (
          <div
            key={employeeAnswer.id}
            className="border-light-gray bg-cp-black border-radius-10"
          >
            <table className="border-collapse w-full">
              <thead>
                <tr>
                  <th
                    className="p-10 text-align-left h-50 border-bottom-light-gray"
                    style={{ borderRight: "none" }}
                    colSpan={2}
                  >
                    {`Q${questionNumber}: ${question.content}`}
                  </th>
                </tr>
                {question.file_url && (
                  <tr>
                    <td
                      colSpan={2}
                      className="text-align-center border-bottom-light-gray"
                    >
                      {getFileTypeFromAWSLink(question.file_url) === "image" ? (
                        <img
                          src={question.file_url}
                          alt={`Question ${questionNumber}`}
                          className="max-w-full max-h-300 object-contain"
                        />
                      ) : getFileTypeFromAWSLink(question.file_url) ===
                        "video" ? (
                        <video
                          src={question.file_url}
                          controls
                          className="max-w-full max-h-300"
                        />
                      ) : getFileTypeFromAWSLink(question.file_url) ===
                        "pdf" ? (
                        <div className="max-w-full max-h-300">
                          <VerticalDocumentViewer
                            isLoading={false}
                            pageRefs={pageRefs}
                            fileUrl={question.file_url}
                          />
                        </div>
                      ) : (
                        <div className="max-w-full max-h-300 flex centered">
                          <a
                            href={question.file_url}
                            target="_blank"
                            rel="noreferrer"
                            className="text-muted"
                          >
                            Download File
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </thead>
              <tbody>
                {!isUnanswered &&
                  (question.type === "Multiple Choice"
                    ? question.answers.map((ans, ansIndex) => {
                        const isSelected = selectedIds.includes(ans.id);

                        const isCorrect = ans.correct;
                        let rowStyle = {
                          backgroundColor: isCorrect
                            ? "rgba(40, 167, 69, 0.2)"
                            : isSelected
                            ? "rgba(205, 60, 68, 0.2)"
                            : undefined,
                        };

                        return (
                          <tr key={ans.id} style={rowStyle}>
                            <td className="w-auto p-10 text-align-left h-50 border-bottom-light-gray">
                              <span className="font-bold">{`A${
                                ansIndex + 1
                              }: `}</span>
                              {ans.content}
                            </td>
                            <td className="white-space-nowrap text-align-center p-10 w-60 border-bottom-light-gray">
                              {isSelected &&
                                (isCorrect ? (
                                  <MdCheckCircle color="#28a745" size={24} />
                                ) : (
                                  <MdCancel color="#cd3c44" size={24} />
                                ))}
                            </td>
                          </tr>
                        );
                      })
                    : question.type === "Numeric" && (
                        <>
                          {[
                            {
                              label: "Employee Answer",
                              value: answer !== null ? parseFloat(answer) : "",
                            },
                            {
                              label: "Correct Answer",
                              value: question.answer,
                            },
                          ].map((item, idx) => (
                            <tr
                              key={idx}
                              style={{
                                backgroundColor:
                                  item.label === "Correct Answer"
                                    ? "rgba(40, 167, 69, 0.2)"
                                    : status === EmployeeAnswerStatus.INCORRECT
                                    ? "rgba(205, 60, 68, 0.2)"
                                    : undefined,
                              }}
                            >
                              <td className="p-10 text-align-left h-50 border-bottom-light-gray">
                                {item.label}
                              </td>
                              <td className="white-space-nowrap text-align-center p-10 w-60 border-bottom-light-gray">
                                {item.value}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td className="p-10 text-align-left h-50 border-bottom-light-gray">
                              Result
                            </td>
                            <td className="white-space-nowrap text-align-center p-10 w-60 border-bottom-light-gray">
                              {status === EmployeeAnswerStatus.CORRECT ? (
                                <MdCheckCircle color="#28a745" size={24} />
                              ) : (
                                <MdCancel color="#cd3c44" size={24} />
                              )}
                            </td>
                          </tr>
                        </>
                      ))}
                {!isUnanswered && (
                  <tr>
                    <td
                      colSpan={2}
                      className="p-10 text-align-left border-bottom-none h-50"
                      style={{ borderRight: "none" }}
                    >
                      <span className="font-bold">Explanation:</span>{" "}
                      {question.explanation || "No explanation"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(EmployeeAnswerReviewList);
