import axiosClient from "src/features/axios/client";
import {
  MCAnswerFormData,
  NumericAnswerFormData,
} from "../components/SittingQuestionTakeForm";
import {
  MCQuestionType,
  NumericalQuestionType,
} from "../types/assessments.types";
import { Sitting } from "../types/sittings.types";

class SittingTakeService {
  async getMySittingTake(id: number): Promise<Sitting> {
    return await axiosClient
      .get(`/api/sitting-take/${id}/`)
      .then((res) => res.data);
  }

  async getNextQuestion(
    id: number
  ): Promise<NumericalQuestionType | MCQuestionType | null> {
    return await axiosClient
      .get(`/api/sitting-take/${id}/next-question/`)
      .then((res) => res.data);
  }

  async postNumericAnswer(
    data: NumericAnswerFormData
  ): Promise<NumericalQuestionType | MCQuestionType | null> {
    return await axiosClient
      .post(`/api/sitting-take/${data.sitting}/submit-numeric-answer/`, data)
      .then((res) => res.data);
  }

  async postMCAnswer(
    data: MCAnswerFormData
  ): Promise<NumericalQuestionType | MCQuestionType | null> {
    return await axiosClient
      .post(`/api/sitting-take/${data.sitting}/submit-mc-answer/`, data)
      .then((res) => res.data);
  }
}

const sittingTakeService = new SittingTakeService();

export default sittingTakeService;
