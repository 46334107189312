import { ModuleContentTypes } from "../learningPaths.enums";

const getModuleContentTypeString = (content?: ModuleContentTypes) => {
  switch (content) {
    case ModuleContentTypes.Assessment:
      return "Assessment";
    case ModuleContentTypes.Video:
      return "Video";
    case ModuleContentTypes.Policy:
      return "Policy";
    case ModuleContentTypes.ModuleContentFile:
      return "File";
    default:
      return "Unknown";
  }
};

export default getModuleContentTypeString;
