export enum ImageGeneratorStatus {
  Processing = "processing",
  Complete = "complete",
  Failed = "failed",
  Retry = "retry",
}

export enum ImageGeneratorSteps {
  ReceivedPrompt = "received_prompt",
  GeneratingImage = "generating_image",
  FinalizingData = "finalizing_data",
}
