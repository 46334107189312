import { useEffect, useState } from "react";

const useScroll = (scrollRef: React.RefObject<HTMLDivElement>) => {
  const [scrollState, setScrollState] = useState({ left: false, right: false });

  useEffect(() => {
    const element = scrollRef.current;

    const checkScroll = () => {
      if (!element) return;

      const { scrollLeft, scrollWidth, clientWidth } = element;
      setScrollState({
        left: scrollLeft > 0,
        right: scrollLeft < scrollWidth - clientWidth,
      });
    };

    checkScroll();

    if (element) {
      element.addEventListener("scroll", checkScroll);
      return () => element.removeEventListener("scroll", checkScroll);
    }
  }, [scrollRef]);

  return scrollState;
};

export default useScroll;
