import SearchInput from "src/components/Inputs/SearchInput";
import PageLayout from "src/components/Layout/PageLayout";
import UnreadDiscussionsIndicator from "src/features/discussions/components/UnreadDiscussionsIndicator";
import useSetHeaderTitle from "src/features/header/hooks/useSetHeaderTitle";
import SiteHeader from "../../../components/Headers/SiteHeader";
import LearningPathTakeV2 from "../componentsV2/LearningPathTakeV2";

export default function LearningPathsTakeRouteV2() {
  useSetHeaderTitle("Learning Paths");

  return (
    <PageLayout
      header={
        <SiteHeader
          id="learning-paths-take-header"
          actions={
            <>
              <SearchInput id="lpSearch" />
              <UnreadDiscussionsIndicator />
            </>
          }
        />
      }
      mainContent={<LearningPathTakeV2 />}
      rightContent={undefined}
    />
  );
}
