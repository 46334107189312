import { useEffect, useState } from "react";
import { MdArrowForward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import Card from "src/components/Cards/Card";
import Dialog from "src/components/Dialog/Dialog";
import DialogContent from "src/components/Dialog/DialogContent";
import DialogHeader from "src/components/Dialog/DialogHeader";
import ProgressBar from "src/components/ProgressBar/ProgressBar";
import useWindowSize from "src/features/page/hooks/useWindowSize";
import PdfViewer from "src/features/pdf/components/PdfViewer";
import { EmployeeLearningPathListItem } from "../types/employeeLearningPathsV2.types";

type Props = {
  employeeLearningPath?: EmployeeLearningPathListItem;
};

export default function EmployeeLearningPathDetailCard({
  employeeLearningPath,
}: Props) {
  const [isCertificateDialogOpen, setIsCertificateDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { windowWidth, windowHeight } = useWindowSize();

  useEffect(() => {
    if (employeeLearningPath?.certificate_url) {
      setIsCertificateDialogOpen(true);
    }
  }, [employeeLearningPath]);

  const learningPath = employeeLearningPath?.learning_path;

  const title = "Congratulations! You have completed this learning path!";

  const description = employeeLearningPath?.overall_score
    ? `You have successfully completed this learning path with an overall score of ${employeeLearningPath?.overall_score}%`
    : "You have successfully completed this learning path!";

  const handleBackToLearningPaths = () => navigate("/learning-paths");

  const handleOpenCertificateInNewWindow = () => {
    if (employeeLearningPath?.certificate_url) {
      window.open(employeeLearningPath.certificate_url, "_blank");
    }
  };

  return (
    <>
      <div className="flex card">
        <Dialog
          isOpen={isCertificateDialogOpen}
          setIsOpen={setIsCertificateDialogOpen}
        >
          <DialogHeader>Learning Path Certificate</DialogHeader>
          <DialogContent>
            {employeeLearningPath?.certificate_url && (
              <div
                style={{ overflowX: "auto" }}
                onClick={handleOpenCertificateInNewWindow}
              >
                <PdfViewer
                  file={employeeLearningPath?.certificate_url}
                  width={windowWidth < 576 ? windowWidth - 20 : windowHeight}
                />
              </div>
            )}
          </DialogContent>
        </Dialog>
        <div className="flex flex-col assessmentFeatureItemContainer p-10">
          <div className={"assessmentDetailPreviewInfo"}>
            <div className="flex flex-1 flex-row-mobile-column p-10 gap-20">
              <div className="flex flex-col flex-1 gap-20">
                {title ? (
                  <span
                    className={"textShadow text-xxl font-w-600"}
                    data-testid="title"
                  >
                    {title}
                  </span>
                ) : null}
                {description ? (
                  <span
                    className={
                      "assessmentDetailPreviewCategory truncatedDescription"
                    }
                    data-testid="subTitle"
                  >
                    {description}
                  </span>
                ) : null}
                <span className={"flex gap-10"} data-testid="actions">
                  <div>
                    <Button
                      color={ButtonColors.Yellow}
                      onClick={handleBackToLearningPaths}
                      dataTestid="navigate-button"
                    >
                      <MdArrowForward size={20} />
                      Back to Learning Paths
                    </Button>
                  </div>
                </span>
              </div>
              <div className="flex flex-col flex-1-desktop justify-center">
                <Card className="flex flex-col gap-10 p-20">
                  {employeeLearningPath?.completion_percentage !== undefined ? (
                    <>
                      <div className="flex flex-row justify-between">
                        <span className="text-sm font-bold">
                          Learning Path Progress
                        </span>
                        <span className="text-sm font-bold">
                          {employeeLearningPath.completion_percentage}%
                        </span>
                      </div>
                      <ProgressBar
                        progress={employeeLearningPath.completion_percentage}
                        progressBarStyle={{ height: 10, borderRadius: 5 }}
                        progressClassName={"bg-cp-green"}
                        containerClassName="bg-cp-yellow-10"
                      />
                    </>
                  ) : null}
                </Card>
              </div>
            </div>
          </div>

          <>
            <div className="featureItemImageContainer">
              {learningPath?.file_url ? (
                <img
                  src={learningPath.file_url}
                  alt={learningPath.title}
                  className="w-full object-cover border-radius-10"
                />
              ) : null}
            </div>
          </>
          <div className="featureItemOverlay" />
        </div>
      </div>
    </>
  );
}
