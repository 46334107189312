const SvgComponent = (props) => (
  <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.75 10.5a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5ZM11 11l-1-1"
      stroke="#4f4f4f"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
