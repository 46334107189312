import { z } from "zod";
import {
  ScheduledTodoStatus,
  TodoObjectTypes,
} from "../types/todosEnums.types";

export const createScheduledTodosFormSchema = z.object({
  objectType: z.nativeEnum(TodoObjectTypes).optional(),
  objectId: z.string().or(z.number()).optional(),
  status: z.nativeEnum(ScheduledTodoStatus).optional(),
  employees: z.array(z.string().or(z.number())).optional(),
  roles: z.array(z.string().or(z.number())).optional(),
  send_notifications: z.boolean().optional(),
  allow_duplicate_todos: z.boolean().optional(),
  start_date: z.date(),
  recurrence: z.string().min(1, "Please select a recurrence").or(z.number()),
  deadline: z.string().min(1, "Please select a deadline").nullable(),
});

export type CreateScheduledTodosFormValues = z.infer<
  typeof createScheduledTodosFormSchema
>;
