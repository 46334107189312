import React from "react";
import Card from "src/components/Cards/Card";

type HorizontalListCardProps = {
  imageSrc?: string;
  imageAlt: string;
  header?: React.ReactNode;
  body: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string;
};

function HorizontalListCard({
  imageSrc,
  imageAlt,
  header,
  body,
  onClick,
  style,
  className,
}: HorizontalListCardProps) {
  return (
    <Card
      className={`hover-bg-white-20 flex flex-col ${className}`}
      style={{ width: 300, padding: "0.5rem", ...style }}
      onClick={onClick}
    >
      {imageSrc ? (
        <img
          src={imageSrc}
          alt={imageAlt}
          className="w-full border-radius-5 cardImage"
        />
      ) : (
        <div className="w-full border-radius-5 cardImage bg-mid-gray centered">
          <span className="text-muted">No image found.</span>
        </div>
      )}
      <div className="flex flex-col gap-10 py-10 flex-1">
        <div className="flex flex-row justify-between">{header}</div>
        {body}
      </div>
    </Card>
  );
}

export default React.memo(HorizontalListCard);
