import { InfiniteQueryFilters } from "src/components/Lists/FilterableListSidebar";
import {
  DjangoDetailResponse,
  PaginatedApiResponse,
  SearchAndPaginationFilters,
} from "src/features/axios/axios.types";
import axiosClient from "src/features/axios/client";
import { TodoDetail } from "src/features/todos/types/todos.types";
import {
  AssessmentDetail,
  AssessmentListItem,
} from "../types/assessments.types";
import { MySittingListItem, Sitting } from "../types/sittings.types";
import { AssessmentDetailsFormSchemaType } from "../zod/AssessmentDetailsSchema";
import { AssessmentUpdateFormSchemaType } from "../zod/assessmentSchema";
import { SubClassedQuestion } from "./questionsService";

export type GetAssessmentsSelectListResponse = {
  id: number;
  title: string;
  category: string;
};

export type QuestionToAssessmentParams = {
  id: number;
  question_id: number;
};

export type UpdateQuestionPositionParams = {
  id: number;
  assessment_id: number;
  new_position: number;
};

export type GetAssessmentsPaginatedParams = SearchAndPaginationFilters & {
  start_date?: string;
  end_date?: string;
};

class AssessmentService {
  async addQuestionToAssessment({
    id,
    question_id,
  }: QuestionToAssessmentParams): Promise<AssessmentDetail> {
    return await axiosClient
      .post(`/api/assessments/${id}/add-question/`, { question_id })
      .then((res) => res.data);
  }

  async createAssessment(
    data: AssessmentDetailsFormSchemaType
  ): Promise<AssessmentDetail> {
    return await axiosClient
      .post("/api/assessments/", data)
      .then((res) => res.data);
  }

  async createAssessmentCopy(id: string): Promise<AssessmentDetail> {
    return await axiosClient
      .post(`/api/assessments/${id}/copy/`)
      .then((res) => res.data);
  }

  async createQuestion(data: FormData): Promise<SubClassedQuestion> {
    return await axiosClient
      .post(`/api/questions/`, data)
      .then((res) => res.data);
  }

  async deleteAssessment(id: string): Promise<{}> {
    return await axiosClient
      .delete(`/api/assessments/${id}/`)
      .then((res) => res.data);
  }

  async deleteAssessmentQuestion({
    id,
    question_id,
  }: QuestionToAssessmentParams): Promise<AssessmentDetail> {
    return await axiosClient
      .post(`/api/assessments/${id}/remove-question/`, { question_id })
      .then((res) => res.data);
  }

  async getAssessment(id: string): Promise<AssessmentDetail> {
    return await axiosClient
      .get(`/api/assessments/${id}`)
      .then((res) => res.data);
  }

  async getAssessments(): Promise<AssessmentListItem[]> {
    return await axiosClient.get("/api/assessments/").then((res) => res.data);
  }

  async getAssessmentsPaginated(
    params: GetAssessmentsPaginatedParams
  ): Promise<PaginatedApiResponse<AssessmentListItem>> {
    return await axiosClient.get("/api/assessments/", { params });
  }

  async getAssessmentTodos(id: string): Promise<TodoDetail[]> {
    return await axiosClient
      .get(`/api/assessments/${id}/todos/`)
      .then((res) => res.data);
  }

  async getMyRecentSittingsList(): Promise<MySittingListItem[]> {
    return await axiosClient
      .get("/api/my-recent-sittings/")
      .then((res) => res.data);
  }

  async getSitting(id: string): Promise<Sitting> {
    return await axiosClient
      .get(`/api/sittings/${id}/`)
      .then((res) => res.data);
  }

  async getSittings({
    assessmentId,
    page = 1,
    employee_module_content__isnull = true,
    sortBy,
    sortDirection,
    ...filters
  }: any): Promise<Sitting[]> {
    return await axiosClient
      .get("/api/sittings/", {
        params: {
          assessment_id: assessmentId,
          employee_module_content__isnull,
          page,
          sortBy,
          sortDirection,
          ...filters,
        },
      })
      .then((res) => res.data);
  }

  async getSittingsPaginated({
    page,
    page_size,
  }: InfiniteQueryFilters): Promise<PaginatedApiResponse<Sitting>> {
    return await axiosClient.get("/api/sittings/", {
      params: { page, page_size },
    });
  }

  async startAssessment(assessmentId: string): Promise<Sitting> {
    return await axiosClient
      .post(`/api/assessments/${assessmentId}/start/`)
      .then((res) => res.data);
  }

  async partialUpdateAssessment(
    data: Partial<AssessmentUpdateFormSchemaType>
  ): Promise<AssessmentDetail> {
    return await axiosClient
      .patch(`/api/assessments/${data.id}/`, data)
      .then((res) => res.data);
  }

  async partialUpdateQuestion(
    data: FormData,
    id: string
  ): Promise<SubClassedQuestion> {
    return await axiosClient
      .patch(`/api/questions/${id}/`, data)
      .then((res) => res.data);
  }

  async updateAssessment(
    id: string | number,
    data: AssessmentDetailsFormSchemaType
  ): Promise<AssessmentDetail> {
    return await axiosClient
      .put(`/api/assessments/${id}/`, data)
      .then((res) => res.data);
  }

  async updateQuestionPosition({
    id,
    assessment_id,
    new_position,
  }: UpdateQuestionPositionParams): Promise<DjangoDetailResponse> {
    return await axiosClient
      .patch(`/api/questions/${id}/update-position/`, {
        assessment_id,
        new_position,
      })
      .then((res) => res.data);
  }
}

const assessmentService = new AssessmentService();

export default assessmentService;
