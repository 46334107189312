import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import myTodosService from "../../services/myTodosService";
import { CompositeTodoFilter } from "../../types/todos.types";

export default function useGetMyTodosListQuery(params: CompositeTodoFilter) {
  return useQuery({
    queryKey: [QueryKeys.MyTodosList, params],
    queryFn: () => myTodosService.getMyTodos({ ...params }),
  });
}
