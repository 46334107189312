import CheckCircleYellowIcon from "src/components/Icons/CheckCircleYellowIcon";
import StepFourGrayIcon from "src/components/Icons/StepFourGrayIcon";
import StepFourYellowIcon from "src/components/Icons/StepFourYellowIcon";
import useVerticalStepperContent from "src/components/Steps/hooks/useVerticalStepperContent";
import StepContainer from "src/components/Steps/StepContainer";
import StepEditButton from "src/features/learningPaths/components/LPBuilder/Steps/StepEditButton";
import { useAppDispatch } from "src/store";
import useVideoFormReducer from "../hooks/useVideoFormReducer";
import { setVideoFormStep, VideoFormSteps } from "../redux/videoSlice";
import VideoScheduleContent from "./VideoScheduleContent";

export default function VideoFormStepFour() {
  const { step } = useVideoFormReducer();
  const dispatch = useAppDispatch();

  const handleStepButtonClick = () =>
    dispatch(setVideoFormStep(VideoFormSteps.AssignEmployees));

  return useVerticalStepperContent({
    currentStep: step,
    activeStep: VideoFormSteps.AssignEmployees,
    titleAction:
      step !== VideoFormSteps.AddDetails &&
      step !== VideoFormSteps.UploadVideo &&
      step !== VideoFormSteps.UploadThumbnail &&
      step !== VideoFormSteps.AssignEmployees ? (
        <StepEditButton onClick={handleStepButtonClick} />
      ) : null,
    activeIcon: <StepFourYellowIcon />,
    inactiveIcon:
      step === VideoFormSteps.Publish ? (
        <CheckCircleYellowIcon />
      ) : (
        <StepFourGrayIcon />
      ),
    defaultContent: (
      <StepContainer>
        {step !== VideoFormSteps.AddDetails &&
        step !== VideoFormSteps.UploadVideo &&
        step !== VideoFormSteps.UploadThumbnail &&
        step !== VideoFormSteps.AssignEmployees ? (
          <span className="text-muted">This step is complete!</span>
        ) : (
          <span className="text-muted">Assign the video to employees.</span>
        )}
      </StepContainer>
    ),
    activeContent: <VideoScheduleContent />,
  });
}
