import { useEffect } from "react";

import Card from "src/components/Cards/Card";
import Container from "src/components/Container/Container";
import VerticalStepper from "src/components/Steps/VerticalStepper";
import useSetHeaderTitle from "src/features/header/hooks/useSetHeaderTitle";
import { useAppDispatch } from "src/store";
import { PolicyFormSteps, setPolicyFormStep } from "../../slices/policySlice";
import PolicyFormStepFive from "./PolicyFormStepFive";
import PolicyFormStepOne from "./PolicyFormStepOne";
import PolicyFormStepThree from "./PolicyFormStepThree";
import PolicyFormStepTwo from "./PolicyFormStepTwo";
import styles from "./PolicySteps.module.css";

export default function PolicyCreateEditPage() {
  const dispatch = useAppDispatch();
  useSetHeaderTitle("Policy Form");

  useEffect(() => {
    dispatch(setPolicyFormStep(PolicyFormSteps.AddDetails));
  }, [dispatch]);

  const steps = [
    PolicyFormStepOne(),
    PolicyFormStepTwo(),
    PolicyFormStepThree(),
    PolicyFormStepFive(),
  ];

  return (
    <Card>
      <Container style={{ padding: "20px 10px" }}>
        <VerticalStepper steps={steps} className={styles.step} />
      </Container>
    </Card>
  );
}
