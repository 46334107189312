import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import getFileTypeFromAWSLink from "src/features/aws/utils/getFileTypeFromAWSLink";
import { ModuleContentTypes } from "../learningPaths.enums";
import employeeLearningPathsService from "../servicesV2/employeeLearningPathsService";
import employeeModuleContentsService from "../servicesV2/employeeModuleContentsService";
import employeeModulesService from "../servicesV2/employeeModulesService";
import { EmployeeLpStatus } from "../types/employeeLearningPathsV2.types";
import {
  EmployeeModuleContentListItem,
  EmployeeModuleContentStatus,
} from "../types/employeeModuleContentsV2.types";
import {
  EmployeeModuleListItem,
  EmployeeModuleStatus,
} from "../types/employeeModulesV2.types";
import EmployeeLearningPathDetailCard from "./EmployeeLearningPathDetailCard";
import EmployeeModuleContentAssessment from "./EmployeeModuleContents/EmployeeModuleContentAssessment";
import EmployeeModuleContentImageViewer from "./EmployeeModuleContents/EmployeeModuleContentImageViewer";
import EmployeeModuleContentPdfViewer from "./EmployeeModuleContents/EmployeeModuleContentPdfViewer";
import EmployeeModuleContentVideo from "./EmployeeModuleContents/EmployeeModuleContentVideo";
import EmployeeModuleDetailsCard from "./EmployeeModuleDetailsCard";

function LearningPathTakeV2() {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();

  const [currentEmployeeModule, setCurrentEmployeeModule] =
    useState<EmployeeModuleListItem | null>(null);
  const [currentEmployeeModuleContent, setCurrentEmployeeModuleContent] =
    useState<EmployeeModuleContentListItem | null>(null);
  const [showModuleDetailsScreen, setShowModuleDetailsScreen] =
    useState<boolean>(false);
  const [showLearningPathCompletedScreen, setShowLearningPathCompletedScreen] =
    useState<boolean>(false);

  const {
    data: employeeLearningPath,
    isLoading: isEmployeeLearningPathLoading,
    isRefetching: isEmployeeLearningPathRefetching,
    isError: isEmployeeLearningPathError,
    refetch: refetchEmployeeLearningPath,
  } = useQuery({
    queryKey: ["getEmployeeLearningPath", String(id)],
    queryFn: id
      ? () => employeeLearningPathsService.getEmployeeLearningPath(parseInt(id))
      : undefined,
    enabled: !!id,
    onSuccess: (data) => {
      if (data.status === EmployeeLpStatus.Completed) {
        setShowLearningPathCompletedScreen(true);
      }
    },
  });

  const {
    data: employeeModules,
    isInitialLoading: isEmployeeModulesLoading,
    isRefetching: isEmployeeModulesRefetching,
    isError: isEmployeeModulesError,
    refetch: refetchEmployeeModules,
  } = useQuery({
    queryKey: ["getEmployeeModules", String(id)],
    queryFn: id
      ? () =>
          employeeModulesService.getEmployeeModules({
            employee_learning_path_id: parseInt(id),
          })
      : undefined,
    enabled: !!id,
    onSuccess: (data) => {
      // If there is a module with status Assigned or InProgress, it gets selected
      // as the current module.
      const currentEmpModule = data.find(
        (empModule) =>
          empModule.status === EmployeeModuleStatus.Assigned ||
          empModule.status === EmployeeModuleStatus.InProgress
      );
      if (currentEmpModule) {
        setCurrentEmployeeModule(currentEmpModule);
      }
    },
  });

  const {
    data: employeeModuleContents,
    isInitialLoading: isEmployeeModuleContentsLoading,
    isRefetching: isEmployeeModuleContentsRefetching,
    isError: isEmployeeModuleContentsError,
    refetch: refetchEmployeeModuleContents,
  } = useQuery({
    queryKey: ["getEmployeeModuleContents", String(currentEmployeeModule?.id)],
    queryFn: currentEmployeeModule
      ? () =>
          employeeModuleContentsService.getEmployeeModuleContents({
            employee_module_id: currentEmployeeModule.id,
          })
      : undefined,
    enabled: !!currentEmployeeModule,
    onSuccess: (data) => {
      setShowModuleDetailsScreen(true);
      // get the first content with status Assigned or InProgress
      const currentEmpModuleContent = data.find(
        (empModuleContent) =>
          empModuleContent.status === EmployeeModuleContentStatus.Assigned ||
          empModuleContent.status === EmployeeModuleContentStatus.InProgress
      );
      if (currentEmpModuleContent) {
        setCurrentEmployeeModuleContent(currentEmpModuleContent);
      }
    },
  });

  const markEmployeeLearningPathAsCompletedMutation = useMutation({
    mutationFn: employeeLearningPathsService.markAsCompleted,
    onSuccess: (data) => {
      queryClient.setQueryData(["getEmployeeLearningPath", String(id)], data);
      setShowLearningPathCompletedScreen(true);
    },
  });

  const startEmployeeModuleMutation = useMutation({
    mutationFn: employeeModulesService.startEmployeeModule,
    onSuccess: (data) => {
      // update the query cache with the started employee module
      queryClient.setQueryData(
        ["getEmployeeModules", String(id)],
        (prevData: EmployeeModuleListItem[] | undefined) => {
          if (prevData) {
            return prevData.map((empModule) =>
              empModule.id === data.id ? data : empModule
            );
          }
          return prevData;
        }
      );
      setCurrentEmployeeModule(data);
    },
  });

  const markEmployeeModuleAsCompletedMutation = useMutation({
    mutationFn: employeeModulesService.markAsCompleted,
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["getEmployeeModules", String(id)],
        (prevData: EmployeeModuleListItem[] | undefined) => {
          if (prevData) {
            return prevData.map((empModule) =>
              empModule.id === data.id ? data : empModule
            );
          }
          return prevData;
        }
      );
    },
  });

  const startEmployeeModuleContentMutation = useMutation({
    mutationFn: employeeModuleContentsService.markAsStarted,
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["getEmployeeModuleContents", String(currentEmployeeModule?.id)],
        (prevData: EmployeeModuleContentListItem[] | undefined) => {
          if (prevData) {
            return prevData.map((empModuleContent) =>
              empModuleContent.id === data.id ? data : empModuleContent
            );
          }
          return prevData;
        }
      );
      setCurrentEmployeeModuleContent(data);
    },
  });

  const markEmployeeModuleContentAsCompletedMutation = useMutation({
    mutationFn: employeeModuleContentsService.markAsCompleted,
    onSuccess: async (data) => {
      queryClient.setQueryData(
        ["getEmployeeModuleContents", String(currentEmployeeModule?.id)],
        (prevData: EmployeeModuleContentListItem[] | undefined) => {
          if (prevData) {
            return prevData.map((empModuleContent) =>
              empModuleContent.id === data.id ? data : empModuleContent
            );
          }
          return prevData;
        }
      );

      const allEmployeeModuleContents = queryClient.getQueryData<
        EmployeeModuleContentListItem[]
      >(["getEmployeeModuleContents", String(currentEmployeeModule?.id)]);

      if (allEmployeeModuleContents) {
        const allContentsCompleted = allEmployeeModuleContents.every(
          (empModuleContent) =>
            empModuleContent.status === EmployeeModuleContentStatus.Completed
        );

        if (
          allContentsCompleted &&
          currentEmployeeModuleContent?.employee_module
        ) {
          // partial update mutation the employee module status to completed
          markEmployeeModuleAsCompletedMutation.mutate(
            currentEmployeeModuleContent?.employee_module
          );
        }
      }
    },
  });

  const startEmployeeLearningPathMutation = useMutation({
    mutationFn: employeeLearningPathsService.markAsStarted,
    onSuccess: (data) => {
      queryClient.setQueryData(["getEmployeeLearningPath", String(id)], data);
    },
  });

  const handleEmployeeModuleContinue = useCallback(() => {
    if (employeeLearningPath?.status === EmployeeLpStatus.Assigned) {
      startEmployeeLearningPathMutation.mutate(employeeLearningPath.id);
    }
    if (currentEmployeeModule?.status === EmployeeModuleStatus.Assigned) {
      startEmployeeModuleMutation.mutate(currentEmployeeModule.id);
    }
    if (
      currentEmployeeModuleContent?.status ===
      EmployeeModuleContentStatus.Assigned
    ) {
      startEmployeeModuleContentMutation.mutate(
        currentEmployeeModuleContent.id
      );
    }
    setShowModuleDetailsScreen(false);
  }, [
    currentEmployeeModule?.id,
    currentEmployeeModule?.status,
    currentEmployeeModuleContent?.id,
    currentEmployeeModuleContent?.status,
    employeeLearningPath?.id,
    employeeLearningPath?.status,
    startEmployeeModuleContentMutation,
    startEmployeeModuleMutation,
    startEmployeeLearningPathMutation,
  ]);

  const handleClickNext = useCallback(() => {
    // get the next employee module content which is assigned or in
    // progress. If there is no next employee module content, try the
    // next employee module. If there is no next employee module,
    // mark the learning path as completed. from there the onSuccess
    // should redirect to the learning path details page.
    const nextEmpModuleContent = employeeModuleContents?.find(
      (empModuleContent) =>
        empModuleContent.status === EmployeeModuleContentStatus.Assigned ||
        empModuleContent.status === EmployeeModuleContentStatus.InProgress
    );
    if (nextEmpModuleContent) {
      startEmployeeModuleContentMutation.mutate(nextEmpModuleContent.id);
      return;
    }
    const nextEmpModule = employeeModules?.find(
      (empModule) =>
        empModule.status === EmployeeModuleStatus.Assigned ||
        empModule.status === EmployeeModuleStatus.InProgress
    );
    if (nextEmpModule) {
      startEmployeeModuleMutation.mutate(nextEmpModule.id);
      setShowModuleDetailsScreen(true);
      return;
    }
    // mark the learning path as completed
    if (employeeLearningPath) {
      markEmployeeLearningPathAsCompletedMutation.mutate(
        employeeLearningPath?.id
      );
    }
  }, [
    employeeLearningPath,
    employeeModuleContents,
    employeeModules,
    markEmployeeLearningPathAsCompletedMutation,
    startEmployeeModuleContentMutation,
    startEmployeeModuleMutation,
  ]);

  const handleModuleContentCompleted = useCallback(() => {
    if (!currentEmployeeModuleContent) return;
    markEmployeeModuleContentAsCompletedMutation.mutate(
      currentEmployeeModuleContent?.id
    );
  }, [
    currentEmployeeModuleContent,
    markEmployeeModuleContentAsCompletedMutation,
  ]);

  const handleRefetchAll = useCallback(() => {
    refetchEmployeeLearningPath();
    refetchEmployeeModules();
    refetchEmployeeModuleContents();
  }, [
    refetchEmployeeLearningPath,
    refetchEmployeeModules,
    refetchEmployeeModuleContents,
  ]);

  const isLoading =
    isEmployeeLearningPathLoading ||
    isEmployeeModulesLoading ||
    isEmployeeLearningPathRefetching ||
    isEmployeeModulesRefetching ||
    isEmployeeModuleContentsLoading ||
    isEmployeeModuleContentsRefetching;

  if (isLoading) {
    return (
      <div className="flex flex-1 p-10 card min-h-300">
        <Skeleton height={800} />
      </div>
    );
  }

  if (
    isEmployeeLearningPathError ||
    isEmployeeModulesError ||
    isEmployeeModuleContentsError
  ) {
    return (
      <div className="flex flex-1 p-10 card centered min-h-300">
        <ErrorFallback onReload={handleRefetchAll} />
      </div>
    );
  }

  if (showModuleDetailsScreen) {
    return (
      <EmployeeModuleDetailsCard
        onClickContinue={handleEmployeeModuleContinue}
        employeeModule={currentEmployeeModule}
        employeeModuleContents={employeeModuleContents}
      />
    );
  }

  if (showLearningPathCompletedScreen) {
    return (
      <EmployeeLearningPathDetailCard
        employeeLearningPath={employeeLearningPath}
      />
    );
  }

  if (currentEmployeeModuleContent) {
    switch (currentEmployeeModuleContent.module_content.content_type) {
      case ModuleContentTypes.Assessment:
        return (
          <EmployeeModuleContentAssessment
            employeeModuleContent={currentEmployeeModuleContent}
            onClickNext={handleClickNext}
            onModuleContentCompleted={handleModuleContentCompleted}
          />
        );
      case ModuleContentTypes.Policy:
        return (
          <EmployeeModuleContentPdfViewer
            employeeModuleContent={currentEmployeeModuleContent}
            onClickNext={handleClickNext}
            onModuleContentCompleted={handleModuleContentCompleted}
          />
        );
      case ModuleContentTypes.Video:
        return (
          <EmployeeModuleContentVideo
            employeeModuleContent={currentEmployeeModuleContent}
            onClickNext={handleClickNext}
            onModuleContentCompleted={handleModuleContentCompleted}
          />
        );
      case ModuleContentTypes.ModuleContentFile:
        const fileUrl =
          currentEmployeeModuleContent.module_content.content_object.file_url;
        const isVideoFile = getFileTypeFromAWSLink(fileUrl) === "video";
        const isImageFile = getFileTypeFromAWSLink(fileUrl) === "image";
        const isPdfFile = getFileTypeFromAWSLink(fileUrl) === "pdf";
        if (isVideoFile) {
          return (
            <EmployeeModuleContentVideo
              employeeModuleContent={currentEmployeeModuleContent}
              onClickNext={handleClickNext}
              onModuleContentCompleted={handleModuleContentCompleted}
            />
          );
        } else if (isPdfFile) {
          return (
            <EmployeeModuleContentPdfViewer
              employeeModuleContent={currentEmployeeModuleContent}
              onClickNext={handleClickNext}
              onModuleContentCompleted={handleModuleContentCompleted}
            />
          );
        } else if (isImageFile) {
          return (
            <EmployeeModuleContentImageViewer
              employeeModuleContent={currentEmployeeModuleContent}
              onClickNext={handleClickNext}
              onModuleContentCompleted={handleModuleContentCompleted}
            />
          );
        }
        return <div className="flex p-10 card">No content found</div>;
      default:
        return <div className="flex p-10 card">No content found</div>;
    }
  }

  return (
    <div className="flex flex-1 p-10 card centered min-h-300">
      <Button
        className="border-radius-5"
        color={ButtonColors.Yellow}
        size={ButtonSizes.LG}
        title="Finish Learning Path"
        type="button"
        onClick={
          id
            ? () =>
                markEmployeeLearningPathAsCompletedMutation.mutate(parseInt(id))
            : undefined
        }
        disabled={false}
      />
    </div>
  );
}

export default React.memo(LearningPathTakeV2);
