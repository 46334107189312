import { useAlert } from "@blaumaus/react-alert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import Button from "src/components/Buttons/Button";
import { ButtonColors } from "src/components/Buttons/buttons.types";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import StepContainer from "src/components/Steps/StepContainer";
import { SAVE_AS_DRAFT } from "src/features/buttons/titles";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useVideoFormReducer from "../hooks/useVideoFormReducer";
import videoService2 from "../services/videoService2";

export default function VideoPublishForm() {
  const alert = useAlert();
  const navigate = useNavigate();
  const handleErrors = useErrorHandling();
  const queryClient = useQueryClient();
  const { videoId } = useVideoFormReducer();

  const partialUpdateVideoMutation = useMutation({
    mutationFn: videoService2.partialUpdateVideo,
    onSuccess: async (data) => {
      queryClient.setQueryData([QueryKeys.Video, String(data.id)], data);
      await queryClient.invalidateQueries([QueryKeys.MyTodosList]);
      alert.success("Video saved successfully!");
      navigate(`/videos/${data.id}`);
    },
    onError: handleErrors,
  });

  const handleSaveAsDraft = () => {
    if (videoId) {
      partialUpdateVideoMutation.mutate({
        id: Number(videoId),
        draft: true,
      });
    }
  };

  const handlePublishVideo = () => {
    if (videoId) {
      partialUpdateVideoMutation.mutate({
        id: Number(videoId),
        draft: false,
      });
    }
  };

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">
          Publish the video or save it as a draft.
        </span>
      </div>
      <EditingContainer>
        <ButtonsContainer>
          <Button
            title={SAVE_AS_DRAFT}
            color={ButtonColors.GrayAndYellow}
            onClick={handleSaveAsDraft}
          />
          <Button
            title="Publish Video"
            color={ButtonColors.Yellow}
            onClick={handlePublishVideo}
          />
        </ButtonsContainer>
      </EditingContainer>
    </StepContainer>
  );
}
