import { useQuery } from "@tanstack/react-query";

import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import learningPathService from "../services/learningPathService";

type LearningPathDetailQuery = {
  id?: string | null;
};

export default function useGetLearningPathDetailQuery({
  id,
}: LearningPathDetailQuery) {
  return useQuery({
    queryKey: [QueryKeys.LearningPath, String(id)],
    queryFn: id ? () => learningPathService.getLearningPath(id) : undefined,
    enabled: !!id,
  });
}
