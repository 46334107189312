import { Sitting } from "src/features/assessments/types/sittings.types";
import axiosClient from "src/features/axios/client";
import { EmployeeModuleContentListItem } from "../types/employeeModuleContentsV2.types";

export type GetEmployeeModuleContentsParams = {
  employee_module_id: number;
};

class EmployeeModuleContentsService {
  async getEmployeeModuleContents(
    params: GetEmployeeModuleContentsParams
  ): Promise<EmployeeModuleContentListItem[]> {
    return await axiosClient
      .get(`/api/employee-module-contents/`, { params })
      .then((res) => res.data);
  }

  async markAsStarted(id: number): Promise<EmployeeModuleContentListItem> {
    return await axiosClient
      .post(`/api/employee-module-contents/${id}/start/`)
      .then((res) => res.data);
  }

  async markAsCompleted(id: number): Promise<EmployeeModuleContentListItem> {
    return await axiosClient
      .post(`/api/employee-module-contents/${id}/complete/`)
      .then((res) => res.data);
  }

  async startAssessment(id: number): Promise<Sitting> {
    return await axiosClient
      .post(`/api/employee-module-contents/${id}/start-assessment/`)
      .then((res) => res.data);
  }
}

const employeeModuleContentsService = new EmployeeModuleContentsService();

export default employeeModuleContentsService;
