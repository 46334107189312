import Skeleton from "react-loading-skeleton";

import StepContainer from "src/components/Steps/StepContainer";
import ScheduledTodosForm from "src/features/todos/components/ScheduledTodosForm";
import ScheduledTodosTable from "src/features/todos/components/ScheduledTodosTable";
import useGetScheduledTodosListQuery from "src/features/todos/hooks/queries/useGetScheduledTodosListQuery";
import useScheduledTodosFormState from "src/features/todos/hooks/redux/useScheduledTodosFormState";
import { ScheduledTodosFormSteps } from "src/features/todos/todosSlice";
import { TodoObjectTypes } from "src/features/todos/types/todosEnums.types";
import { useAppDispatch } from "src/store";
import useGetMemoQuery from "../../hooks/useGetMemoQuery";
import useMemoFormState from "../../hooks/useMemoFormState";
import { MemoFormSteps, setMemoFormStep } from "../../slices/memoSlice";

export default function MemoScheduleContent() {
  const dispatch = useAppDispatch();

  const { selectedMemoId, step } = useMemoFormState();
  const { scheduledTodosFormStep } = useScheduledTodosFormState();

  const { data: memo, isLoading: isMemoLoading } = useGetMemoQuery({
    id: selectedMemoId ? String(selectedMemoId) : undefined,
  });

  const { data: scheduledTodos, isLoading: isLoadingScheduledTodos } =
    useGetScheduledTodosListQuery({
      objectId: selectedMemoId,
      objectType: TodoObjectTypes.Memo,
    });

  const isLoading = isMemoLoading || isLoadingScheduledTodos;

  const handleGoToPublishMemoStep = () =>
    dispatch(setMemoFormStep(MemoFormSteps.PublishMemo));

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">
          Assign "To-Do" tasks related to this memo to specific employees,
          roles, or departments. These tasks must be completed by the designated
          recipients. Please note that scheduled tasks will only be dispatched
          once the memo is published.
        </span>
      </div>
      {isLoading ? <Skeleton height={200} /> : null}
      {!isLoading &&
      scheduledTodosFormStep === ScheduledTodosFormSteps.ListScheduledTodos ? (
        <ScheduledTodosTable
          scheduledTodos={scheduledTodos}
          isScheduleStep={step === MemoFormSteps.AssignToEmployees}
          onNextStep={handleGoToPublishMemoStep}
        />
      ) : null}
      {!isLoading &&
      memo &&
      scheduledTodosFormStep ===
        ScheduledTodosFormSteps.CreateUpdateScheduledTodos ? (
        <ScheduledTodosForm
          objectId={memo.id}
          objectType={TodoObjectTypes.Memo}
          onSuccess={handleGoToPublishMemoStep}
        />
      ) : null}
    </StepContainer>
  );
}
