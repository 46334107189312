import React, { useEffect } from "react";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import LPImage from "../../components/LPTake/LPImage";
import ModuleContentTitleAndDescription from "../../components/LPTake/ModuleContentTitleAndDescription";
import { EmployeeModuleContentListItem } from "../../types/employeeModuleContentsV2.types";
import getModuleContentTypeString from "../../utils/getModuleContentTypeString";

type Props = {
  employeeModuleContent: EmployeeModuleContentListItem | null;
  onClickNext: () => void;
  onModuleContentCompleted: () => void;
};

function EmployeeModuleContentImageViewer({
  employeeModuleContent,
  onClickNext,
  onModuleContentCompleted,
}: Props) {
  const [isNextBtnDisabled, setIsNextBtnDisabled] = React.useState(true);

  const title = `${getModuleContentTypeString(
    employeeModuleContent?.module_content?.content_type
  )}: ${employeeModuleContent?.module_content?.content_object?.title}`;

  const description =
    employeeModuleContent?.module_content?.content_object?.description ||
    "No description";

  const fileUrl =
    employeeModuleContent?.module_content?.content_object.file_url;

  useEffect(() => {
    setTimeout(() => {
      onModuleContentCompleted();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsNextBtnDisabled(false);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex p-10 card">
      <ModuleContentTitleAndDescription
        title={title}
        description={description}
      />
      <div className="flex py-10 items-end">
        <Button
          className="border-radius-5"
          color={ButtonColors.Yellow}
          size={ButtonSizes.LG}
          title="Next"
          type="button"
          onClick={onClickNext}
          disabled={isNextBtnDisabled}
        />
      </div>
      {fileUrl && <LPImage src={fileUrl} />}
      <div className="flex py-10 items-end">
        <Button
          className="border-radius-5"
          color={ButtonColors.Yellow}
          size={ButtonSizes.LG}
          title="Next"
          type="button"
          onClick={onClickNext}
          disabled={isNextBtnDisabled}
        />
      </div>
    </div>
  );
}

export default React.memo(EmployeeModuleContentImageViewer);
