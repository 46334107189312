import React from "react";

type Props = {
  title?: React.ReactNode | string;
  subTitle?: React.ReactNode | string | null;
  actions?: React.ReactNode;
  rightInfo?: React.ReactNode;
};

function ObjectDetailPreview({ title, subTitle, actions, rightInfo }: Props) {
  return (
    <div className={"assessmentDetailPreviewInfo"}>
      <div className="flex flex-1 flex-row-mobile-column p-10 gap-20">
        <div className="flex flex-col flex-1 gap-20">
          {title ? (
            <span
              className={"textShadow text-xxl font-w-600 truncatedTitle"}
              data-testid="title"
            >
              {title}
            </span>
          ) : null}
          {subTitle ? (
            <span
              className={"assessmentDetailPreviewCategory truncatedDescription"}
              data-testid="subTitle"
            >
              {subTitle}
            </span>
          ) : null}
          {actions ? (
            <span className={"flex gap-10"} data-testid="actions">
              {actions}
            </span>
          ) : null}
        </div>
        <div className="flex flex-col flex-1-desktop justify-center">
          {rightInfo}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ObjectDetailPreview);
