import { SittingStatus } from "../types/sittings.types";

function getSittingStatusDisplay(sittingStatus?: SittingStatus): string {
  switch (sittingStatus) {
    case SittingStatus.InProgress:
      return "In Progress";
    case SittingStatus.Passed:
      return "Passed";
    case SittingStatus.Failed:
      return "Did Not Pass";
    case SittingStatus.TimeExpired:
      return "Time Expired";
    case SittingStatus.Assigned:
      return "Assigned";
    default:
      return "No Status";
  }
}

export default getSittingStatusDisplay;
