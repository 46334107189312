import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";

import { useAlert } from "@blaumaus/react-alert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import Button from "src/components/Buttons/Button";
import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import DragAndDropFileInput, {
  FileUploadResponse,
  UploadVariables,
} from "src/components/Forms/DragAndDropFileInput";
import FormRow from "src/components/Forms/FormRow";
import StepContainer from "src/components/Steps/StepContainer";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import EditingContainer from "src/features/learningPaths/components/LPBuilder/Forms/EditingContainer";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { useAppDispatch } from "src/store";
import { z } from "zod";
import useGetVideoQuery from "../hooks/queries/useGetVideoQuery";
import useVideoFormReducer from "../hooks/useVideoFormReducer";
import {
  setVideoFormStep,
  setVideoId,
  VideoFormSteps,
} from "../redux/videoSlice";
import videoService2 from "../services/videoService2";

const videoUpdateSchema = z.object({
  id: z
    .string()
    .transform((value) => parseInt(value, 10))
    .or(z.number()),
  file_url: z.string().nullable(),
  name: z.string().min(1, "A video is required."),
  ext: z.string().min(1, "A video is required."),
});

export type VideoUpdateFormShemaType = z.infer<typeof videoUpdateSchema>;

function VideoUploadForm() {
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const { videoId } = useVideoFormReducer();
  const queryClient = useQueryClient();
  const handleErrors = useErrorHandling();

  const { data: video } = useGetVideoQuery({ id: String(videoId) });

  const partialUpdateVideoMutation = useMutation({
    mutationFn: videoService2.partialUpdateVideo,
    onSuccess: async (data) => {
      // invalidate the query to get the video after thumbnail creation
      await queryClient.invalidateQueries([QueryKeys.Video, String(data.id)]);
      dispatch(setVideoId(String(data.id)));
      dispatch(setVideoFormStep(VideoFormSteps.UploadThumbnail));
    },
    onError: handleErrors,
  });

  const form = useForm<VideoUpdateFormShemaType>({
    resolver: zodResolver(videoUpdateSchema),
    defaultValues: {
      id: video?.id,
      file_url: video?.file_url,
      name: video?.name || "",
      ext: video?.ext || "",
    },
  });

  const onSubmit = useCallback(
    (data: VideoUpdateFormShemaType) => {
      partialUpdateVideoMutation.mutate(data);
    },
    [partialUpdateVideoMutation]
  );

  const handleGoToLastStep = () => {
    const id = video?.id ? String(video.id) : null;
    dispatch(setVideoId(id));
    dispatch(setVideoFormStep(VideoFormSteps.AddDetails));
  };

  const handleSuccessfulUpload = useCallback(
    (_: FileUploadResponse, variables: UploadVariables) => {
      form.setValue("file_url", variables.get_url);
      form.setValue("name", variables.name);
      form.setValue("ext", variables.ext);
    },
    [form]
  );

  const handleRemovefile = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      form.setValue("file_url", "");
      form.setValue("name", "");
      form.setValue("ext", "");
    },
    [form]
  );

  const onError: SubmitErrorHandler<VideoUpdateFormShemaType> = (errors) => {
    if (errors.name) {
      alert.error(errors.name.message);
    }
  };

  return (
    <StepContainer>
      <div className="mb-20">
        <span className="text-muted">Upload your video file.</span>
      </div>
      <EditingContainer>
        <form
          onSubmit={form.handleSubmit(onSubmit, onError)}
          className="mt-20 overflow-y-auto"
        >
          <FormRow>
            <DragAndDropFileInput
              mediaClassName="max-h-300"
              s3Folder="private/videos"
              acceptedTypes="video/*"
              fileUrl={form.watch("file_url") || ""}
              onRemoveFile={handleRemovefile}
              onSuccessfulUpload={handleSuccessfulUpload}
            />
          </FormRow>
          <ButtonsContainer>
            <Button
              color={ButtonColors.GrayAndYellow}
              size={ButtonSizes.MD}
              type="button"
              onClick={handleGoToLastStep}
              disabled={partialUpdateVideoMutation.isLoading}
            >
              {partialUpdateVideoMutation.isLoading ? (
                <RotatingLines
                  strokeColor="#f1b70c"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              ) : (
                "Go back"
              )}
            </Button>
            <Button
              color={ButtonColors.Yellow}
              size={ButtonSizes.MD}
              type="submit"
              disabled={partialUpdateVideoMutation.isLoading}
            >
              {!partialUpdateVideoMutation.isLoading ? (
                "Save And Next"
              ) : (
                <RotatingLines
                  strokeColor="#000"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              )}
            </Button>
          </ButtonsContainer>
        </form>
      </EditingContainer>
    </StepContainer>
  );
}

export default React.memo(VideoUploadForm);
