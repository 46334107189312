import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import ErrorFallback from "src/components/Errors/ErrorFallback";
import CompletedStepContainer from "src/components/Steps/CompletedStepContainer";
import useAuth from "src/features/auth/hooks/useAuth";
import hasGroupPermissions from "src/features/auth/utils/hasGroupPermissions";
import { NON_EMPLOYEES_GROUP } from "src/features/groups/groups";
import employeeAnswersService from "../services/employeeAnswersService";
import { Sitting } from "../types/sittings.types";
import getSittingResultContainerColor from "../utils/getSittingResultContainerColor";
import getSittingStartEndText from "../utils/getSittingStartEndText";
import getSittingStatusDisplay from "../utils/getSittingStatusDisplay";
import EmployeeAnswerReviewList from "./EmployeeAnswerReviewList";

type Props = {
  sitting: Sitting;
};

function SittingResult({ sitting }: Props) {
  const { employee } = useAuth();

  const hasPermissionToViewAllSittings = useMemo(
    () => hasGroupPermissions(employee, NON_EMPLOYEES_GROUP),
    [employee]
  );

  const isSittingEmployee = useMemo(
    () => sitting?.employee.id === employee?.id,
    [employee, sitting]
  );

  const {
    data: employeeAnswers,
    isInitialLoading: isemployeeAnswersLoading,
    isError: isemployeeAnswersError,
    refetch: refetchemployeeAnswers,
    isRefetching: isemployeeAnswersRefetching,
  } = useQuery({
    queryKey: ["employeeAnswers", sitting?.id],
    queryFn: sitting?.id
      ? () =>
          employeeAnswersService.getMyEmployeeAnswers({
            sitting: sitting.id,
          })
      : undefined,
    enabled:
      !!sitting?.id && (hasPermissionToViewAllSittings || isSittingEmployee),
    retry: false,
  });

  const description = useMemo(() => getSittingStartEndText(sitting), [sitting]);
  const sittingStatus = useMemo(
    () => getSittingStatusDisplay(sitting?.status),
    [sitting]
  );
  const containerColor = useMemo(
    () => getSittingResultContainerColor(sitting?.status),
    [sitting]
  );

  const handleRefetch = useCallback(() => {
    refetchemployeeAnswers();
  }, [refetchemployeeAnswers]);

  if (isemployeeAnswersLoading || isemployeeAnswersRefetching) {
    return (
      <div className="centered flex-col p-10">
        <Skeleton height={500} />
      </div>
    );
  }

  if (isemployeeAnswersError) {
    return (
      <div className="centered flex-col p-10">
        <ErrorFallback onReload={handleRefetch} />
      </div>
    );
  }

  return (
    <CompletedStepContainer
      title={`${sittingStatus} - ${sitting?.percent_correct}%`}
      description={description}
      color={containerColor}
      actionButtons={undefined}
      content={
        <EmployeeAnswerReviewList
          employeeAnswers={employeeAnswers}
          sitting={sitting}
        />
      }
    />
  );
}

export default SittingResult;
