// Function to parse and normalize the answer
// if the answer is a string, it will be parsed as JSON
// if the answer is an array, it will be returned as is
// if the answer is null, it will return an empty array
const parseAndNormalizeAnswer = (
  answer: string | null
): (number | string)[] => {
  if (!answer) return [];

  try {
    const parsed = JSON.parse(answer);
    if (Array.isArray(parsed)) {
      return parsed;
    } else {
      return [parsed];
    }
  } catch (error) {
    console.error("Error parsing answer:", error);
    return [];
  }
};

export default parseAndNormalizeAnswer;
