import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

import { CHECKBOX, SELECT, TEXTAREA } from "src/features/forms/inputTypes";
import InputError from "../Errors/InputError";
import inputStyles from "../Inputs/Inputs.module.css";
import SquareCheckboxInput from "../Inputs/SquareCheckboxInput";
import TextArea from "../Inputs/TextArea";
import TextInput from "../Inputs/TextInput";
import styles from "./DynamicFormInput.module.css";

const TEXT_INPUT_TYPES = ["text", "password", "email", "number"];

type Option = {
  id?: number;
  [key: string]: any;
  subOptions?: Option[]; // subOptions for nested options
};

type FormInputProps = {
  id?: string;
  containerClassName: string;
  errorClassName: string;
  inputClassName: string;
  defaultValue?: any;
  error?: any;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  control?: Control<FieldValues> | undefined;
  placeholder?: string;
  register?: any;
  required?: boolean;
  setValue?: any;
  type?: string;
  value?: any;
  min?: number;
  max?: number;
  rows?: number;
  columns?: number;
  options?: Option[];
  optionKey?: string;
  extraOption?: any;
  step?: string | number;
};

const DynamicFormInput = React.forwardRef(
  (
    {
      id,
      errorClassName,
      inputClassName,
      containerClassName,
      defaultValue,
      error,
      name,
      onChange,
      onKeyDown,
      control,
      placeholder,
      register,
      required,
      setValue,
      type,
      value,
      min,
      max,
      options,
      rows,
      columns,
      optionKey,
      extraOption,
      step,
      ...rest
    }: FormInputProps,
    ref
  ) => {
    return (
      <>
        {type === CHECKBOX ? (
          <>
            <SquareCheckboxInput
              id={name}
              key={name}
              label={placeholder}
              {...register(name)}
            />
            {error ? <InputError error={error} /> : null}
          </>
        ) : null}
        {type === SELECT && !control ? (
          <>
            <div className={containerClassName}>
              <select
                {...(register ? register(name) : {})}
                defaultValue={defaultValue}
                className={
                  error
                    ? errorClassName || inputStyles.darkInputErrorW100
                    : inputClassName || inputStyles.darkInput
                }
                onChange={onChange}
                value={value}
              >
                <option className={styles.option} value={""}>
                  Select an option
                </option>
                {options && options?.length > 0
                  ? options.map((option: any) => (
                      <option
                        key={option.id || option.value}
                        className={styles.option}
                        value={Number(option.id) || option.value}
                      >
                        {option[optionKey as string]}
                      </option>
                    ))
                  : null}
                {extraOption}
              </select>
            </div>
            {error ? <InputError error={error} /> : null}
          </>
        ) : null}
        {type === SELECT && control ? (
          <>
            <div className={containerClassName}>
              <Controller
                control={control}
                name={name}
                render={({ field }) => (
                  <select
                    {...field}
                    className={
                      error
                        ? errorClassName || inputStyles.darkInputErrorW100
                        : inputClassName || inputStyles.darkInput
                    }
                  >
                    <option className={styles.option} value="">
                      Select an option
                    </option>
                    {options &&
                      options.length > 0 &&
                      options.map((option) => (
                        <option
                          key={option.id}
                          className={styles.option}
                          value={option.id}
                        >
                          {option[optionKey as string]}
                        </option>
                      ))}
                    {extraOption}
                  </select>
                )}
              />
            </div>
            {error ? <InputError error={error} /> : null}
          </>
        ) : null}
        {type === TEXTAREA ? (
          <TextArea
            id={id}
            ref={ref}
            name={name}
            placeholder={placeholder}
            inputClassName={
              inputClassName ? inputClassName : inputStyles.darkInput
            }
            containerClassName={
              containerClassName
                ? containerClassName
                : inputStyles.inputContainer
            }
            errorClassName={
              errorClassName ? errorClassName : inputStyles.darkInputError
            }
            required={required}
            value={value}
            rows={rows}
            columns={columns}
            error={error}
            {...(register ? register(name, { required: required }) : {})}
            {...rest}
          />
        ) : null}
        {TEXT_INPUT_TYPES.includes(type as string) ? (
          <TextInput
            ref={ref}
            inputClassName={
              inputClassName ? inputClassName : inputStyles.darkInput
            }
            containerClassName={
              containerClassName
                ? containerClassName
                : inputStyles.inputContainer
            }
            errorClassName={
              errorClassName ? errorClassName : inputStyles.darkInputError
            }
            defaultValue={defaultValue}
            type={type}
            placeholder={placeholder}
            id={id}
            name={name}
            min={min}
            max={max}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={value}
            error={error}
            step={step}
            {...(register ? register(name, { required: required }) : {})}
            {...rest}
          />
        ) : null}
      </>
    );
  }
);

export default DynamicFormInput;
